import React from 'react';
import { Grid, ArgumentAxis, Chart, Series, CommonSeriesSettings, Legend, Export, Tooltip, Title, Subtitle, Margin
} from 'devextreme-react/chart';
import useApi from '../../hooks/useApi';
import uri from '../../utils/uri';
import moment from 'moment';

const SalesDaily = () => {

    const { data } = useApi({ url: uri.dashboard.base + '/get-sales-daily' });

    const users = data?.length ? Object.keys(data[0]).filter(x => x != 'date') : [];

    const deleteDate = ({ date, ...rest}) => rest;

    const result = data.map((item) => {

        item.day = moment(item.date).format('dddd');

        return deleteDate(item);

    });

    return (
        <Chart
            palette="Soft Pastel"
            dataSource={result}
        >
            <CommonSeriesSettings
                argumentField="day"
                type={'line'}
            />
            {
                users.filter(x => x != 'day').map((user) => <Series

                    key={user}
                    valueField={user}
                    name={user} />)

            }  

            <Margin bottom={20} />
            <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"

            >    <Grid visible={true} />
            </ArgumentAxis>
            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
            />

            <Export enabled={true} />
            <Title text="Ventas por vendedor">
                <Subtitle text="Últimos 6 dias" />
            </Title>
            <Tooltip enabled={true} />
        </Chart>
    );
}

export default SalesDaily;
