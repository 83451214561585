const catalogos = [{
    name : 'grupos',
    caption : 'Categoria de Productos',
    url : 'familias'
},{
    name : 'marcas',
    caption : 'Marcas',
    url : 'presentaciones'
},{
    name : 'unidadmedida',
    caption : 'Unidad de Medida',
    url : 'ums'
},{
    name : 'bancos',
    caption : 'Bancos',
    url : 'banks'
},{
    name : 'gruponotacredito',
    caption : 'Justificación Nota de Crédito',
    url : 'movementgroups'
}];



export default catalogos;