import React from 'react';

import PieChart, {
    Legend as PieLegend,
    Series as PieSeries,
    Tooltip as PieTooltip,
    Format as PieFormat,
    Label as PieLabel,
    Connector,
    Export as PieExport,
} from 'devextreme-react/pie-chart';
import { formatToMoneyNumber } from '../../../../utils/common';

const ChartPie = ({ data, type, typeLabel, reverseType, reverseTypeLabel , currentCategory }) => {

    console.log(type);
    console.log(data);

    const customizeText = arg => {
        return  `${formatToMoneyNumber(arg.valueText)} - ${(arg.percent * 100).toFixed(2)}%`
    }

    const customizeTooltipHTML = (pointInfo) => {

        console.log(pointInfo);

        //const percent = parseFloat((pointInfo.value / totalGlobal) * 100).toFixed(2);

        return {
            html:  `<div>
                        <div class="tooltip-header">${pointInfo.argumentText}</div>
                        <div class="tooltip-body">
                            <div class="series-name">
                                <span class='top-series-name'>Total C$</span>: 
                            </div>
                            <div class="value-text">
                                <span class='top-series-value'>${formatToMoneyNumber(pointInfo.value)}</span>
                            </div>
                            <div class="series-name">
                                <span class='bottom-series-name'>Porcentaje</span>: 
                            </div>
                            <div class="value-text">
                                <span class='bottom-series-value'>${(pointInfo.percent * 100).toFixed(2)}</span>% 
                            </div>
                        </div>
                    </div>`,
        }
    }

    return (
        <PieChart
            id="pie"
            type="doughnut"
            title={`Ventas de ${typeLabel} por ${reverseTypeLabel} ${currentCategory}` }
            palette="Soft Pastel"
            dataSource={data}
        >
            <PieSeries argumentField={type} valueField={'total'}>
                <PieLabel visible={true} forma="millions" customizeText={customizeText}>    
                    <Connector visible={true} />
                </PieLabel>
            </PieSeries>
            <PieExport enabled={true} />
            <PieLegend
                margin={0}
                horizontalAlignment="right"
                verticalAlignment="top"
                columnCount={4}
            />
            <PieTooltip enabled={true} customizeTooltip={customizeTooltipHTML}>
                <PieFormat type="millions" />
            </PieTooltip>
        </PieChart>
    );
}

export default ChartPie;
