
export const dialogDefault = {
    open: false,
    id: 0,
};

export const gridStateDefault = {
    id: 0,
    disabledView: true, 
    disabledPrint: true, 
    disabledPayment: true, 
    disabledViewReceipts: true, 
    disabledCancel: true,
    disabledEnable: true
    
};


export const catalogsName = 'catalogs';

export const DIALOG_AREAPRODUCT = 'DIALOG_AREAPRODUCT';

export const DIALOG_INPUTPRODUCT = 'DIALOG_INPUTPRODUCT';
export const DIALOG_OUTPUTPRODUCT = 'DIALOG_OUTPUTPRODUCT';
export const DIALOG_PRODUCT = 'DIALOG_PRODUCT';
export const GRID_STATE = 'GRID_STATE';
export const GRID_STATE_CC = 'GRID_STATE_CC';


export const GET_CATALOGS = 'GET_CATALOGS';
export const DIALOG_TRANSFER = 'DIALOG_TRANSFER';
export const DIALOG_TRANSFER_WITHPRODUCT = 'DIALOG_TRANSFER_WITHPRODUCT';

export const OPEN_DIALOG_SERVICETEST = 'OPEN_DIALOG_SERVICETEST';
export const CLOSE_DIALOG_SERVICETEST = 'CLOSE_DIALOG_SERVICETEST';

export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export const OPEN_DIALOG_RECIBO = 'OPEN_DIALOG_RECIBO';
export const CLOSE_DIALOG_RECIBO = 'CLOSE_DIALOG_RECIBO';
export const DIALOG_BILL = 'DIALOG_BILL';
export const DIALOG_BILL_DISABLED = 'DIALOG_BILL_DISABLED';
export const DIALOG_AUTH = 'DIALOG_AUTH';
export const DIALOG_CLIENT = 'DIALOG_CLIENT';
export const DIALOG_NC = 'DIALOG_NC';
export const DIALOG_PROVIDER = 'DIALOG_PROVIDER';
export const DIALOG_APPRULE = 'DIALOG_APPRULE';
export const CLIENT_DETAIL = 'CLIENT_DETAIL';
export const DIALOG_PURCHASE = 'DIALOG_PURCHASE';
export const DIALOG_TRASLATE = 'DIALOG_TRASLATE';

export const TOOLBAR_ADD = 'TOOLBAR_ADD';
export const TOOLBAR_REMOVE = 'TOOLBAR_REMOVE';
export const TOOLBAR_LOAD = 'TOOLBAR_LOAD';