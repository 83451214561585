import React, { useRef, useState } from 'react';
import { DataGrid, Popup } from 'devextreme-react';
import {
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Column,
    Lookup,
    Editing,
    Button as ButtonGrid,
    Export
} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../utils/proxy';
import Title from '../../components/shared/Title';
import BlockHeader from '../../components/shared/BlockHeader';
import { store } from '../../services/store';
import * as NuevoReceipt from '../../components/bill/Nuevo';
import CustomButton from '../../components/buttons/CustomButton';
import { _path } from "../../data/headerNavigation";
import { dataAccess, formatDate, formatDateTime, resources } from '../../data/app';
import { cellAsPayoff, cellRender, dataFormatId, } from '../../utils/common';
import urlReport from '../../services/reportServices';
import useAuthorization from '../../hooks/useAuthorization';
import { billDialogAction } from '../../store/bill/billDialogReducer';
import Nuevo from './Nuevo';
import { useDispatch } from 'react-redux';

import uri from '../../utils/uri';
import ReceiptByBillId from '../receipts/ReceiptByBillId';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { preBillType } from '../../data/enums';
import { confirm } from 'devextreme/ui/dialog';

const PreBills = props => {

    const { clientId = 0, title='',action='', typeId=0, initStates, ...rest } = props;

    const [ authorized ] = useAuthorization( resources.cotizacion );
    const [ setDataDialog] = useState({ id: 0, billId: 0, open: false, client: '', balance: '' });
    const [states, setStates] = useState([...initStates]);
    const [statesActive, setStatesActive] = useState(1);

    const dispatch = useDispatch();
    let refGrid = useRef();

    const printReport = ({ row }) => {

        console.log(row);

        const { id } = row.data;
        const report = urlReport();
        report.print(`${report.preBillTicket(id)}`);

    }

    const addMenuItems = (e) => {

        if (e.target == "content") {
            
            if (!e.items) e.items = [];
            
            if (e.rowIndex >= 0){

                e.items.push({
                    
                    text: `Ver ${action}`,
                    icon: 'find',
                    onItemClick: () => {
                        showDialog(e.row.data.id);                        
                    }

                },{

                    text: `Imprimir ${action}`,
                    icon: 'print',
                    onItemClick: () => {
                        printReport(e);
                    }
                });                              

                e.items.push({

                    text: `Anular ${action}`,
                    icon: 'remove',
                    onItemClick: () => refGrid.current.instance.deleteRow(e.rowIndex),
                    color: 'red'

                });               
                
            }

        }
    }

    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            if (!e.data.active)
                e.rowElement.classList.add('no-activo');
            else{
                if (e.data.preBillTypeId == preBillType.facturacion)
                    e.rowElement.classList.add('converted');
                
            }

        }
    }

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon: 'exportxlsx',
                type: 'success',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.exportToExcel(false)
            }
        },{
            location: 'after',
            widget: 'dxTagBox',
            options: {
                width: 420,
                placeholder: 'Filtrar por Tipo',
                dataSource : e.component.columnOption('preBillTypeId').lookup.dataSource,
                valueExpr:'id', 
                displayExpr:'name',
                value: states,
                showClearButton:true,
                multiline:false,
                hideSelectedItems:true,
                onValueChanged: (e) => {
                    setStates(e.value);
                }

            }
        },{
            location: 'after',
            widget: 'dxSelectBox',
            options: {
                width: 200,
                placeholder: 'Seleccionar',
                dataSource : [{ id: 0, name: 'Todas' }, { id: 1, name: 'Activas' }, { id: 2, name: 'Inactivas' }],
                valueExpr:'id', 
                displayExpr:'name',
                value: statesActive,
                showClearButton:true,
                onValueChanged: (e) => {
                    setStatesActive(e.value);
                    if(e.value == 1)
                        refGrid.current.instance.filter(['active', '=', true]);
                    else if(e.value == 2)
                        refGrid.current.instance.filter(['active', '=', false]);
                    else
                        refGrid.current.instance.clearFilter();
                }

            }
        });
    }

    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
        }

    }

    const showDialog = (id) => dispatch(billDialogAction({ open: true, id }))

    const onSave = (e) => refGrid.current.instance.refresh();   

    const close = e => {
        setDataDialog({ id: 0, billId: 0, open: false, client: '', balance: '' });
    }

    const changePreBill = ({ title, action, id }) => {

        let result = confirm("<i>Esta seguro de  "+title+"?</i>", title);
        result.then((dialogResult) => {

            if (dialogResult){
                
                http(`${uri.prebills.base}/${id}/${action}`).asGet().then(resp => {
                    
                    notify(`Acción ${title} realizada correctamente`);
                    refGrid.current.instance.refresh();   
                    
                }).catch(err => {
                    
                    notify(err, 'error', 5000);
                });
                
            }
        });

    }

    const revert = ({ row }) => {

        const { id } = row.data;
        changePreBill({ title: 'Revertir a cotización', action: 'revert', id });

    }    

    const convert = ({ row }) => {

        const { id } = row.data;
        changePreBill({ title: 'Convertir a preventa', action: 'convert', id });       

    }

    const cellRenderDelivery = (cellData) => {

        const { isDeliveried, billId } = cellData.data;

        let deliveriedClassName = 'not-deliveried';
        if(isDeliveried)
            deliveriedClassName = 'is-deliveried';
        
        return (
            <div>
                <div className="current-value">
                    <span className={deliveriedClassName}> {billId == null ? '--' : isDeliveried ? 'Entregado': 'Sin entrega'} </span>
                </div>                
            </div>
        );
    };

    //let extraParameter = [['preBillTypeId', typeId]];
    let extraParameter =[['preBillTypeId', states]];

    if (clientId > 0)
        extraParameter.push(['clientId', clientId]);

    const active = statesActive == 1 ? true : statesActive == 2 ? false : null;

    return authorized(
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} showFavorite={true} {...rest}>
                <CustomButton
                    type="default"
                    text={`Nueva ${action}`}
                    icon='plus'
                    onClick={() => showDialog(0)}
                />              
            </BlockHeader>           
            <Nuevo onSave={onSave} { ...props } />
            <DataGrid id="gridContainer"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.prebills, remoteOperations: true, extraParameter })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                onContextMenuPreparing={addMenuItems}
                onRowPrepared={onRowPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onCellPrepared={onCellPrepared}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <Export enabled={false} fileName={title} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column type="buttons" width={50}>
                    <ButtonGrid visible={typeId == preBillType.cotizacion} name="convert" icon="refresh" onClick={convert} hint={'Convertir a preventa'}/>
                    <ButtonGrid visible={typeId == preBillType.preventa} name="revert" icon="revert" onClick={revert} hint={'Revertir a cotización'}/>
                </Column>
                <Column dataField="id" caption="Id" width={80} cellRender={dataFormatId} />
                <Column dataField="billId" caption="Factura" width={80} cellRender={dataFormatId} />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={110} />
                <Column dataField="nombre" caption="Cliente" minWidth={200} visible={clientId == 0} allowHeaderFiltering={false} />
                <Column dataField="paymentTypeId" width={80} caption="Tipo">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="preBillTypeId" width={110} caption="Documento">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'preBillType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="total" width={100} cellRender={cellRender()} />
                <Column dataField="active" width={100} caption="Activa" filterValue={active} />
                <Column dataField="createBy" caption='Vendedor' width={100} />
                <Column dataField="isDeliveriede" width={100} caption="Entregado" cellRender={cellRenderDelivery} />
                <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} width={180} visible={false} />
                <Column type="buttons" width={90}>
                    <ButtonGrid name="print" icon="print" onClick={printReport}/>
                    <ButtonGrid name="edit" icon="find" onClick={e => showDialog(e.row.data.id)}/>
                    <ButtonGrid name="delete" />
                </Column>
                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowUpdating={true}
                    useIcons={true}
                ></Editing>
            </DataGrid>
        </div>
    );
}

export default PreBills;
