import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule} from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { dialogOutputProduct } from '../../../store/outPutProduct/outPutProductDialogReducer';
import { createStoreLocal } from '../../../utils/proxy';
import { editorOptionsSelect } from '../../../data/app';
import { DataGrid, SelectBox } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import ProductDDBComponent from '../../../components/dropdown/ProductDDBComponent';
import uri from '../../../utils/uri';
import { cellRender, formatId, formatToMoney, onCellPrepared } from '../../../utils/common';
import http from '../../../utils/http';
import useProducts from '../../../hooks/useProducts';
import gridsHelper from '../../../utils/gridsHelper';
import ButtonForm from '../../../components/buttons/ButtonForm';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { defaultLabels, outPutProductDefault } from '../../../data/defaultObjects';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';

const Nuevo = props => {    

    const { typeId } = props;

    const { outPutProductDialog : { open, id }, user } = useSelector(store => store)

    const active = true;
    const exists = true;

    const { products } = useProducts({areaId: user.areaId,  active, exists });
    const [ outPutProduct, setOutPutProduct ] = useState({...outPutProductDefault});
    const [ saving, setSaving ] = useState(false);
    const [ details, setDetails ] = useState([]);
    const [lots, setLots] = useState([]);   
    const dispatch = useDispatch();

    let refForm = useRef();
    let refGrid = useRef();
    let refSelect = useRef(); 

    useEffect(() => {          

        if(id > 0){
            
            http(uri.outPutProducts.getById(id)).asGet().then(resp => {

                const { outPutProductDetails, ...rest } = resp;

                outPutProductDetails.map(detail =>{

                    let info = products.find(x => x.id == detail.productId);

                    detail['presentation'] = info.presentation;
                    detail['um'] = info.um;
                    detail['family'] = info.family;
                    detail['internalCode'] = info.internalCode;

                    return detail;
                })

                setOutPutProduct({...outPutProduct, ...rest});              
                setDetails([...outPutProductDetails]);

            });

        }else{

            setOutPutProduct({...outPutProductDefault, areaId : user.areaId, typeId : typeId});
            setDetails([]);       

        }

    }, [open]);
    
    const onToolbarPreparing = gridsHelper(refGrid, { text : 'Agregar producto', icon:'plus' });

    const closeDialog = ( load ) => {
        refForm.current.instance.resetValues();  
        refGrid.current.instance.cancelEditData();
        dispatch(dialogOutputProduct({open : false}));
        if (load) {
            let { onSave } = props;
            onSave();      
        }        
    }

    const onHiding = ({ load }) => {
        
        closeDialog(load);        
    }

    const guardarSalida = (e) => {

        let result = refForm.current.instance.validate();

        if (result.isValid) {

            let result = confirm("<i>Esta seguro de guardar el ajuste salida?</i>", "Confirmar");

            result.then((dialogResult) => {
                if (dialogResult) {                

                    setSaving(true);
                    let data = {...outPutProduct,outPutProductDetails:[...details] };

                    http(uri.outPutProducts.insert).asPost(data).then(resp => {
                        setSaving(false);
                        notify('Entrada registrada correctamente');
                        closeDialog(true);
                    }).catch(err => {
                        setSaving(false);
                        notify(err, 'error', 5000);
                    });
                }
            });

        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        newData[prop] = value || 0;
        if(prop == 'productId' && value){

            let info = products.find(x => x.id == value);

            newData['internalCode'] = info.internalCode;
            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['cost'] = info.cost;            
            newData['price'] = info.price;            
            newData['quantity'] = 1;
            newData['total'] = info.cost;
        }
        
        if(prop == 'quantity' && (+value) >= 0){
            newData['total'] = currentRowData['cost'] * value;
        }

        if(prop == 'internalCode' && value){
            let product = products.find(x => x.internalCode == value);
            if(product){
                newData['productId'] = product.id;
                newData['presentation'] = product.presentation;
                newData['um'] = product.um;
                newData['family'] = product.family;
                newData['cost'] = product.cost;            
                newData['price'] = product.price;            
                newData['quantity'] = 1;
                newData['total'] = product.cost;
            }
        
        }

    }

    const isNew = id == 0;

    const textSaving = 'Guardar Salida';

    useEffect(() => {

        http('catalogos/lots').asGet().then(_lots => {
          const lotsMap = _lots.map(x => ({ ...x, name: `${x.lote}-${moment(x.expireDate).format('DD-YYYY')}` }));
          setLots(lotsMap);
        });
 
     },[]);

    const onRowInserting = (e) => {

        const isCanceled = new Promise((resolve, reject) => {

            const existsProduct = details.find(x => x.productId == e.data.productId);

            if (existsProduct) {
                notify(`El producto ya se encuentra en la lista`, 'error', 1000);
                reject(`El producto ya se encuentra en la lista`);
            }else{
                resolve(false);
            }
        });
        e.cancel = isCanceled;

    }

    const ds = useMemo(() =>new DataSource({
        key: 'productId',
        store: details
    }));

    const itemRender = (data) => {

        const isZero = data.stock <= 0;

        const cssZero = isZero ? 'product-stock zero-stock' : 'product-stock';

        return <div className="d-flex space-between">
            <div>
                <div className="product-name"><b>{data.internalCode}</b>-{data.name}</div>
                <div className="product-desciption"><small className='text-muted'>Categoria: </small>{data.family} <small className='text-muted'>Marca: </small>{data.presentation}</div>
            </div>  
            <div className="text-right">
                <div className="product-price">{formatToMoney(data.cost)}</div>
                <div className={cssZero}><small className='text-muted'>Disponible:</small> {data.stock}/{data.disponible}</div>
            </div>    
        </div>
    }

    const dataSourceProduct = useCallback(() => new DataSource({
        load: (loadOptions) => {
    
            let params = {};
            params.skip = loadOptions.skip || 0;
            params.take = loadOptions.take || 10;
    
            if(loadOptions.searchValue)
                params.name = loadOptions.searchValue;

            params.exists = true;
            params.active=false; 
            params.has=false;
            params.onlyProducts= false;
    
            return http(uri.products.getByArea(user.areaId)+'/grid')
            .asGet(params).then(x => x.items);
            
        },
        byKey: id => http(uri.products.getById(id)).asGet(),
        paginate : true,
        pageSize: 10
    }),[]);

    const addProductById = ( id, _info=null ) => {

        if (!id) return;

        const items = ds.items();

        const existsProduct = items.find(x => x.productId == id);

        if (existsProduct) {
            notify(`El producto ya se encuentra en la lista`, 'error', 5000);
            return
        }

        let info = _info == null ? products.find(x => x.id == id) : _info;

        if(info.stock <= 0)
            return notify('No hay stock disponible', 'error', 5000);

        let newData = {}; 

        newData['presentation'] = info.presentation;
        newData['um'] = info.um;
        newData['family'] = info.family;
        newData['stock'] = info.stock;
        newData['price'] = info.price;
        newData['customPrice'] = info.price;
        newData['cost'] = info.cost;
        newData['quantity'] = 1;
        newData['total'] = info.price;         
        newData['discount'] = 0;
        newData['productId'] = info.id;  
        newData['internalCode'] = info.internalCode;

        ds.store().insert(newData).then(() => {
            ds.reload().then(() => {
                refGrid.current.instance.editCell(details.length-1, 'quantity');
            });
        });

        //setBill(_bill => ({ ..._bill, billDetails: [...newDetail],}));

    }


    const setFocus = () => {
        setTimeout(() => {
            refSelect.current.instance.focus();        
        }, 300);
    }

    const onEditCanceled = (e) => {
        setFocus()
    }

    const keyDown = (e) => {
        if(e.event.key == 'Enter')
            setFocus()
    }

    return (
        <div>
             <Popup
                width={'90vw'}
                height={'90vh'}
                title={isNew ? `Nueva salida de inventario` : `Salida #${ formatId(id)}`}                
                onHiding={onHiding}
                visible={open}                
            >
                <Form formData={outPutProduct} ref={refForm}>
                    <GroupItem colCount={3}>                       
                        <SimpleItem dataField="areaId" editorType="dxSelectBox"
                            editorOptions={{
                                disabled: true,
                                dataSource: createStoreLocal({ name: 'area'}),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Area" />
                            <RequiredRule message="Seleccione el area" />
                        </SimpleItem>
                        <SimpleItem dataField="date" editorType="dxDateBox"
                            editorOptions={{
                                displayFormat : 'dd/MM/yyyy',
                                openOnFieldClick:true,
                            }} >
                            <Label text="Fecha" />
                            <RequiredRule message="Seleccione la fecha" />
                        </SimpleItem>
                        <SimpleItem dataField="typeId" editorType="dxSelectBox"
                            editorOptions={{
                                disabled: true,
                                dataSource: createStoreLocal({name: 'outPutProductType'}),
                                ...editorOptionsSelect
                            }} >
                            <Label text="Tipo" />
                            <RequiredRule message="Seleccione el tipo" />
                        </SimpleItem>
                        <SimpleItem dataField="observation" colSpan={3}  editorType="dxTextArea">
                            <Label text="Observacion" />
                            <RequiredRule message="Ingrese una observacion" />
                            <StringLengthRule max={500} message="Maximo 500 caracteres" />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={4}>
                        <SimpleItem colSpan={2}>
                            <SelectBox 
                                ref={refSelect}
                                dataSource={dataSourceProduct()} 
                                valueExpr="id" 
                                displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}
                                searchEnabled={true} 
                                searchMode="contains" searchExpr={['name','internalCode']} 
                                searchTimeout={600} 
                                placeholder="Selecione una producto" 
                                showClearButton={true}         
                                itemRender={itemRender}                           
                                onValueChanged={e => {

                                    addProductById(e.value);
                                    e.component.reset();
                                    e.component.focus();
                                    //this.props.data.component.editCell(this.props.data.rowIndex, this.quantity);
                                    //refGrid.current.instance.editCell(0, 'quantity');

                                }} />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem>
                        <DataGrid id="gridDetails"
                            ref={refGrid}
                            selection={{ mode: 'single' }}
                            dataSource={ds}
                            showBorders={true}
                            showRowLines={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            height={320}
                            onToolbarPreparing={onToolbarPreparing}
                            onCellPrepared={onCellPrepared}
                            onRowInserting={onRowInserting}
                            onEditCanceled={onEditCanceled}                              
                            onKeyDown={keyDown}
                        >
                            <SearchPanel visible={true} />
                            <Column dataField="internalCode" caption={'Código'} width={90} allowEditing={true} setCellValue={setCellValue.bind(null,"internalCode")}>
                                <RuleRequired />
                            </Column> 
                            <Column dataField="productId" caption="Producto"
                                setCellValue={setCellValue.bind(null,"productId")}
                                editCellComponent={props => <ProductDDBComponent showPrice={false} {...props} validateQuantity={true} />}>
                                    <Lookup 
                                        dataSource={products}
                                        valueExpr="id" 
                                        displayExpr='name'                                    
                                    />
                                    <RuleRequired />
                            </Column>    
                            {/* <Column dataField="lote" caption="Lote" width={160} allowEditing={true}>
                                <Lookup dataSource={opt => {
                                    return{
                                        store: lots,
                                        filter: opt.data ? ['productId', '=', opt.data.productId] : null
                                    }
                                }} valueExpr="id" displayExpr="name" />
                            </Column>                       */}
                            <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                                <RuleRequired />
                            </Column>  
                            <Column dataField="um" caption="Unidad Medida" width={120} allowEditing={false}>
                                <RuleRequired />
                            </Column>          
                            <Column dataField="quantity" caption="Cantidad" dataType="number" width={80} setCellValue={setCellValue.bind(null,"quantity")}>
                                <RuleRequired />
                            </Column>
                            <Column dataField="cost" caption="Costo" dataType="number" width={100} allowEditing={false} cellRender={cellRender()} >
                                <RuleRequired />
                            </Column>
                            <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                <RuleRequired />
                            </Column> 
                            <Column type="buttons" width={50}>
                                <ButtonGrid name="delete" />                            
                            </Column>
                            <Editing
                                mode="cell"
                                allowDeleting={true}                               
                                allowUpdating={true}
                                selectTextOnEditStart={true}
                                useIcons={true}
                            ></Editing>
                            <Summary>
                                <TotalItem
                                    column="total"
                                    summaryType="sum" 
                                    customizeText={data => `${formatToMoney(data.value)}`} />
                            </Summary>
                        </DataGrid>
                    </GroupItem>
                </Form>

                <ButtonForm saving={saving} visible={isNew} textSaving={textSaving} onClick={guardarSalida}/>
               
            </Popup>
        </div>
    );
}

export default Nuevo;
