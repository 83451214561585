import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { billDialogAction } from '../../../store/bill/billDialogReducer';
import { createStoreLocal } from '../../../utils/proxy';
import { editorOptionsSelect, formatDate } from '../../../data/app';
import { DataGrid, Button, TextBox, ScrollView } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, Summary, TotalItem } from 'devextreme-react/data-grid';
import { cellRender,  formatId, toMoney } from '../../../utils/common';
import http from '../../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault, defaultLabels } from '../../../data/defaultObjects';
import { paymentType } from '../../../data/enums';
import ProductDDBComponent from '../../../components/dropdown/ProductDDBComponent';
import useProducts from '../../../hooks/useProducts';
import uri from '../../../utils/uri';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';

const Nuevo = props => {

    const { billDialog: { open, id, clientId }, user, } = useSelector(store => store);
    const [resumenData, setResumenData] = useState([]);
    const billDefaultCustom = { ...billDefault, clientId: clientId };

    const [ reload, setReload ] = useState(0);

    const { products, setProducts } = useProducts({ areaId: user.areaId, exists: true, reload });
    const [bill, setBill] = useState({ ...billDefaultCustom });
    const [saving, setSaving] = useState(false);
    const [lots, setLots] = useState([]);   
    const [currentCreditDays, setCurrentCreditDays] = useState(0);

    let refForm = useRef();
    let refGrid = useRef();
    let textBoxRef = useRef();

    useEffect(() => {
        
        //     if (appInfo.rate == 0) {
        //         dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
        //         notify('Debe ingresar la tasa de cambio del dia para poder facturar', 'warning', 5000)
        //         props.history.push('/app/tasa-de-cambio');
        //     }

        if (id > 0) {

            http(uri.products.getByArea(user.areaId)).asGet().then(data => {
                setProducts(data);

                http(uri.bills.getById(id)).asGet().then(resp => {

                    resp.billDetails.forEach(detail => {

                        let info = products.find(x => x.id == detail.productId);

                        detail['presentation'] = info.presentation;
                        detail['um'] = info.um;
                        detail['family'] = info.family;

                    });

                    setBill(_bill => ({ ..._bill, ...resp }));
                    setResumenData([...resp.billDetails]);
                    if (open) textBoxRef.current.instance.focus();
                })

            });

        } else {

            setBill({ ...billDefaultCustom, billDetails: [] });
            setResumenData([]);
            if (open) textBoxRef.current.instance.focus();

        }
    
       
    }, [open]);

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
        alert('saliendo');
    };


    useEffect(() => {
        if (open) textBoxRef.current.instance.focus();
    }, [open]);

    const dispatch = useDispatch();

    const onKeyDown = (e) => {
        
        if(e?.event?.key == 'Enter'){

            const code = e.event.target.value;

            let info = products.find(x => x.internalCode == code);
            let newData = {};

            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            newData['quantity'] = 1;
            newData['total'] = info.price;         
            newData['discount'] = 0;
            newData['productId'] = info.id;   

            const newDetail = [...bill.billDetails, newData ];

            setBill(_bill => ({ ..._bill, billDetails: [...newDetail] }));
            setResumenData([...newDetail]);    
            
            // textBoxRef.current.instance.option('value','');
            // textBoxRef.current.instance.focus();

        }

    }

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();

        dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const guardarFactura = (e) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            let result = refForm.current.instance.validate();

            if (result.isValid) {

                let result = confirm("<i>Esta seguro de guardar la factura?</i>", "Confirmar");

                result.then((dialogResult) => {
                    if (dialogResult) {
                        
                        setSaving(true);
                        let data = { ...bill };
                        
                        http(uri.bills.insert).asPost(data).then(resp => {
                            setSaving(false);
                            notify(`Factura registrada correctamente`);
                            setReload(Math.random());
                            closeDialog(true);                    
                        }).catch(err => {
                            setSaving(false);
                            notify(err, 'error', 5000);
                        });

                    }
                });

            }
        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        newData[prop] = value || 0;
        if (prop == 'productId' && value) {

            let info = products.find(x => x.id == value);

            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            newData['discount'] = 0;
            !currentRowData['quantity'] && (newData['quantity'] = 1);
            !currentRowData['total'] && (newData['total'] = info.price);
        }

        if (prop == 'quantity' && (+value) >= 0) {
            newData['total'] = currentRowData['price'] * value;
        }

        if (prop == 'customPrice' && (+value) >= 0) {
            newData['total'] = currentRowData['quantity'] * value;
        }

        if (prop == 'discount' && (+value) >= 0) {
            newData['total'] = (currentRowData['quantity'] *  currentRowData['customPrice']) - value;
        }

    }

    const onCellPrepared = e => {

        const cellsQuantity = ['quantity', 'quantityRequest']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                e.cellElement.classList.add('quantity-text');
            if (e.column.dataField == 'customPrice')
                e.cellElement.classList.add('customPrice-text');

        }

    }

    const onRowInserted = e => {
        setResumenData([...bill.billDetails])
    }

    const isNew = id == 0;

    const init = "";

    useEffect(() => {

        http('catalogos/lots').asGet().then(_lots => {
          const lotsMap = _lots.map(x => ({ ...x, name: `${x.lote}-${moment(x.expireDate).format('DD-YYYY')}` }));
          setLots(lotsMap);
        });
 
     },[reload]);

    const paymentTypeIdChanged = (e) => {

        setBill(_bill => ({ 
            ..._bill, paymentTypeId: e.value, 
            paymentMethodId: e.value == paymentType.credito ? null : '', 
            bankId: e.value == paymentType.credito ? null : '', 
            reference:'', 
            creditDays: e.value == paymentType.contado ? 0 : currentCreditDays }))
    }

    const clientIdChanged = (e) => {

        const client = e.component.option('items')
            .find(x => x.id == e.value);

        let creditDays = 0;
    
        if(client)
            creditDays = client.creditDays;                 
        
        setCurrentCreditDays(creditDays);
        setBill(_bill => ({ ..._bill, clientId: e.value, creditDays: creditDays }));
        
    }

    
    const dataSource = useCallback(() => new DataSource({
        load: (loadOptions) => {

            let params = {};
            params.skip = loadOptions.skip || 0;
            params.take = loadOptions.take || 10;

            if(loadOptions.searchValue)
                params.name = loadOptions.searchValue  ;

            return http(uri.clients.asCatalog)
            .asGet(params).then(x => x.items);
            
        },
        byKey: id => {

            return new Promise((resolve, reject) => {

                if(id == 0)
                    resolve(null);
                else
                    http(uri.clients.getById(id)).asGet().then(resp => resolve(resp));
            });
            
        },       
        paginate : true,
        pageSize: 10
    }),[]);


    return (
        <div>
            <Popup
                fullScreen={true}
                title={isNew ? `Nueva factura` : `Ver factura ${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                showCloseButton={true}
                className="bg-fbfbfb"
            >
                <ScrollView showScrollbar='never'>
                    <Form formData={bill} ref={refForm}>
                        <GroupItem colCount={4}>
                            <GroupItem colSpan={4}>
                                <GroupItem colCount={6}>

                                    <SimpleItem dataField="date" colSpan={2} editorType="dxDateBox"
                                        editorOptions={{
                                            displayFormat: formatDate,
                                            openOnFieldClick: true,
                                        }} >
                                        <Label text="Fecha" />
                                        <RequiredRule message="Seleccione la fecha" />
                                    </SimpleItem>
                                    <SimpleItem dataField="paymentTypeId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: createStoreLocal({ name: 'billPaymentType' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: paymentTypeIdChanged 
                                        }} >
                                        <Label text="Tipo pago" />
                                        <RequiredRule message="Seleccione el tipo" />
                                    </SimpleItem>
                                    <SimpleItem dataField="codigo" colSpan={2} editorType="dxTextBox"
                                        editorOptions={{ placeholder: 'Talonario', showClearButton: true }} >
                                        <Label text="Referencia" />
                                    </SimpleItem>
                                    <SimpleItem dataField="clientId" colSpan={4} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: dataSource(),
                                            valueExpr:"id",
                                            displayExpr: item => item ? `${item.id} - ${item.name} ${item.lastName}` : '',
                                            searchEnabled: true,
                                            searchExpr: ["name", "lastName"]
                                            , showClearButton:true, onValueChanged: clientIdChanged}} >
                                        <Label text="Cliente" />
                                        <RequiredRule message="Seleccione el cliente" />
                                    </SimpleItem>
                                    <SimpleItem dataField="creditDays" colSpan={2} editorType="dxNumberBox"
                                        editorOptions={{
                                            disabled: true
                                        }} >
                                        <Label text="Dias crédito" />
                                        <RequiredRule message="Seleccione el tipo" />
                                    </SimpleItem>                                

                                    <SimpleItem dataField="paymentMethodId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            disabled: bill.paymentTypeId == paymentType.credito,
                                            dataSource: createStoreLocal({ name: 'billPaymentMethod' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: e => setBill(_bill =>({ ..._bill, billPaymentTypeId: e.value, }))
                                        }} >
                                        <Label text="Forma pago" />
                                    </SimpleItem>
                                    <SimpleItem dataField="bankId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            disabled: bill.paymentTypeId == paymentType.credito,
                                            dataSource: createStoreLocal({ name: 'bank' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: e => setBill(_bill =>({ ..._bill, bankId: e.value, }))
                                        }} >
                                        <Label text="Banco" />
                                    </SimpleItem>
                                    <SimpleItem dataField="reference" colSpan={2} editorType="dxTextBox"
                                        editorOptions={{ showClearButton: true, disabled: bill.paymentTypeId == paymentType.credito, }} >
                                        <Label text="Referencia" />
                                    </SimpleItem>
                                
                                    <SimpleItem dataField="observation" colSpan={6} editorType="dxTextBox">
                                        <Label text="Observacion" />
                                        <StringLengthRule max={150} message="Maximo 150 caracteres" />
                                    </SimpleItem>

                                </GroupItem>
                            </GroupItem>                        

                        </GroupItem>
                        <GroupItem>
                            <DataGrid id="gridDetails"
                                ref={refGrid}
                                selection={{ mode: 'single' }}
                                dataSource={bill.billDetails}
                                showBorders={true}
                                showRowLines={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                height={280}
                                columnHidingEnabled={true}
                                onRowInserted={onRowInserted}
                                onRowRemoved={onRowInserted}
                                onRowUpdated={onRowInserted}
                                onCellPrepared={onCellPrepared}
                            >
                                
                                <Column dataField="productId" caption="Producto"
                                    setCellValue={setCellValue.bind(null, "productId")}
                                    editCellComponent={props => <ProductDDBComponent showPrice={true} {...props} />}
                                >
                                
                                    <Lookup
                                        dataSource={products}
                                        valueExpr="id"
                                        displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}

                                    />
                                    <RuleRequired />
                                </Column>                          
                                <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="quantity" caption="Cantidad" dataType="number" width={80}
                                    setCellValue={setCellValue.bind(null, "quantity")}
                                >
                                    <RuleRequired />
                                </Column>
                                <Column visible={false} dataField="price" caption="Precio" dataType="number" width={100} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="customPrice" caption="Precio" dataType="number" width={100} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "customPrice")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="discount" caption="Desc" dataType="number" width={80} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "discount")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column type="buttons" width={50}>
                                    <ButtonGrid name="delete" />
                                </Column>
                                <Summary>
                                    <TotalItem  column="productId" summaryType="count" />
                                    <TotalItem  column="discount" summaryType="sum" customizeText={toMoney}  />
                                    <TotalItem column="total" summaryType="sum" customizeText={toMoney} />
                                </Summary>
                                <Editing
                                    mode="cell"
                                    allowDeleting={true}
                                    allowUpdating={true}
                                    selectTextOnEditStart={true}
                                    useIcons={true}
                                ></Editing>
                            </DataGrid>
                        </GroupItem>
                        <GroupItem colCount={6}>                       
                            <SimpleItem colSpan={2} dataField="codeAuth" editorType="dxTextBox" editorOptions={{readOnly: true}}>
                                <Label text="Código Autorización" />                           
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </ScrollView>
                <div className='flex-v'>
                    <TextBox visible={false} focusStateEnabled={true} ref={textBoxRef} onKeyDown={onKeyDown} value={init}></TextBox>
                    <Button
                        className="mt-10"
                        text={saving ? 'Guardando...' : `Guardar factura`}
                        type="success"
                        icon='save'
                        disabled={saving}
                        visible={isNew}
                        onClick={guardarFactura}
                        />
                </div>

            </Popup>
        </div>
    );
}

export default Nuevo;
