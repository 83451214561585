import React from 'react';

import { Button } from 'devextreme-react/button';

import Cardinfo from './CardInfo';
import './index.css';
import SalesMonthly from './SalesMonthly';
import CategorySales from './CategorySales';
import SalesDaily from './SalesDaily';

const Dashboard = (props) => {

    const navegateTo = (path) => {
        props.history.push({ pathname: path });
    }

    return (
        <div>
            <div className='dash-row'>
                 <div className='dash-col-6'>
                    <div className='dash-row'>
                        <div className='dash-col-12 d-flex'>
                            <Button width={140} icon='doc' text="Facturas" type="default" stylingMode="outlined" onClick={e => navegateTo('/app/facturas')} />
                            <Button width={140} icon='box' text="Inventaio" type="warning" stylingMode="outlined" onClick={e => navegateTo('/app/productos')} />
                            <Button width={140} icon='cart' text="Compras" type="danger" stylingMode="outlined" onClick={e => navegateTo('/app/compras')} />
                            <Button width={140} icon='user' text="Clientes" type="success" stylingMode="outlined" onClick={e => navegateTo('/app/clientes')} />
                        </div>
                    </div>
                    <div className='dash-row'>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Ventas Año'} icon={'group'} _path={'get-cardinfo-year'} />
                        </div>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Ventas Mes'} icon={'cart'} _path={'get-cardinfo-month'} />
                        </div>
                    </div>
                    <div className='dash-row'>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Facturas Vencidas'} icon={'money'} _path={'get-overdue-bills'} />
                        </div>
                        <div className='dash-col-6'>
                            <Cardinfo title={'Facturas Pagadas'} icon={'chart'} _path={'get-paid-bills'} />
                        </div>
                    </div>
                </div>
                <div className='dash-col-6'>
                    <div className="m15 dash-color">
                        <SalesMonthly/>
                    </div>
                </div>
            </div>
            <div className='dash-row'>
                <div className='dash-col-6'>
                    <div className='m15 dash-color'>
                        <CategorySales />
                    </div>
                </div>
                <div className='dash-col-6'>
                    <div className='m15 dash-color'>
                        <SalesDaily />
                    </div>
                </div >
            </div >
        </div >
    );
}

export default Dashboard;
