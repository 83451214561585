// react
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'devextreme-react/button';
import { addToolBarAction, removeToolBarAction } from '../../store/toolBar/toolBarOptsReducer';

function BlockHeaderState({
        showFavorite = false, 
        title, 
        icon,
        onClickView,
        onClickPrint, 
        onClickPayment, 
        onClickViewReceipts, 
        onClickCancel, 
        onClickEnable, 
        onRefresh,
        onClickAdd,

        visibleView = false,
        visiblePrint= false, 
        visiblePayment= false, 
        visibleViewReceipts= false, 
        visibleCancel= false, 
        visibleEnable= false, 
        visibleRefresh= false,
        visibleAdd= false,

        children,...rest }) {

    const { gridState: { data,
        disabledView, 
        disabledPrint, 
        disabledPayment, 
        disabledViewReceipts, 
        disabledCancel,
        disabledEnable,
        
    }, toolBarOpts } = useSelector(store => store); 

    const dispatch = useDispatch();

    const path = rest.location?.pathname;

    const existFavorite = toolBarOpts.find(x => x.name === title);

    const onClick = () => {
    
        if(existFavorite) 
            dispatch(removeToolBarAction(title));
        else
            dispatch(addToolBarAction({ name: title, path}));         

    }
     
    const showFavorites = path && showFavorite;

    const className = existFavorite ? 'd-hand dx-icon-favorites exist-favorite' : 'd-hand dx-icon-favorites';

    return (
        <div className="block-header">
            {title && <h3 className="block-header__title">{title} {icon && <span className={icon} style={{fontSize : 20}}></span>} {showFavorites && <span onClick={onClick} className={className} style={{fontSize : 20}}></span>}</h3>}
            <div className="block-header__divider" />       
            {visibleView && <Button hint='Ver ' onClick={() => onClickView(data.id)} icon={'search'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledView}/> }
            {visiblePrint && <Button hint='Imprimir' onClick={() => onClickPrint(data.id)} icon={'print'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledPrint}/> }
            {visiblePayment && <Button hint='Aplicar recibo' onClick={() => onClickPayment(0, data.id, true, data.nombre, data.balance)} icon={'money'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledPayment}/> }
            {visibleViewReceipts && <Button hint='Ver recibos' onClick={() => onClickViewReceipts(true, data.id, data.nombre)} icon={'product'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledViewReceipts}/> }
            {visibleCancel && <Button  hint='Anular' onClick={() => onClickCancel(data.id, data.nombre)} icon={'remove'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledCancel}/> }
            {visibleEnable && <Button  hint='Habilitar' onClick={() => onClickEnable(data.id)} icon={'check'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledEnable}/> }
            {visibleRefresh && <Button hint='refrescar' onClick={onRefresh} icon={'refresh'} stylingMode="contained" className='ml-10' type={'normal'} /> }
            {visibleAdd && <Button hint='Agregar' onClick={onClickAdd} icon={'plus'} stylingMode="contained" className='ml-10' type={'normal'} /> }
            {children}
        </div>
    );
}

export default BlockHeaderState;
