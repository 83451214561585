import { gridStateDefault, GRID_STATE_CC } from "../consts";

const myState = { ...gridStateDefault }

export const setGridStateCC =  newState => {
    
    return{ 
        type: GRID_STATE_CC, 
        payload : {...myState, ...newState} 
    }

} 


export default function gridStateCCReducer(state = myState, { type, payload }) {
   
    const actions = {
        [GRID_STATE_CC] : () => ({...payload })
    }

    return actions[type]?.call() || state;
}