import React, { useEffect, useState, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { createStoreLocal } from '../../utils/proxy';
import { editorOptionsSelect, formatDate, editorOptionsSwitch } from '../../data/app';
import { Button } from 'devextreme-react';

import { formatId } from '../../utils/common';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';

import useProducts from '../../hooks/useProducts';
import uri from '../../utils/uri';
import { authDialogAction } from '../../store/authorization/authDialogReducer';
import useClients from '../../hooks/useClients';
import useUsers from '../../hooks/useUsers';
import { typeAuths } from '../../data/enums';

const Nuevo = props => {

    const { authDialog: { open, id }, user, } = useSelector(store => store);
    const authDefaultCustom = { 
        typeId: null,
        userOneTime:false,
        observation: '',
        clientId: null,

    };

    const [ reload, setReload ] = useState(0);

    const { products } = useProducts({ areaId: user.areaId, exists: true, reload });
    const { clients } = useClients({  });
    const { users } = useUsers({  });

    const [auth, setAuth] = useState({ ...authDefaultCustom });
    const [saving, setSaving] = useState(false);

    let refForm = useRef();

    useEffect(() => {       

        if (id > 0) {          

            http(uri.authorizations.getById(id)).asGet().then(resp => {   
                setAuth({ ...auth, ...resp });
            })
           
        } else {

            if(open)
                setAuth({ ...authDefaultCustom });

        }

    }, [open]);

    const dispatch = useDispatch();    

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();       

        dispatch(authDialogAction({ id: 0, open: false }));
        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const guardar = (e) => {


        let result = refForm.current.instance.validate();

        if (result.isValid) {

            setSaving(true);
            let data = { ...auth };

            http(uri.authorizations.insert).asPost(data).then(resp => {
                setSaving(false);
                notify(`Regla registrada correctamente`);
                closeDialog(true);                    
            }).catch(err => {
                setSaving(false);
                notify(err, 'error', 5000);
            });

        }
        

    }

    const isNew = id == 0; 

    const isDiscount = auth.typeId == typeAuths.discount;
    const isCredit = auth.typeId == typeAuths.credit;

    return (
        <div>
            <Popup
                width={500}
                height={550}
                title={isNew ? `Nueva regla` : `Editar regla ${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"
            >                
                <Form formData={auth} ref={refForm}>
                    <GroupItem colCount={2}>

                        <SimpleItem dataField="typeId" colSpan={2} editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: createStoreLocal({ name: 'AuthorizationType' }),
                                ...editorOptionsSelect,
                                onValueChanged: e => setAuth({ ...auth, typeId: e.value })
                            }} >
                            <Label text="Tipo Autorización" />
                            <RequiredRule message="Seleccione el tipo" />
                        </SimpleItem>

                        <SimpleItem dataField="description" colSpan={2} editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Descriptión', showClearButton: true }} >
                            <Label text="Descriptión" />
                            <RequiredRule message="Este dato es requerido" />
                        </SimpleItem>

                        <SimpleItem dataField="discount" colSpan={2} editorType="dxNumberBox"
                            editorOptions={{
                               min:0,
                               max:100,
                               readOnly: !isDiscount,
                            }} >
                            <Label text="Descuento" />
                            {isDiscount && <RequiredRule message="Este dato es requerido" />}
                        </SimpleItem>

                        <SimpleItem dataField="useOneTime" colSpan={2} editorType="dxSwitch"
                            editorOptions={{
                               ...editorOptionsSwitch,
                               onValueChanged: e => setAuth({ ...auth, userOneTime: e.value, expiredDate: null })
                            }} >
                            <Label text="Un solo uso" />
                        </SimpleItem>

                        <SimpleItem dataField="expiredDate" colSpan={2} editorType="dxDateBox"
                            editorOptions={{
                                displayFormat: formatDate,
                                openOnFieldClick: true,
                                disabled: auth.userOneTime,
                                onValueChanged: e => setAuth({ ...auth, expiredDate: e.value })
                            }} >
                            <Label text="Fecha de fin" />
                            {!auth.userOneTime && <RequiredRule message="Seleccione la fecha" />}
                        </SimpleItem>
                        <SimpleItem dataField="productId" colSpan={2} editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: products,
                                ...editorOptionsSelect,
                                showClearButton:true,
                                onValueChanged: e => setAuth({ ...auth, clientId: e.value })
                            }} >
                            <Label text="Producto" />
                        </SimpleItem>
                        <SimpleItem dataField="clientId" colSpan={2} editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: clients,
                                ...editorOptionsSelect,
                                showClearButton:true,
                                onValueChanged: e => setAuth({ ...auth, clientId: e.value })
                            }} >
                            <Label text="Cliente" />
                        </SimpleItem>
                        <SimpleItem dataField="username" colSpan={2} editorType="dxSelectBox"
                            editorOptions={{
                                dataSource: users,
                                ...editorOptionsSelect,
                                showClearButton:true,
                                valueExpr: "username",
                                displayExpr: item => item ? `${item.username} | ${item.fullName}` : "",
                                onValueChanged: e => setAuth({ ...auth, username: e.value })
                            }} >
                            <Label text="Usuario" />
                        </SimpleItem>
                        <SimpleItem dataField="active" colSpan={2} editorType="dxSwitch"
                            editorOptions={{
                               ...editorOptionsSwitch,
                            }} >
                            <Label text="Activa" />
                        </SimpleItem>

                    </GroupItem>                   
                </Form>
                <br />
                <div className='flex-v'>
                    <Button
                        className="mt-10"
                        text={saving ? 'Guardando...' : `Guardar`}
                        type="success"
                        icon='save'
                        disabled={saving}
                        onClick={guardar}
                        />
                </div>

            </Popup>
        </div>
    );
}

export default Nuevo;
