import { dialogDefault, DIALOG_NC } from "../consts";

const mydialog = {...dialogDefault}


export const ncDialogAction = ({ open = false, id = 0, clientId = 0  }) => ({
    type: DIALOG_NC,
    payload : { open, id, clientId  }
});

export default function ncDialogReducer(state = mydialog, { type, payload }) {

    const actions = {
        [DIALOG_NC] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}

