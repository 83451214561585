import React, { useRef, useState, useEffect } from 'react';
import { Popup } from 'devextreme-react';
import Form, { SimpleItem, Label, StringLengthRule, RequiredRule } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useDispatch, useSelector } from 'react-redux'
import http from '../../utils/http';
import uri from '../../utils/uri';
import { defaultClient } from '../../data/defaultObjects';
import { editorOptionsSwitch, editorOptsTextBoxPhone, editorOptionsSelect, editorOptionsNumberBox, resources } from '../../data/app';
import { clientDialogAction } from '../../store/client/clientDialogReducer';
import { createStoreLocal } from '../../utils/proxy';
import useAuthorization from '../../hooks/useAuthorization';

const Nuevo = (props) => {

    const { isMobile } = props;    

    const [ _, canConfigureCredit ] = useAuthorization( resources.configurarCredito );

    const dispatch = useDispatch();
    
    const { clientDialog: { open, id  } } = useSelector(store => store);

    const [cliente, setcliente] = useState({ ...defaultClient }); 
    const [cities, setCities] = useState([]); 
    const [saving, setSaving] = useState({
        loading : false,
        text : 'Guardar'
    });

    const closeDialog = (load, clientId) => {

        dispatch(clientDialogAction({ id: 0, clientId: 0, open: false }));

        if(load)
            if(props.onSave)
                props.onSave();

    }

    const onHiding = ({ load }) => {

        closeDialog(load, 0);
        
    }

    const refForm = useRef();

    const guardarCliente = (params) => {

        let result = refForm.current.instance.validate();      

        if (result.isValid) {

            setSaving({loading : true, text:'Guardando...'});
            
            http(uri.clients.insert).asPost(cliente).then(resp => {

                notify('Datos guardados correctamente');
                setSaving({loading : false, text:'Guardar'});               
                closeDialog(true, resp.id);

            }).catch(err => {

                setSaving({loading : false, text:'Guardar'});
                notify(err, 'error');

            });

        }
    }

    useEffect(() => {
        http('catalogos/City').asGet().then(resp => setCities(resp))
    }, [0]); 

    useEffect(() => {

        if(id > 0)
            http(uri.clients.getById(id)).asGet().then(resp => setcliente(cli => ({ ...resp, isCompany: resp.isCompany ?? false })));
        else{
            setcliente({ ...defaultClient });
            refForm.current.instance.resetValues();
        }
    }, [open]); 

    const updateCliente = (prop) => e => { 
        setcliente({ ...cliente, [prop]: e.value });    
    }

    const propPop = isMobile ? { fullScreen: true } : { width: 700, height: 500 };

    return (
        <div>           
            <Popup
                title={`Cliente`}
                {...propPop}
                onHiding={onHiding}
                visible={open}
                showCloseButton={true}
                className="bg-fbfbfb"
            >
                <Form formData={cliente} ref={refForm} colCount={2}>
                    <SimpleItem dataField="isCompany" editorType='dxSwitch' editorOptions={{...editorOptionsSwitch, onValueChanged: updateCliente('isCompany')}}>
                        <Label text="Es Empresa?" />                        
                    </SimpleItem> 
                    <SimpleItem dataField="typeId" colSpan={2} editorType="dxSelectBox"
                        editorOptions={{
                            disabled: !cliente.isCompany,
                            dataSource: createStoreLocal({ name: 'clientType' }),
                            ...editorOptionsSelect,
                            onValueChanged: e => setcliente({ ...cliente, typeId: e.value, })
                        }} >
                        <Label text="Tipo" />
                        { cliente.isCompany && <RequiredRule message="Este campo es requerido" />}
                    </SimpleItem>     
                    <SimpleItem dataField="identification">
                        <Label text={cliente.isCompany ? 'RUC':'Identificación'} />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="birthdate" editorType='dxDateBox' editorOptions={{
                            disabled: cliente.isCompany,
                            openOnFieldClick:true
                        }}>
                        <Label text="Fecha de Nac" />
                    </SimpleItem>   
                    <SimpleItem dataField="name" colSpan={1}>
                        <Label text={ cliente.isCompany ? 'Comercio':'Nombre' } />
                        <RequiredRule message="Este campo es requerido" />
                        <StringLengthRule max={100} message="Maximo 100 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="lastName" >
                        <Label text={ cliente.isCompany ? 'Contacto':'Apellidos'} />
                        { cliente.isCompany == false && <RequiredRule message="Este campo es requerido" />}
                        <StringLengthRule max={100} message="Maximo 100 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="sexId"  editorType="dxSelectBox"
                        editorOptions={{
                            disabled: cliente.isCompany,
                            dataSource: createStoreLocal({ name: 'sex' }),
                            ...editorOptionsSelect,
                        }} >
                        <Label text="Sexo" />
                    </SimpleItem>  
                    <SimpleItem dataField="email">
                        <Label text="Correo" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>   
                    <SimpleItem dataField="phoneNumber" editorOptions={{...editorOptsTextBoxPhone}}>
                        <Label text="Teléfono" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>                   
                    <SimpleItem dataField="cellNumber" editorOptions={editorOptsTextBoxPhone}>
                        <Label text="Célular" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="departmentId"  editorType="dxSelectBox"
                        editorOptions={{                            
                            dataSource: createStoreLocal({ name: 'department' }),
                            onValueChanged: e => setcliente({ ...cliente, departmentId: e.value, }),
                            ...editorOptionsSelect,
                        }} >
                        <Label text="Departamento" />
                    </SimpleItem>     
                    <SimpleItem dataField="cityId"  editorType="dxSelectBox"
                        editorOptions={{                            
                            dataSource: cities.filter(c => c.departmentId == cliente.departmentId),
                            ...editorOptionsSelect,
                        }} >
                        <Label text="Ciudad" />
                    </SimpleItem> 
                   
                    

                    <SimpleItem dataField="creditLimit" editorType="dxNumberBox" editorOptions={{...editorOptionsNumberBox, min:0, readOnly: !canConfigureCredit}}>
                        <Label text="Monto Crédito" />
                    </SimpleItem>
                    <SimpleItem dataField="creditDays" editorType="dxNumberBox" editorOptions={{...editorOptionsNumberBox, min:0, readOnly: !canConfigureCredit}}>
                        <Label text="Dias Crédito" />
                    </SimpleItem>

                    <SimpleItem dataField="address" colSpan={2}>
                        <Label text="Dirección" />
                        <StringLengthRule max={250} message="Maximo 250 caracteres" />
                    </SimpleItem>
                    
                </Form>
                <br />
                <Button
                    width={180}
                    text={saving.text} 
                    disabled={saving.loading}
                    type="default"
                    icon='save'
                    onClick={guardarCliente}
                />
                <br />
            </Popup>
        </div>
    );
}

export default Nuevo;
