import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { 
    Paging, 
    Pager, 
    FilterRow, 
    HeaderFilter, 
    ColumnChooser, 
    Column, 
    Lookup,
    Editing} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../utils/proxy';
import Title from '../../components/shared/Title';
import BlockHeader from '../../components/shared/BlockHeader';
import { store } from '../../services/store';

import CustomButton from '../../components/buttons/CustomButton';
import { _path } from "../../data/headerNavigation";
import { dataAccess, formatDate, formatDateTime, resources } from '../../data/app';
import toCapital, { cellAsPayoff, cellRender, dataFormatId, formatId } from '../../utils/common';
import urlReport from '../../services/reportServices';
import useAuthorization from '../../hooks/useAuthorization';
import { openDialogRecibo } from '../../store/dialogRecibo/dialogReciboReducer';
import Nuevo from './Nuevo';
import { useDispatch } from 'react-redux';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import uri from '../../utils/uri';
import { onCellPreparedReceipt } from '../../components/bill/CellPrepared';

const Receipts = () => {
    
    const [ authorized ] = useAuthorization( resources.verRecibos );

    const dispatch = useDispatch();    
    let refGrid = useRef();

    const addMenuItems =(e) => {
        
        if (e.target == "content") {
            
            // e.items can be undefined
            if (!e.items) e.items = [];
 
            // Add a custom menu item
            if(e.rowIndex >= 0)
                e.items.push({

                    text: `Ver recibo`,
                    icon : 'doc',
                    onItemClick: () => {  },
                    color : 'red'
                },{

                    text: `Ver factura`,
                    icon : 'doc',
                    onItemClick: () => {  },
                    color : 'red'
                },{

                    text: `Re-imprimir recibo`,
                    icon : 'print',
                    onItemClick: () => {
                        const report = urlReport();
                        report.print(`${report.recibo(e.row.data.id)}`);
                    }
                    
                },{

                    text: `Anular recibo`,
                    icon : 'remove',
                    onItemClick: () => refGrid.current.instance.deleteRow(e.rowIndex),
                    color : 'red'
                });
           
        }
    }

    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            if (!e.data.active) 
                e.rowElement.classList.add('no-activo');
            
        }
    }

    const onToolbarPreparing = (e) => {  
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon:'exportxlsx',
                type:'success',
                stylingMode:"outlined",
                onClick: () =>  refGrid.current.instance.exportToExcel(false)
            }
        });
    } 

    const onSave = e => {
        
    }

    const title ='Recibos de caja';

    return authorized(
        <div className="container">
            <Title title={title}/>
            <BlockHeader title={title}>
                <CustomButton
                    type="default"
                    text={`Generar recibo`}
                    icon='plus'
                    onClick = {() => {
                        dispatch(openDialogRecibo(0));
                    }}
                />
            </BlockHeader>
            <Nuevo onSave={onSave} />
            <DataGrid id="gridReceipt"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({uri : uri.receivables, remoteOperations: true })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                onContextMenuPreparing={addMenuItems}
                onRowPrepared={onRowPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onCellPrepared={onCellPreparedReceipt}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column dataField="id" caption="Recibo #" width={90} cellRender={dataFormatId} />
                <Column dataField="createAt" caption="Fecha" dataType='date'  format={formatDate} width={120}/>               
                <Column dataField="billId" caption="Factura #" width={90} cellRender={dataFormatId} />
                <Column dataField="nombre" caption="Cliente" />                           
                <Column dataField="amount" caption="Pagado"  width={100} cellRender={cellRender()} />
                <Column dataField="balance" caption="Pendiente"  width={100} cellRender={cellRender()} />
                <Column dataField="createBy" caption='Creado por' width={100} visible={false} />
                <Editing
                    mode="popup"                 
                    allowDeleting={true}
                    useIcons={true}
                ></Editing>
            </DataGrid>
        </div>
    );
}

export default Receipts;
