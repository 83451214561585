import React, { useEffect, useState } from 'react';
import uri from '../utils/uri';
import http from '../utils/http';

const useClientDetail = ({clientId = 0}) => {

    const [data, setData] = useState({});
    const [ isLoading, setIsLoading] = useState(false);

    const getClient = async () => {

        setIsLoading(true);
        const resp = await  http(`${uri.clients.getById(clientId)}/detail`).asGet();
        setData(resp);
        setIsLoading(false);
       
    }

    useEffect(() => {        

        if (clientId > 0)
            getClient();
        else
            setData({});

    }, [clientId]);

    return {
        data,
        isLoading
    };
}

export default useClientDetail;
