import React from 'react';
import PreBills from './PreBills';
import { preBillType } from '../../data/enums';

const PreVentas = (props) => {

    const title = "Pre-Ventas";
    const action = "Pre-Venta";
    const typeId = preBillType.preventa;
    const initStates = [2,3];

    return (
        <PreBills title={title} action={action} typeId={typeId} initStates={initStates} {...props}/>
    );

}

export default PreVentas;
