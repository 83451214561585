import React, { useRef, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import {
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Column,
    Lookup,
    Editing,
    Button as ButtonGrid,
    Export
} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../../utils/proxy';
import Title from '../../../components/shared/Title';
import BlockHeader from '../../../components/shared/BlockHeader';
import { store } from '../../../services/store';
import CustomButton from '../../../components/buttons/CustomButton';
import { _path } from "../../../data/headerNavigation";
import { formatDate, formatDateTime, resources } from '../../../data/app';
import {  cellRender, dataFormatIdMobile, } from '../../../utils/common';
import urlReport from '../../../services/reportServices';
import useAuthorization from '../../../hooks/useAuthorization';
import { billDialogAction } from '../../../store/bill/billDialogReducer';
import { useDispatch } from 'react-redux';

import uri from '../../../utils/uri';
import http from '../../../utils/http';
import notify from 'devextreme/ui/notify';
import { preBillType } from '../../../data/enums';
import { confirm } from 'devextreme/ui/dialog';
import BlockHeaderState from '../../../components/shared/BlockHeaderState';
import { setGridState } from '../../../store/gridState/gridStateReducer';
import NuevoNew from './NuevoNew';

const PreBills = props => {

    const { clientId = 0, title='',action='', typeId=0, initStates, targetPage = '' } = props;

    const [ authorized ] = useAuthorization( resources.cotizacion );
    const [states, setStates] = useState([...initStates]);
    const dispatch = useDispatch();
    let refGrid = useRef();

    const printReport = ( id ) => {

        //const { id } = row.data;
        const report = urlReport();
        report.print(`${report.preBillTicket(id)}`);

    }

    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            if (!e.data.active)
                e.rowElement.classList.add('no-activo');
            else{
                if (e.data.preBillTypeId == preBillType.facturacion)
                    e.rowElement.classList.add('converted');
                
            }

        }
    }

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxTagBox',
            options: {
                width: 320,
                placeholder: 'Filtrar por Tipo',
                dataSource : e.component.columnOption('preBillTypeId').lookup.dataSource,
                valueExpr:'id', 
                displayExpr:'name',
                value: states,
                showClearButton:true,
                multiline:false,
                hideSelectedItems:true,
                onValueChanged: (e) => {
                    setStates(e.value);
                }

            }
        });
    }

    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
        }

    }

    const showDialog = (id) => dispatch(billDialogAction({ open: true, id }));

    //const showDialog2 = (id) => dispatch(billDialogAction2({ open: true, id }));

    const onSave = (e) => refGrid.current.instance.refresh(); 

    const changePreBill = ({ title, action, id }) => {

        let result = confirm("<i>Esta seguro de  "+title+"?</i>", title);
        result.then((dialogResult) => {

            if (dialogResult){
                
                http(`${uri.prebills.base}/${id}/${action}`).asGet().then(resp => {
                    
                    notify(`Acción ${title} realizada correctamente`);
                    refGrid.current.instance.refresh();   
                    
                }).catch(err => {
                    
                    notify(err, 'error', 5000);
                });
                
            }
        });

    }

    const revert = ({ row }) => {

        const { id } = row.data;
        changePreBill({ title: 'Revertir a cotización', action: 'revert', id });

    }    

    const convert = ({ row }) => {

        const { id } = row.data;
        changePreBill({ title: 'Convertir a preventa', action: 'convert', id });       

    }

    const onRowClick = e => {
        
        const { id, active } = e.data;

        dispatch(setGridState({
            id: id, 
            data: e.data,
            disabledView: false, 
            disabledPrint: false, 
            disabledPayment: true, 
            disabledViewReceipts: true, 
            disabledCancel: !active 
        }));

    }

    const deleteRow = (id) => {
        refGrid.current.instance.deleteRow(id)
    }


    let extraParameter =[['preBillTypeId', states]];

    if (clientId > 0)
        extraParameter.push(['clientId', clientId]);    

    return authorized(
        <div className="container mobile">
            <Title title={title} />
            <BlockHeader title={title}>
                <CustomButton
                    type="default"                    
                    icon='menu'
                    onClick={() =>  props.history.push({ pathname: '/app/mobile/' + targetPage })
                }
                />         
                <CustomButton
                    type="default"
                    text={`Nueva ${action}`}
                    icon='plus'
                    onClick={() => showDialog(0)}
                />              
                     
            </BlockHeader>   
            <BlockHeaderState icon="icon-file-text" 
                onClickView={(id) => showDialog(id)}
                onClickPrint={id => printReport(id)}               
                onClickCancel={deleteRow}
                onRefresh={() => refGrid.current.instance.refresh()}                
                onClickAdd={() => showDialog(0)}
                visibleView={true}
                visiblePrint={true}
                visibleCancel={true}
                visibleRefresh={true}
                visibleAdd={true}
                >              
            </BlockHeaderState>        
            <NuevoNew onSave={onSave} { ...props } />
            <DataGrid id="gridContainer"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.prebills, remoteOperations: true, extraParameter })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                onRowPrepared={onRowPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onCellPrepared={onCellPrepared}
                onRowClick={onRowClick}             
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <Export enabled={false} fileName={title} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column type="buttons" width={40} >
                    <ButtonGrid visible={typeId == preBillType.cotizacion} name="convert" icon="refresh" onClick={convert} hint={'Convertir a preventa'}/>
                    <ButtonGrid visible={typeId == preBillType.preventa} name="revert" icon="revert" onClick={revert} hint={'Revertir a cotización'}/>
                </Column>
                <Column dataField="id" caption="Id" width={55} cellRender={dataFormatIdMobile} />
                <Column dataField="nombre" caption="Cliente" visible={clientId == 0} allowHeaderFiltering={false} minWidth={180} />
                <Column dataField="total" width={100} cellRender={cellRender()} />
                <Column dataField="billId" caption="Factura" width={70} cellRender={dataFormatIdMobile} />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={100} />
                <Column dataField="paymentTypeId" width={100} caption="Tipo pago">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="preBillTypeId" width={110} caption="Documento">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'preBillType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="active" width={100} caption="Activa" />
                <Column dataField="createBy" caption='Creado por' width={100} />
                <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} width={180} />
                <Column type="buttons" width={90} visible={true}>
                    <ButtonGrid name="print" icon="print" onClick={e => printReport(e.row.data.id)}/>
                    <ButtonGrid name="edit" icon="find" onClick={e => showDialog(e.row.data.id)}/>
                    <ButtonGrid name="delete" />
                </Column>
                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowUpdating={true}
                    useIcons={true}
                ></Editing>
            </DataGrid>
        </div>
    );
}

export default PreBills;
