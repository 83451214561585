import {  CLIENT_DETAIL } from "../consts";

const stateInit = {clientId: 0};


export const clientDetailAction = ({clientId}) => ({
    type: CLIENT_DETAIL,
    payload : {clientId}
});

export default function clientDetailReducer(state = stateInit, { type, payload }) {

    const actions = {
        [CLIENT_DETAIL] : () => {
            return {...stateInit,...payload}
        }
    }

    return actions[type]?.call() || state;
}

