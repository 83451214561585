import React from 'react';

import {
    Chart,
    CommonSeriesSettings,
    Series,
    ArgumentAxis,
    Grid,
    Crosshair,
    Export,
    Legend,
    Point,
    Label,
    Font,
    Title,
    Subtitle,
    Tooltip,
  } from 'devextreme-react/chart';
import { formatToMoneyNumber } from '../../../../utils/common';
import moment from 'moment';


const ChartLine = ({ data, type, typeLabel, reverseType, reverseTypeLabel , collection, currentCategory }) => {
    
    const customizeTooltip = (pointInfo) => ({
        text: `${formatToMoneyNumber(pointInfo.valueText)}`,
    });

    function onLegendClick(e) {
        e.target.isVisible() ? e.target.hide() : e.target.show();
    }

    const customizeText = (e) => moment(e.value).format('DD-MMM');

    const orderByDate = (a, b) => {
        return new Date(a.date) - new Date(b.date);
    }

    return (
        <Chart
            id={`chart-${type}`}
            dataSource={data.sort(orderByDate)}
            onLegendClick={onLegendClick}            
            title={`Top ventas de ${typeLabel} por ${reverseTypeLabel}  ${currentCategory}`}
            >
            <CommonSeriesSettings
                argumentField={'date'}
                type="spline"                
            />
            {collection.map((item) => (
                <Series
                    key={item}
                    valueField={item}
                    name={item}
                    ignoreEmptyPoints={true}
                    showInLegend={true}
                />
            ))}
             <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
            >
                <Grid visible={true} />
                <Label customizeText={customizeText} />
            </ArgumentAxis>
            <Crosshair
                enabled={true}
                color="#949494"
                width={3}
                dashStyle="dot"
            >
                <Label
                visible={true}
                backgroundColor="#949494"
                >
                <Font
                    color="#fff"
                    size={12}
                />
                </Label>
            </Crosshair>
            <Legend
                verticalAlignment="left"
                horizontalAlignment="left"
                itemTextPosition="left"
                
            />
            <Title text="Energy Consumption in 2004">
                <Subtitle text="(Millions of Tons, Oil Equivalent)" />
            </Title>
            <Export enabled={true} />
            <Tooltip enabled={true} customizeTooltip={customizeTooltip}/>
        </Chart>
    );
}

export default ChartLine;
