import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { Column, Editing, Pager, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import BlockHeader from '../../../components/shared/BlockHeader';
import { onCellPreparedReceipt } from '../../../components/bill/CellPrepared';
import { cellRender, dataFormatId, formatId, formatToMoney, toMoney } from '../../../utils/common';
import { store } from '../../../services/store';
import uri from '../../../utils/uri';
import { formatDateTime } from '../../../data/app';
import urlReport from '../../../services/reportServices';

const MobileReceiptByBillId = (props) => {

    const { onSave } = props;
    const refGrid = useRef();

    const onRowRemoved = (e) => {
        onSave();
    }

    const addMenuItems =(e) => {
        
        if (e.target == "content") {
            
            // e.items can be undefined
            if (!e.items) e.items = [];
 
            // Add a custom menu item
            if(e.rowIndex >= 0)
                e.items.push({

                    text: `Imprimir recibo`,
                    icon : 'print',
                    onItemClick: () => {
                        const report = urlReport();
                        report.print(`${report.receiptTicket(e.row.data.id)}`);
                    }
                    
                });
           
        }
    }

    const { id, reference, cliente } = props;
    const title = `Recibos de la factura ${formatId(reference)}`;
    const titleCliente = `Cliente: ${cliente}`;
    
    return (<>
            <BlockHeader title={title} />
            <BlockHeader title={titleCliente} />
            <DataGrid
                id="gridReceipt"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: { get : uri.receivables.byBill(id), remove : uri.receivables.remove } })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}        
                onCellPrepared={onCellPreparedReceipt}     
                onRowRemoved={onRowRemoved}     
                columnHidingEnabled={true}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
              
                <Column dataField="referencePhysical" caption="Recibo" width={70} cellRender={dataFormatId} />
                <Column dataField="amount" caption="Pagado"  width={70} cellRender={toMoney} />
                <Column dataField="balance" caption="Pendiente"  width={80} cellRender={toMoney} />
                <Column dataField="observation" caption="Observacion"  />
                <Column dataField="createBy" caption='Creado por' width={100} />
                <Column dataField="createAt" caption="Creado el" dataType='date' format={formatDateTime} width={140} />
                <Summary>                
                    <TotalItem
                        column="amount"
                        summaryType="sum" 
                        customizeText={data => `${formatToMoney(data.value)}`} />
                </Summary>         
                <Editing
                    mode="popup"                 
                    allowDeleting={true}
                    useIcons={true}
                ></Editing>     
            </DataGrid>
    </>);
    
}

export default MobileReceiptByBillId;
