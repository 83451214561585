import React, {  useRef } from "react";
import {
    Paging,
    Pager,
    FilterRow,
    ColumnChooser,
    Column,
    Export,   
    Button as ButtonGrid,   
    Editing,
    Popup,     
    Form, 
    RequiredRule,
}
    from 'devextreme-react/data-grid';
   
import { Item } from 'devextreme-react/form';
import { DataGrid } from 'devextreme-react';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from "../../components/shared/Title";
import { store } from "../../services/store";
import {  resources, formatDateTime, formatDate } from '../../data/app';
import uri from "../../utils/uri";
import useAuthorization from "../../hooks/useAuthorization";
import { cellRender } from "../../utils/common";
import { userService } from "../../services/user.service";
import { confirm } from "devextreme/ui/dialog";
import http from "../../utils/http";

const Cuts = props => {

    var user = userService.getUser();
   
    const { history } = props;

    const [ authorized ] = useAuthorization( resources.gestionCierreCaja );

    let dataGrid = useRef();
    const title = "Cierres de caja";        

    const onToolbarPreparing = e => {

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                stylingMode:"outlined",
                type:'default',
                icon: 'add',
                text: 'Nuevo cierre de caja',
                onClick: e => dataGrid.current.instance.addRow()// dispatch(openDialog({open : true, id : 0}))
            }
        });
    }

    const onRowDblClick = (e) => {
        history.push('/clinica/cliente/'+e.data.id);
    } 

    const onInitNewRow = (e) => {
        e.data.createBy = user.username;
    }

    const reopen = (e) => { 

        const { id } = e.row.data;  
        let confirmResult = confirm('¿Desea reabrir la caja?', 'Reapertura de caja');
        confirmResult.then(res => {
            if(res){
                dataGrid.current.instance.option('loadPanel.enabled', true);
                http(uri.cuts.base + '/re-open/' +id).asGet().then(resp => {
                    dataGrid.current.instance.option('loadPanel.enabled', false);
                    dataGrid.current.instance.refresh();
                });
            }
        });

    }


    return authorized(
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} />           
            <DataGrid
                ref={dataGrid}
                dataSource={store({uri : uri.cuts, remoteOperations: true })}
                selection={{ mode: 'single' }}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnHidingEnabled={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                onToolbarPreparing={onToolbarPreparing}
                onRowDblClick={onRowDblClick}
                onInitNewRow={onInitNewRow}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}               
            >
                <Pager allowedPageSizes={[10, 15, 30, 50]} showPageSizeSelector={true} showInfo={true} />
                <Paging defaultPageSize={15} />
                <FilterRow visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={true} fileName={title} allowExportSelectedData={true} />      
                <Column type='buttons'>
                    <ButtonGrid icon='pulldown'
                        visible={e => e.row.data.status}
                        hint={'Reaperturar caja'}
                        onClick={reopen}
                    />
                    <ButtonGrid icon='menu'
                        visible={e => !e.row.data.status}
                        hint={'Ejecutar cierre'}
                        onClick={e => {

                            const { id } = e.row.data;  
                            props.history.push('/app/cierre/ejecute', { id });

                        }}
                    />
                </Column>               
                <Column dataField="id" caption="#" width={70} />
                <Column dataField="descripcion" caption="Corte" minWidth={180} />
                <Column dataField="cutDate" caption="Fecha" minWidth={180} dataType="date" format={formatDate}/>
                <Column dataField="createBy" caption="Usuario" />
                <Column dataField="startAt" caption="Inicia" width={180} dataType="date" format={formatDateTime} />
                <Column dataField="endAt" caption="Termina" width={180} dataType="date" format={formatDateTime} />
                <Column dataField="initValue" caption="Saldo inicial" cellRender={cellRender(1)} />
                <Column dataField="endValue" caption="Saldo Final" cellRender={cellRender(1)}/>
                <Column dataField="status" caption="Cerrada?" />
                <Column type='buttons'>
                    <ButtonGrid name='edit' /> 
                </Column>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    useIcons={true}
                >
                    <Popup title={'Apertura de caja'} showTitle={true} width={400} height={300}>

                    </Popup>
                    <Form>
                        <Item dataField="initValue" colSpan={2}>
                            <RequiredRule message="El campo es requerido" />                            
                        </Item>
                        <Item dataField="cutDate" colSpan={2} editorOptions={{ openOnFieldClick: true }}>
                            <RequiredRule message="El campo es requerido" />                            
                        </Item>
                        <Item dataField="createBy" colSpan={2} editorOptions={{ readOnly: true}}>
                        </Item>          
                    </Form>
                </Editing>
            </DataGrid>
        </div>
    )


}


export default Cuts;
