import React from 'react';
import { Grid, ArgumentAxis,
    Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Label, Export, Tooltip, Title, Subtitle, Margin
} from 'devextreme-react/chart';
import useApi from '../../hooks/useApi';
import uri from '../../utils/uri';
import { toMoney } from '../../utils/common';

const SalesMonthly = () => {

    const { data } = useApi({ url: uri.dashboard.base + '/get-sales-monthly' });

    const customizeTooltip = (arg) => {
        return { text: `${arg.seriesName} total: ${toMoney({value: arg.valueText}) }` };
    }

    return (
        <Chart
            id="chart"
            title="Ventas mensuales"
            dataSource={data}
            className='w-100'
        >
            <CommonSeriesSettings argumentField="month" type="stackedBar" barWidth={10} />
            <Series
                color={'#727cf5'}
                valueField="creditValue"
                name="Credito"
            />
            <Series
                color={'#efefef'}
                valueField="cashValue"
                name="Contado"
            />

            <ValueAxis position="left">
            </ValueAxis>
            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="top"
            />
            <Export enabled={true} />
            <Tooltip
                enabled={true}
                location="edge"
                customizeTooltip={customizeTooltip}
            />
        </Chart>
    );
}

export default SalesMonthly;
