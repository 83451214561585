import React from 'react';
import { Grid, ArgumentAxis,
    Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Label, Export, Tooltip, Title, Subtitle, Margin
} from 'devextreme-react/chart';
import useApi from '../../hooks/useApi';
import uri from '../../utils/uri';
import PieChart, {
    Legend as PieLegend,
    Series as PieSeries,
    Tooltip as PieTooltip,
    Format as PieFormat,
    Label as PieLabel,
    Connector,
    Export as PieExport,
} from 'devextreme-react/pie-chart';
const CategorySales = () => {

    const { data } = useApi({ url: uri.dashboard.base + '/get-sales-family' });

    const customizeTooltip2 = arg => {
        return { text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`, }
    }

    return (
        <PieChart
            id="pie"
            type="doughnut"
            title="Ventas por categoria"
            palette="Soft Pastel"
            dataSource={data}
        >
            <PieSeries argumentField="family">
                <PieLabel visible={true} forma="millions">    <Connector visible={true} />
                </PieLabel>
            </PieSeries>
            <PieExport enabled={true} />
            <PieLegend
                margin={0}
                horizontalAlignment="right"
                verticalAlignment="top"
            />
            <PieTooltip enabled={true} customizeTooltip={customizeTooltip2}>
                <PieFormat type="millions" />
            </PieTooltip>
        </PieChart>
    );
}

export default CategorySales;
