import React, { useRef, useState }from "react";
import {
    Paging,
    Pager,
    FilterRow,
    ColumnChooser,
    Column,
    Lookup,
    Export,       
    Summary,
    TotalItem, 
}
    from 'devextreme-react/data-grid';
import * as NuevoReceipt from '../../components/bill/Nuevo';
import { DataGrid, ProgressBar, Popup } from 'devextreme-react';
import Title from "../../components/shared/Title";
import { store } from "../../services/store";
import { formatDate, formatDateTime } from '../../data/app';
import { useDispatch } from 'react-redux';
import { cellRender, cellRenderBold, dataFormatId } from "../../utils/common";
import numeral from "numeral";
import moment from "moment";
import BlockHeaderStateCC from "../../components/shared/BlockHeaderStateCC";
import { setGridStateCC } from '../../store/gridStateCC/gridStateCCReducer';
import { billDialogAction } from '../../store/bill/billDialogReducer';
import Nuevo from '../bills/Nuevo';
import urlReport from "../../services/reportServices";
import ReceiptByBillId from "../receipts/ReceiptByBillId";

const Pending = props => {

    const [dataDialog, setDataDialog] = useState({ id: 0, billId: 0, open: false, client: '', balance: '' });
    const [dialogReceipt, setDialogReceipt] = useState({ open: false, id: 0, reference: 0 });

    let refGrid = useRef();

    const dispatch = useDispatch();

    const showDialog = (id) => dispatch(billDialogAction({ open: true, id }));  
    
    const printReport = ( id ) => {
       
        const report = urlReport();
        report.print(`${report.billTicket(id)}`);

    }

    const close = e => {
        setDataDialog({ id: 0, billId: 0, open: false, client: '', balance: '' });
    }
   
    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
            if (e.column.dataField == 'mora')
                if(e.row.data.mora < 0)
                    e.cellElement.classList.add('col-mora');
        }

    }

    const title = "Cuentas por cobrar";     
    
    const customizeText = (cellInfo) => {
        return `C$ ${numeral(cellInfo.value).format('0,0.00')}`
    }

    const cellBar = (data) => {

        const { creditDays, daysInDebt, mora, expirationDate } = data.row.data;

        const vence = moment(expirationDate).format('DD/MM/YYYY');

        const statusFormat = value => mora < 0 ? `Vencida el ${vence}` : `${creditDays - daysInDebt} dias restantes. Vence el ${vence}`;

        return (
            <ProgressBar
                id="progress-bar-status" 
                className={mora < 0 ? 'bar-red' : 'bar-blue'}              
                min={0}                
                max={creditDays}
                value={daysInDebt}
                statusFormat={statusFormat}
                showStatus={true}
            />
        )
    }

    const onRowClick = e => {
        
        const { id } = e.data;

        dispatch(setGridStateCC({
            id: id, 
            data: e.data,
            disabledView: false, 
            disabledPrint: false, 
            disabledPayment: false, 
            disabledViewReceipts: false, 
            disabledCancel: false }));

    }

    const onSave = (e) => refGrid.current.instance.refresh();

    return (
        <div className="container">
            <Title title={title}/>
            <BlockHeaderStateCC title={title} icon="icon-file-text"  showFavorite={true} {...props}
                onClickView={(id) => showDialog(id)}
                onClickPrint={id => printReport(id)}
                onClickPayment={(id, billId, open, client, balance) => setDataDialog({ id: 0, billId, open, client, balance })}
                onClickViewReceipts={(open, id, nombre) => setDialogReceipt({ open, id, reference: id, cliente:nombre })}
            >                        
            </BlockHeaderStateCC>   
            <Popup
                width={800}
                height={400}
                title={`Recibos`}
                onHiding={e => setDialogReceipt({ open: false, id: 0 })}
                visible={dialogReceipt.open}
                className="bg-fbfbfb"
            >
                <ReceiptByBillId id={dialogReceipt.id} reference={dialogReceipt.reference} onSave={onSave} cliente={dialogReceipt.cliente} />
            </Popup>   
            <Nuevo onSave={onSave} { ...props } />
            <NuevoReceipt.default data={dataDialog} close={close} onSave={() => 0} />
            <DataGrid
                ref={refGrid}
                dataSource={store({uri : { get : 'clients/pending/get' } })}
                selection={{ mode: 'single' }}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnHidingEnabled={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}  
                onRowClick={onRowClick}
                onCellPrepared={onCellPrepared}        
            >
                <Pager allowedPageSizes={[10, 15, 30, 50]} showPageSizeSelector={true} showInfo={true} />
                <Paging defaultPageSize={15} />
                <FilterRow visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={true} fileName={title} allowExportSelectedData={true} />       
                <Column dataField="id" caption="Factura" width={70} cellRender={dataFormatId} alignment="rigth" />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={100} />
                <Column dataField="name" caption="Cliente" allowHeaderFiltering={false} minWidth={100} />
                <Column dataField="asignedTo" caption='Vendedor' width={100} />               
                <Column dataField="total" width={110} cellRender={cellRender()} />
                <Column dataField="amount" caption='Pagado' width={110} cellRender={cellRender()} />
                <Column dataField="balance" caption='Saldo' width={110} cellRender={cellRenderBold()} />
                <Column dataField="mora" caption='Mora' width={60} />
                <Column dataField="expirationDate" caption='Expira' dataType='date' format={formatDate} width={100} />
                <Column dataField="progress" caption="Activa" cellRender={cellBar} />
                <Column dataField="createAt" caption='Creado el' dataType='date' visible={false} format={formatDateTime} width={180} />    
                <Summary>                    
                    <TotalItem column="total" summaryType="sum" customizeText={customizeText} />
                    <TotalItem column="amount" summaryType="sum" customizeText={customizeText} />
                    <TotalItem column="balance" summaryType="sum" customizeText={customizeText} />
                </Summary>
            </DataGrid>
        </div>
    )


}


export default Pending;
