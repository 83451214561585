import { dialogDefault, DIALOG_AUTH } from "../consts";

const mydialog = {...dialogDefault}


export const authDialogAction = ({ open = false, id = 0 }) => ({
    type: DIALOG_AUTH,
    payload : { open, id   }
});

export default function authDialogReducer(state = mydialog, { type, payload }) {

    const actions = {
        [DIALOG_AUTH] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}

