import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { EmailRule, Item } from 'devextreme-react/form';
import { 
    Paging, 
    Pager, 
    FilterRow, 
    HeaderFilter, 
    ColumnChooser, 
    Column, 
    Export, 
    Editing,   
    Button as ButtonGrid,
    Lookup} from 'devextreme-react/data-grid';

import uri from '../../utils/uri';
import { store } from '../../services/store';
import Title from '../../components/shared/Title';
import BlockHeader from '../../components/shared/BlockHeader';
import useAuthorization from '../../hooks/useAuthorization';
import { dataAccess, editorOptionsSwitch, formatDateTime, resources } from '../../data/app';
import { createStoreLocal } from '../../utils/proxy';
import Nuevo from './Nuevo';
import { useDispatch } from 'react-redux';
import { authDialogAction } from '../../store/authorization/authDialogReducer';

const Authorizations = () => {

    const [ authorized ] = useAuthorization( resources.auths );

    const title = 'Autorización de descuentos';

    let gridRef = useRef();

    const dispatch = useDispatch();

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Nueva autoriación',
                icon: 'plus',
                type:'default',
                stylingMode:"outlined",
                onClick: () => showDialog(0)
            }
        });
    }

    const showDialog = (id) => {
        dispatch(authDialogAction({ open: true, id }));
    }

    const onSave = () => {
        gridRef.current.instance.refresh();
    }

    return authorized(
        <div className="container">
            <Nuevo onSave={onSave}/>
            <Title title={title} />
            <BlockHeader title={title} />
            <DataGrid id="gridContainer"
                ref={gridRef}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.authorizations })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                onToolbarPreparing={onToolbarPreparing}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={true} fileName={title} allowExportSelectedData={true} />
                <Column dataField="codeAuth" caption='Codigo' width={130}/>
                <Column dataField="typeId" width={150} caption="Tipo pago">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'AuthorizationType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="description" caption='Descripción' />
                <Column dataField="used" caption="Aplicado" dataType="boolean"  width={100}/>
                <Column dataField="active" caption="Activo" dataType="boolean"  width={100}/>

                <Column dataField="createdBy" caption='Creado por' width={90} />
                <Column dataField="createdAt" caption='Creado el' dataType='date'  format={formatDateTime} width={150} />
                <Column visible={false} dataField="modifiedBy" caption='Modificado por' width={90} />
                <Column visible={false} dataField="modifiedAt" caption='Modificado el' dataType='date'  format={formatDateTime} width={150} />
                <Column type="buttons" width={60}>
                    <ButtonGrid name="edit" icon="find" onClick={e => showDialog(e.row.data.id)}/>
                    <ButtonGrid name="delete" />
                </Column>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                >                    
                </Editing>
            </DataGrid>
        </div>
    );
}

export default Authorizations;
