import React, { useRef, useState } from 'react';
import { DataGrid, Popup } from 'devextreme-react';
import {
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Column,
    Lookup,
    Editing,
    Button as ButtonGrid,
    Export
} from 'devextreme-react/data-grid';
import { createStoreLocal } from '../../utils/proxy';
import Title from '../../components/shared/Title';
import { store } from '../../services/store';
import * as NuevoReceipt from '../../components/bill/Nuevo';
import CustomButton from '../../components/buttons/CustomButton';
import { _path } from "../../data/headerNavigation";
import { formatDate, formatDateTime } from '../../data/app';
import { cellAsPayoff, cellRender, dataFormatId, } from '../../utils/common';
import urlReport from '../../services/reportServices';
import { billDialogAction } from '../../store/bill/billDialogReducer';
import Nuevo from './Nuevo';
import { useDispatch } from 'react-redux';
import { confirm } from 'devextreme/ui/dialog';

import uri from '../../utils/uri';
import ReceiptByBillId from '../receipts/ReceiptByBillId';
import { setGridState } from '../../store/gridState/gridStateReducer';
import BlockHeaderState from '../../components/shared/BlockHeaderState';
import BillDisabled from './BillDisabled';
import { billDisabledDialogAction } from '../../store/bill/billDisabledDialogReducer';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';

const Bills = props => {

    const { clientId = 0 } = props;
    
    const [dataDialog, setDataDialog] = useState({ id: 0, billId: 0, open: false, client: '', balance: '' });
    const [dialogReceipt, setDialogReceipt] = useState({ open: false, id: 0, reference: 0 });
    const dispatch = useDispatch();
    let refGrid = useRef();

    const printReport = ( id ) => {
       
        const report = urlReport();
        report.print(`${report.billTicket(id)}`);

    }



    const addMenuItems = (e) => {

        if (e.target == "content") {
            
            if (!e.items) e.items = [];
            
            if (e.rowIndex >= 0){

                const { id, nombre, balance, paymentTypeId } = e.row.data;

                const isContado = paymentTypeId == 1;

                e.items.push({
                    
                    text: `Ver factura`,
                    icon: 'find',
                    onItemClick: () => {
                        showDialog(e.row.data.id);                        
                    }

                },{

                    text: `Imprimir factura`,
                    icon: 'print',
                    onItemClick: () => {
                        printReport(id);
                    }
                });               

                e.items.push({
                    
                    text: `Pagar Factura`,
                    icon: 'money',                    
                    disabled: isContado,
                    onItemClick: () => {
                        setDataDialog({ id: 0, billId: id, open: true, client: nombre, balance });
                    }
                    
                },{
                    
                    text: `Ver Recibos`,
                    disabled: isContado,
                    icon: 'product',
                    onItemClick: () => {
                        setDialogReceipt({ open: true, id, reference:id, cliente:nombre })
                    }
                    
                });                

                e.items.push({

                    text: `Anular factura`,
                    icon: 'remove',
                    onItemClick: () => refGrid.current.instance.deleteRow(e.rowIndex),
                    color: 'red'

                });               
                
            }

        }
    }

    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            if (!e.data.active)
                e.rowElement.classList.add('no-activo');

        }
    }

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Exportar a excel',
                icon: 'exportxlsx',
                type: 'success',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.exportToExcel(false)
            }
        });
    }

    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
        }

    }

    const showDialog = (id) => dispatch(billDialogAction({ open: true, id }));    
     
    const showBillDisabledDialog = (id, name) => dispatch(billDisabledDialogAction({ open: true,  id, name, }));

    const onSave = (e) => refGrid.current.instance.refresh();

    const extraParameter = clientId > 0 ? { key: 'clientId', value: clientId } : null;

    const close = e => {
        setDataDialog({ id: 0, billId: 0, open: false, client: '', balance: '' });
    }

    const enableBill = id => {

        let resultConfirm = confirm("<i>Esta seguro de rehabilitar la factura " + id + "?</i>", "Confirmar");
        resultConfirm.then((dialogResult) => {
            if (dialogResult) {
                http(`${uri.bills.base}/${id}/update`).asGet().then(resp => {

                    notify(`Factura rehabilitada correctamente`);
                    refGrid.current.instance.refresh();

                }).catch(err => {

                    notify(err, 'error', 5000);

                });
            }
        });

    }

    const onRowClick = e => {
        
        const { paymentTypeId, id, active } = e.data;

        const isContado = paymentTypeId == 1;

        dispatch(setGridState({
            id: id, 
            data: e.data,
            disabledView: false, 
            disabledPrint: false, 
            disabledPayment: isContado, 
            disabledViewReceipts: isContado, 
            disabledCancel: !active, 
            disabledEnable: active === true
        }));

    }

    const onRowDblClick = e => {
        showDialog(e.data.id);
    }

    const cellRenderDelivery = (cellData) => {

        const { isDeliveried } = cellData.data;

        let deliveriedClassName = 'not-deliveried';
        if(isDeliveried)
            deliveriedClassName = 'is-deliveried';
        
        return (
            <div>
                <div className="current-value">
                    <span className={deliveriedClassName}> {isDeliveried ? 'Entregado': 'Sin entrega'} </span>
                </div>                
            </div>
        );
    };

    const title = 'Facturas';

    return (
        <div className="container">
            <Title title={title} />           
            <BlockHeaderState showFavorite={true} title={title} icon="icon-file-text" 
                onClickView={(id) => showDialog(id)}
                onClickPrint={id => printReport(id)}
                onClickPayment={(id, billId, open, client, balance) => setDataDialog({ id: 0, billId, open, client, balance })}
                onClickViewReceipts={(open, id, nombre) => setDialogReceipt({ open, id, reference: id, cliente: nombre })}
                onClickCancel={(id, nombre) => showBillDisabledDialog(id, nombre)}
                onClickEnable={enableBill}
                onRefresh={() => refGrid.current.instance.refresh()}
                visibleView={true}
                visiblePrint={true}
                visiblePayment={true}
                visibleViewReceipts={true}
                visibleCancel={true}
                visibleEnable={true}
                visibleRefresh={true} {...props}
                >
                <CustomButton
                    type="default"
                    text={`Nueva factura`}
                    icon='plus'
                    onClick={() => showDialog(0)}
                />               
            </BlockHeaderState>
            <Popup
                width={'90vw'}
                height={'80vh'}
                title={`Recibos de la factura ${dialogReceipt.id}`}
                onHiding={e => setDialogReceipt({ open: false, id: 0 })}
                visible={dialogReceipt.open}
                className="bg-fbfbfb"
            >
                <ReceiptByBillId id={dialogReceipt.id} reference={dialogReceipt.reference} onSave={onSave} cliente={dialogReceipt.cliente} />
            </Popup>
            <Nuevo onSave={onSave} { ...props } />
            <NuevoReceipt.default data={dataDialog} close={close} onSave={() => 0} />
            <BillDisabled onSave={onSave} />
            <DataGrid id="gridContainer"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri: uri.bills, remoteOperations: true, extraParameter })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                //rowAlternationEnabled={true}
                hoverStateEnabled={true}
                onContextMenuPreparing={addMenuItems}
                onRowPrepared={onRowPrepared}
                onToolbarPreparing={onToolbarPreparing}
                onCellPrepared={onCellPrepared}
                onRowDblClick={onRowDblClick}
                onRowClick={onRowClick}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}     
            >
              
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                
                <Export enabled={false} fileName={title} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Column dataField="id" caption="Id" width={70} cellRender={dataFormatId} />
                <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={100} />
                <Column dataField="nombre" caption="Cliente" visible={clientId == 0} minWidth={150} allowHeaderFiltering={false} />
                <Column dataField="paymentTypeId" width={100} caption="Tipo pago">
                    <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="total" width={100} cellRender={cellRender()} />
                <Column dataField="balance" width={100} caption="Deuda" cellRender={cellRender()} />
                <Column dataField="active" width={90} caption="Activa" visible={false} />
                <Column dataField="payoff" width={100} caption='Pagada' dataType="boolean" cellRender={cellAsPayoff} />
                <Column dataField="asignedTo" caption='Vendedor' width={100} />
                <Column dataField="deliveryDate" caption='Fecha Entregado' dataType='date' format={formatDate} visible={false} width={140} />
                <Column dataField="createBy" caption='Creado Por' width={100}  visible={false}/>
                <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} visible={false} width={140} />
                <Column dataField="modifiedBy" caption='Modificado ' width={100} visible={false} />
                <Column dataField="modifiedAt" caption='Creado el' dataType='date' format={formatDateTime} visible={false} width={140} />
                <Column dataField="ConvertBy" caption='Modificada por' visible={false} width={100} />
                <Column dataField="isDeliveriede" width={100} caption="Entregado" cellRender={cellRenderDelivery} />
                <Column type="buttons" width={90}>
                    <ButtonGrid name="print" icon="print" onClick={e => printReport(e.row.data.id)}/>
                    <ButtonGrid name="edit" icon="find" onClick={e => showDialog(e.row.data.id)}/>
                </Column>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    useIcons={true}
                ></Editing>
            </DataGrid>
        </div>
    );
}

export default Bills;
