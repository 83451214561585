import React, { useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { Item } from 'devextreme-react/form';
import { 
    Paging, 
    Pager, 
    FilterRow, 
    HeaderFilter,
    Column, 
    Editing,
    Popup,     
    Form, 
    RequiredRule,
    StringLengthRule,
    Lookup} from 'devextreme-react/data-grid';

import uri from '../../utils/uri';
import toCapital from '../../utils/common';
import { store } from '../../services/store';
import useAuthorization from '../../hooks/useAuthorization';
import { resources } from '../../data/app';
import { defaultLabels } from '../../data/defaultObjects';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from '../../components/shared/Title';
import { createStoreLocal } from '../../utils/proxy';

function ComisionFamilies(props) {

    const [ authorized ] = useAuthorization( resources.configurarComisiones );

    let refGrid = useRef()

    const onToolbarPreparing = (e) => {  
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Crear nuevo',
                icon:'plus',
                type:'default',
                stylingMode:"outlined",
                onClick: () =>  refGrid.current.instance.addRow()
            }
        });
    }  

    const caption = "Comisiones por categoría";
    
    return authorized(
        <div className="container small">
            <Title title={caption}/>
            <BlockHeader title={toCapital(caption)}/>          
            <DataGrid id="gridContainer"
                ref={refGrid}
                selection={{ mode: 'single' }}
                dataSource={store({uri : uri.comissions })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                onToolbarPreparing={onToolbarPreparing}
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Column dataField="familyId" caption={defaultLabels.family}>
                    <Lookup disabled={true} dataSource={createStoreLocal({name:'family'})} valueExpr="id" displayExpr="name" />
                </Column> 
                <Column dataField="comission" caption="Comisión"  width={100} allowHeaderFiltering={false} allowFiltering={false} />
                <Column dataField="d46a59" caption="De 46 a 59"  width={100} allowHeaderFiltering={false} allowFiltering={false} />
                <Column dataField="d60aMas" caption="De 60 a Más"  width={100} allowHeaderFiltering={false}  allowFiltering={false}/>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    useIcons={true}
                >
                    <Popup title={toCapital(caption)} showTitle={true} width={450} height={350}>
                        
                    </Popup>
                    <Form>
                        <Item  dataField="familyId" colSpan={2} >
                            <RequiredRule message="El campo es requerido"/>
                        </Item>
                        <Item  dataField="comission" colSpan={2} >
                            <RequiredRule message="El campo es requerido"/>
                        </Item>
                        <Item  dataField="d46a59" colSpan={2} >
                            <RequiredRule message="El campo es requerido"/>
                        </Item>
                        <Item  dataField="d60aMas" colSpan={2} >
                            <RequiredRule message="El campo es requerido"/>
                        </Item>
                       
                    </Form>
                </Editing>
            </DataGrid>
        </div>
    )

};

export default ComisionFamilies; //connect(mapStateToProps, mapDispatchToProps)(GridSprintStart);