import React, { useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { confirm } from 'devextreme/ui/dialog';
import { Button } from 'devextreme-react';
import { useEffect } from 'react';
import http from '../../utils/http';
import uri from '../../utils/uri';
import { billDisabledDialogAction } from '../../store/bill/billDisabledDialogReducer';
import notify from 'devextreme/ui/notify';

const BillDisabled = (props) => {

    const dispatch = useDispatch();

    const { billDisabledDialog: { open, id, name }} = useSelector(store => store);
    const [saving, setSaving] = useState(false);
    const [formData, setFormData] = useState({ id, name, disabledSubject: '', disassociate: true});

    
    const refForm = useRef(null);

    const deleteBill = () => {

        let result = refForm.current.instance.validate();

        if (result.isValid) {

            let resultConfirm = confirm("<i>Esta seguro de anular la factura " + id + "?</i>", "Confirmar");
            resultConfirm.then((dialogResult) => {
                if (dialogResult) {

                    setSaving(true);
                    http(`${uri.bills.base}/${id}/delete`).asPost(formData).then(resp => {

                        setSaving(false);
                        notify(`Factura anulada correctamente`);
                        closeDialog(true);

                    }).catch(err => {

                        setSaving(false);
                        notify(err, 'error', 5000);

                    });
                   
                }
            });
        }

    }

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();

        dispatch(billDisabledDialogAction({ id: 0, clientId: 0, open: false }));
        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    useEffect(() => {
        if (open) {           
            setFormData({ id, name, disabledSubject: '' })
        }
    }, [open])

    return (
        <Popup title={`Anular factura ${id}`} width={400} height={320} onHiding={onHiding} visible={open} showCloseButton={true}>
            <div className="popup-content">                
                <div className="popup-body">
                    <Form formData={formData} ref={refForm} labelLocation='top'>
                        <GroupItem colCount={2}>                         
                            <SimpleItem colSpan={2} dataField="name" editorType="dxTextBox" editorOptions={{ label: 'Cliente', readOnly: true }}>
                                <Label text="Cliente" />
                            </SimpleItem>
                            <SimpleItem colSpan={2} dataField="disabledSubject" editorType="dxTextArea">
                                <RequiredRule message="El motivo de anulación es requerido" />
                                <StringLengthRule max={250} message="Máximo de caracteres permitidos 250" />
                                <Label text="Motivo" />
                            </SimpleItem>
                            <SimpleItem colSpan={2} dataField="disassociate" editorType="dxCheckBox">
                                <Label text="Desvincular preventa" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
                <div className="popup-footer pt-10">
                    <Button text={saving ? 'Anulando...':'Anular factura'} type="danger" icon="close" onClick={deleteBill} disabled={saving}></Button>
                </div>
            </div>
        </Popup>
    );
}

export default BillDisabled;
