import { dialogDefault, DIALOG_PROVIDER } from "../consts";

const mydialog = {...dialogDefault}


export const providerDialogAction = ({ open = false, id = 0, providerId = 0  }) => ({
    type: DIALOG_PROVIDER,
    payload : { open, id, providerId  }
});

export default function providerDialogReducer(state = mydialog, { type, payload }) {

    const actions = {
        [DIALOG_PROVIDER] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}

