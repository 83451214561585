// react
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { addToolBarAction, removeToolBarAction } from '../../store/toolBar/toolBarOptsReducer';
import { Button } from 'devextreme-react/button';

function BlockHeaderStateCC({showFavorite = false, title, icon,onClickView,onClickPrint, onClickPayment, onClickViewReceipts,...rest }) {

    const { toolBarOpts, gridStateCC: { data,
        disabledView, 
        disabledPrint, 
        disabledPayment, 
        disabledViewReceipts, 
    }} = useSelector(store => store); 
     
    const dispatch = useDispatch();

    const path = rest.location?.pathname;

    const existFavorite = toolBarOpts.find(x => x.name === title);

    const onClick = () => {
        if(existFavorite) 
            dispatch(removeToolBarAction(title));
        else
            dispatch(addToolBarAction({ name: title, path}));         

    }

    const showFavorites = path && showFavorite

    const className = existFavorite ? 'd-hand dx-icon-favorites exist-favorite' : 'd-hand dx-icon-favorites';

    return (
        <div className="block-header">            
            <h3 className="block-header__title">
                {title} {icon && <span className={icon} style={{fontSize : 20}}></span>}
                {showFavorites && <span onClick={onClick} className={className} style={{fontSize : 20}}></span>}
            </h3>
            <div className="block-header__divider" />       
            <Button onClick={() => onClickView(data.id)} icon={'search'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledView}/>    
            <Button onClick={() => onClickPrint(data.id)} icon={'print'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledPrint}/>    
            <Button onClick={() => onClickPayment(0, data.id, true, data.name, data.balance)} icon={'money'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledPayment}/>    
            <Button onClick={() => onClickViewReceipts(true, data.id, data.name)} icon={'product'} stylingMode="contained" className='ml-10' type={'normal'}  disabled={disabledViewReceipts}/>
        </div>
    );
}

export default BlockHeaderStateCC;
