import { dialogDefault, DIALOG_CLIENT } from "../consts";

const mydialog = {...dialogDefault}


export const clientDialogAction = ({ open = false, id = 0, clientId = 0  }) => ({
    type: DIALOG_CLIENT,
    payload : { open, id, clientId  }
});

export default function clientDialogReducer(state = mydialog, { type, payload }) {

    const actions = {
        [DIALOG_CLIENT] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}

