import React from "react";
import { userService } from "../services/user.service";

const Authorized = canAction => component => {
    return canAction ? component : (
        <div className="container small text-center text-danger mt-25">
            <p>
                El usuario no tiene permisos para este recurso 
            </p>
        </div>
    )
}

/**
 * Retorna el un componente si el usuario no tiene permiso al recurso actual
 * @param {{resourceId : boolean, action : Number}} token -  token que se va guardar
 * @return {{canAction: false  Unauthorized}} token
 */
const useAuthorization = (..._resources) => {
    
    const user = userService.getUser();

    const result = _resources.map((x) =>
        user.resources.find((y) => y.resource === x) === undefined ? false : true
    );

    const authorized = Authorized(result[0])

    return [authorized, ...result, ];

}

export default useAuthorization;