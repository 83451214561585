import React, { useEffect, useState} from 'react';
import http from '../utils/http';

const useCardInfo = ({ url }) => {

    const [cardInfo, setCardInfo] = useState({ value:0, percent:0 });
    const [ loading, setLoading ] = useState(true);

    const callApi = async () => {

        setLoading(true);

        const data = await http(url).asGet();       
       
        setCardInfo(data);

        setLoading(false);

    }

    useEffect(() => {

        callApi();

    }, []);

    return {
        loading,
        cardInfo
    }
}

export default useCardInfo;
