import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { LoadPanel } from 'devextreme-react/load-panel';
import { createStoreLocal } from '../../utils/proxy';
import { editorOptionsSelect } from '../../data/app';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import {  DataGrid, NumberBox, ScrollView } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid ,Summary,TotalItem} from 'devextreme-react/data-grid';
import ProductDDBComponent from '../../components/dropdown/ProductDDBComponent';
import uri from '../../utils/uri';
import { cellRender, customizeTextAsPercent, formatId, formatToMoney, onCellPrepared } from '../../utils/common';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import {  movementDefault } from '../../data/defaultObjects';
import { ncDialogAction } from '../../store/notacredit/ncDialogReducer';
import { confirm } from 'devextreme/ui/dialog';


const Nuevo = props => {

    const { ncDialog: { open, id }, user } = useSelector(store => store);

    const [ bill, setBill ] = useState({ id: null });
    const [ billDetallis, setBillDetails ] = useState([]);
    const [ movement, setMovement ] = useState({ ...movementDefault });
    const [ saving, setSaving ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ searching, setSearching ] = useState(false);
    const [ details, setDetails ] = useState([]);

    let refForm = useRef();
    let refBillForm = useRef();
    let refGrid = useRef();
    let billRefId = useRef();

    useEffect(() => {

        if (id > 0) {
            setLoading(true);
            http(uri.movements.getById(id)).asGet().then(resp => {

                http(`${uri.bills.base}/get/${resp.billId}/info`).asGet().then(data => {

                    setBill(data);
                    setBillDetails(data.details);

                    const { movementDetails, ...rest } = resp;                   

                    setMovement({ ...movementDefault, ...rest });
                    setDetails([...movementDetails]);
                    setLoading(false);
                });

            });

        } else {
            setLoading(true);
            setMovement({ ...movementDefault, areaId: user.areaId });
            setDetails([]);
            setLoading(false);

        }

    }, [open]);

    const dispatch = useDispatch();
    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                stylingMode:"outlined",
                text: searching ? 'Cargando...' : 'Agregar producto',
                icon:'add',
                disabled: searching || isDiscount,
                type:'default',
                onClick: () =>  {

                    let result = refForm.current.instance.validate();
                    let resultBill = refBillForm.current.instance.validate();

                    if (result.isValid && resultBill.isValid)                 
                        refGrid.current.instance.addRow();

                }
            }
        });
    }

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refBillForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();
        billRefId.current.instance.reset();
        

        dispatch(ncDialogAction({ open: false, id: 0 }));
        if (load) {
            let { onSave } = props;
            onSave();
        }

        setMovement({ ...movementDefault, areaId: user.areaId });
        setDetails([]);
        
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const guardarEntrada = (e, successCallback) => {

        let result = refForm.current.instance.validate();

        if (result.isValid) {

            let result = confirm("<i>Esta seguro de guardar la nota de crédito?</i>", "Confirmar cambios");

            result.then((dialogResult) => {
                if (dialogResult) {

                    setSaving(true);
                    
                    const newDetails = details.map(x => {
                        x.id = 0;
                        return x;
                    });

                    let data = { ...movement,billId: bill.id, clientId:bill.clientId, movementDetails: [...newDetails] };
        
                    http(uri.movements.insert).asPost(data).then(resp => {
                        setSaving(false);
        
                        if (successCallback) {
                            successCallback(data);
                        }
                        else {
                            if (isNew) {
                                notify('Nota de credito registrada correctamente');
                                closeDialog(true);
                            }
                            else {
                                notify('Nota de credito actualizada correctamente');
                            }
        
                        }
        
        
                    }).catch(err => {
                        setSaving(false);
                        notify(err, 'error', 5000);
                    });
                    
                }
            });           

        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {
        
        newData[prop] = value || 0;
        if (prop == 'productId' && value) {

            let info = billDetallis.find(x => x.id == value);           
            newData['cost'] = info.cost;
            newData['price'] = info.price;
            newData['quantity'] = info.stock;           
            newData['quantityBilled'] = info.stock;           
            newData['total'] = info.price * info.stock;
            newData['importe'] = 0;
            newData['totalFact'] = 0;
            newData['discountPercent'] = 0;
            newData['discount'] = 0;
            
        }

        if (prop == 'quantity' && (+value) >= 0) 
            newData['total'] = currentRowData['price'] * value;   

        if (prop == 'discountPercent' && (+value) >= 0) {
            const totalDiscount = (currentRowData['price'] * currentRowData['quantity']) * (value / 100);
            newData['discount'] = totalDiscount;
            newData['total'] = totalDiscount;
            newData['totalFact'] = (currentRowData['importe'] - totalDiscount);
            newData['newPriceAux'] = 0;

        }

        if (prop == 'newPriceAux' && (+value) >= 0) {

            const newDiscount = (((value * currentRowData['quantity']) / currentRowData['importe']) - 1) * 100 * -1;
            newData['discountPercent'] = parseFloat(parseFloat(newDiscount).toFixed(4));

            const totalDiscount = (currentRowData['price'] * currentRowData['quantity']) * (newDiscount / 100);
            newData['discount'] = totalDiscount;
            newData['total'] = totalDiscount;
            newData['totalFact'] = (currentRowData['importe'] - totalDiscount);

        }

    }

    const searchBill = (e) => { 

        var billId = billRefId.current.instance.option('value');

        if (billId == null || billId == 0) 
            return;    
                
        setSearching(true);
        http(`${uri.bills.base}/get/${billId}/info`).asGet().then(data => {
            setSearching(false);

            if (data == null) {
                notify('Factura no encontrada', 'error', 2000);
                return;
            }
            
            data.totalFormatted = formatToMoney(data.total);
            data.deudaFormatted = formatToMoney(data.balance);            

            setBill(data);
            setBillDetails(data.details);
            
        });           
    
    }

    const typeChanged = (e) => {
            
            if (e.value == 1) { // Devolución
                setDetails([]);
            }
            else {
                const _billDetallis = billDetallis.map(x => {

                    x.quantityBilled = x.stock;
                    x.quantity = x.stock;
                    x.productId = x.id
                    x.discountPercent = 0;
                    x.totalDiscount = 0;
                    x.importe = x.price * x.stock;
                    x.total = 0;
                    x.totalFact = x.price * x.stock;

                    return x;
                });

                setDetails([..._billDetallis]);
            }
    }

    const isNew = id == 0;

    const textSaving =  saving ? 'Guardando...' : 'Guardar Nota';

    const disabled = saving || !isNew;

    const searchButton = useMemo(
        () => ({
          icon: 'search',
          stylingMode: 'text',
          onClick: searchBill
        }),
        [],
      );

    const renderButtonSearch = () => {
        return (
            <NumberBox ref={billRefId}
              placeholder="0000"             
              defaultValue={bill.id}
              onValueChanged={searchBill}
            >
              <TextBoxButton
                name="password"
                location="after"
                options={searchButton}
              />
            </NumberBox>
        );
    }

    const isGoBack = movement.movementTypeId == 1;
    const isDiscount = movement.movementTypeId == 2;
 
    return (
        <div>           
            <Popup
                width={'90vw'}
                height={'98vh'}    
                title={isNew ? `Nueva Nota de Crédito` : `Nota de Crédito #${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                id='bill-panel'
            >           
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#bill-panel' }}
                    visible={loading}
                    showIndicator={loading}
                    shading={true}
                    showPane={true}
                />    
                <ScrollView showScrollbar='never'>
                    <Form formData={bill} ref={refBillForm}>
                        <GroupItem colCount={4} cssClass={'css-bill'}>      
                            <SimpleItem render={renderButtonSearch} >         
                                <Label text="Factura" />
                                <RequiredRule message="Ingrese la factura" />
                            </SimpleItem>    
                            <SimpleItem dataField="nombre" colSpan={2} editorOptions={{ readOnly: true, }} >
                                <Label text="Cliente" />
                                <RequiredRule message="Ingrese la factura" />
                            </SimpleItem>    
                            <SimpleItem dataField="asignedTo" colSpan={1} editorType="dxTextBox" editorOptions={{ readOnly: true, }}>
                                <Label text="Vendedor" />
                                <RequiredRule message="Ingrese la factura" />
                            </SimpleItem> 
                            <SimpleItem dataField="date" editorType="dxDateBox"
                                editorOptions={{
                                    displayFormat: 'dd/MM/yyyy',
                                    readOnly: true
                                }} >
                                <Label text="Fecha" />
                                <RequiredRule message="Ingrese la factura" />
                            </SimpleItem>                                                   
                            <SimpleItem dataField="totalFormatted" colSpan={1} editorType="dxTextBox" editorOptions={{ readOnly: true, }}>
                                <Label text="Total" />
                                <RequiredRule message="Ingrese la factura" />
                            </SimpleItem>
                            <SimpleItem dataField="deudaFormatted" colSpan={1} editorType="dxTextBox" editorOptions={{ readOnly: true, }}>
                                <Label text="Deuda" />
                            </SimpleItem>
                           
                        </GroupItem>
                    </Form>                
                    <Form formData={movement} ref={refForm}>
                        <GroupItem colCount={3} caption={'Datos de la Nota de Crédito'}>                            
                            <SimpleItem dataField="date" editorType="dxDateBox"  colSpan={1}
                                editorOptions={{
                                    displayFormat: 'dd/MM/yyyy',
                                    openOnFieldClick: true,
                                }} >
                                <Label text="Fecha" />
                                <RequiredRule message="Seleccione la fecha" />
                            </SimpleItem>
                            <SimpleItem dataField="movementTypeId" colSpan={1} editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: createStoreLocal({ name: 'movementType' }),
                                    ...editorOptionsSelect,
                                    disabled: bill.id == null,
                                    onValueChanged: typeChanged
                                }} >
                                <Label text="Tipo" />
                                <RequiredRule message="Seleccione el tipo de compra" />
                            </SimpleItem>                        
                            <SimpleItem dataField="movementGroupId" colSpan={1} editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: createStoreLocal({ name: 'movementGroup' }),
                                    ...editorOptionsSelect,
                                    disabled: bill.id == null
                                }} >
                                <Label text="Motivo" />
                                <RequiredRule message="Seleccione una justificación" />
                            </SimpleItem> 
                        </GroupItem>
                        <GroupItem>
                            <DataGrid id="gridDetails"
                                ref={refGrid}
                                selection={{ mode: 'single' }}
                                dataSource={details}
                                showBorders={true}
                                showRowLines={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                height={300}
                                onToolbarPreparing={onToolbarPreparing}
                                onCellPrepared={onCellPrepared}
                            >
                                <Column dataField="productId" caption="Producto"
                                    setCellValue={setCellValue.bind(null, "productId")} allowEditing={isGoBack}
                                    editCellComponent={ProductDDBComponent} minWidth={100}>
                                    <Lookup
                                        dataSource={billDetallis}
                                        valueExpr="id"
                                        displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}

                                    />
                                    <RuleRequired />
                                </Column> 
                                <Column dataField="quantity" caption="Cant" dataType="number" width={50} allowEditing={isGoBack} setCellValue={setCellValue.bind(null, "quantity")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="quantityBilled" caption="Cant Fact" dataType="number" width={80} allowEditing={false} visible={isGoBack}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="cost" caption="Costo" dataType="number" allowEditing={false} width={100} cellRender={cellRender(1)} visible={false}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="price" caption="Precio" dataType="number" width={100} cellRender={cellRender()} allowEditing={false} >
                                    <RuleRequired />
                                </Column>      
                                <Column dataField="newPriceAux" caption="Precio Aux" dataType="number" width={100} cellRender={cellRender()} visible={isDiscount} setCellValue={setCellValue.bind(null, "newPriceAux")}>
                                </Column>      
                                <Column dataField="importe" caption="Importe" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>                        
                                <Column dataField="discountPercent" caption="Desc %" dataType="number" width={80} cellRender={customizeTextAsPercent} allowEditing={isDiscount} visible={isDiscount} setCellValue={setCellValue.bind(null, "discountPercent")}>
                                    <RuleRequired />
                                </Column>                              
                                <Column dataField="discount" caption="Descuento" dataType="number" width={100} cellRender={cellRender()} allowEditing={false} visible={isDiscount} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="totalFact" caption="Total Fac" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} visible={isGoBack}>
                                    <RuleRequired />
                                </Column>                               

                                <Column type="buttons" width={50}>
                                    <ButtonGrid name="delete" />
                                </Column>
                                <Editing
                                    mode="cell"
                                    allowDeleting={true}
                                    allowUpdating={true}
                                    selectTextOnEditStart={true}
                                    useIcons={true}
                                ></Editing>
                                <Summary>
                                    <TotalItem
                                        column={isGoBack ? 'total':'discount'}
                                        summaryType="sum" 
                                        customizeText={data => `${formatToMoney(data.value)}`} />
                                </Summary>
                            </DataGrid>
                        </GroupItem>
                        <GroupItem colCount={3}>

                            <SimpleItem dataField="observation" colSpan={2} editorType="dxTextBox">
                                <Label text="Observación" />
                                <StringLengthRule max={150} message="Maximo 500 caracteres" />
                                <RuleRequired message={"Ingrese la observacion"} />
                            </SimpleItem> 
                            <SimpleItem itemType="button" buttonOptions={{
                                text: textSaving,
                                type: 'success',
                                disabled: disabled,
                                icon: 'save',
                                onClick: guardarEntrada
                            }} />
                        </GroupItem>
                    </Form>
                </ScrollView>

            </Popup>
        </div>
    );
}



export default Nuevo;