import React, { useEffect, useState, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { dialogPurchase } from '../../../store/inPutProductPurchase/purchaseDialogReducer';
import { createStore, createStoreLocal } from '../../../utils/proxy';
import { editorOptionsSelect } from '../../../data/app';
import { DataGrid } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, Summary, TotalItem, Pager } from 'devextreme-react/data-grid';
import ProductDDBComponent from '../../../components/dropdown/ProductDDBComponent';
import uri from '../../../utils/uri';
import { cellRender, cellRenderBold, formatId, formatToMoney, onCellPrepared } from '../../../utils/common';
import http from '../../../utils/http';
import useProducts from '../../../hooks/useProducts';
import gridsHelper from '../../../utils/gridsHelper';
import ButtonForm from '../../../components/buttons/ButtonForm';
import notify from 'devextreme/ui/notify';
import { defaultLabels, labels, purchaseDefault } from '../../../data/defaultObjects';
import Resumen from '../../../components/form/Resumen';

const NuevoPurchase = props => {

    const { typeId } = props;

    const active = true;

    const { purchaseDialog: { open, id }, user } = useSelector(store => store);

    const { products } = useProducts({ areaId: user.areaId, active, onlyProducts: true });
    const [inPutProduct, setInPutProduct] = useState({ ...purchaseDefault });
    const [saving, setSaving] = useState(false);
    const [details, setDetails] = useState([]);
    const [resumenData, setResumenData] = useState([]);

    let refForm = useRef();
    let refGrid = useRef();

    useEffect(() => {

        if (id > 0) {

            http(uri.inPutProducts.getById(id)).asGet().then(resp => {

                const { inPutProductDetails, ...rest } = resp;

                inPutProductDetails.map(detail => {

                    let info = products.find(x => x.id == detail.productId);

                    detail['presentation'] = info.presentation;
                    detail['um'] = info.um;
                    detail['family'] = info.family;

                    return detail;
                })

                setInPutProduct({ ...purchaseDefault, ...rest });
                setDetails([...inPutProductDetails]);

                setResumenData([...inPutProductDetails]);

            });

        } else {

            setInPutProduct({ ...purchaseDefault, areaId: user.areaId, typeId: typeId });
            setDetails([]);
            setResumenData([]);

        }

    }, [open]);

    const dispatch = useDispatch();
    const onToolbarPreparing = gridsHelper(refGrid, { text: 'Agregar producto', icon: 'plus' });

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();

        dispatch(dialogPurchase({ open: false, id: 0 }));
        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const guardarEntrada = (e) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            result = refForm.current.instance.validate();

            if (result.isValid) {

                setSaving(true);
                let data = { ...inPutProduct, inPutProductDetails: [...details] };

                http(uri.inPutProducts.insert).asPost(data).then(resp => {

                    setSaving(false);
                    notify('Compra registrada correctamente');
                    closeDialog(true);

                }).catch(err => {

                    setSaving(false);
                    notify(err, 'error', 5000);

                });

            }
        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        newData[prop] = value || 0;
        if (prop == 'productId' && value) {

            let info = products.find(x => x.id == value);
            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['cost'] = info.cost;
            newData['price'] = 0;
            newData['quantity'] = 1;
            newData['total'] = info.cost;
        }

        if (prop == 'quantity' && (+value) >= 0) {
            newData['total'] = currentRowData['cost'] * value;
        }

        if (prop == 'cost' && (+value) >= 0) {
            newData['total'] = currentRowData['quantity'] * value;
        }

    }

    const onRowInserted = e => {
        setResumenData([...details])
    }

    const isNew = id == 0;

    const textSaving = 'Guardar Entrada';

    return (
        <div>
            <Popup
                width={1150}
                height={580}
                title={isNew ? `Nueva compra de inventario` : `Compra #${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
            >
                <Form formData={inPutProduct} ref={refForm}>
                    <GroupItem colCount={3}>
                        <GroupItem colSpan={2}>
                            <GroupItem colCount={3}>
                                <SimpleItem dataField="areaId" editorType="dxSelectBox"
                                    editorOptions={{
                                        disabled: true,
                                        dataSource: createStoreLocal({ name: 'area' }),
                                        ...editorOptionsSelect
                                    }} >
                                    <Label text="Area" />
                                    <RequiredRule message="Seleccione el area" />
                                </SimpleItem>
                                <SimpleItem dataField="date" editorType="dxDateBox"
                                    editorOptions={{
                                        displayFormat: 'dd/MM/yyyy',
                                        openOnFieldClick: true,
                                    }} >
                                    <Label text="Fecha" />
                                    <RequiredRule message="Seleccione la fecha" />
                                </SimpleItem>
                                <SimpleItem dataField="typeId" editorType="dxSelectBox"
                                    editorOptions={{
                                        disabled: true,
                                        dataSource: createStoreLocal({ name: 'inPutProductType' }),
                                        ...editorOptionsSelect
                                    }} >
                                    <Label text="Tipo" />
                                    <RequiredRule message="Seleccione el tipo" />
                                </SimpleItem>
                                <SimpleItem dataField="providerId" colSpan={2} editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: createStore({ name: 'Provider', active: isNew }),
                                        ...editorOptionsSelect
                                    }} >
                                    <Label text="Proveedor" />
                                    <RequiredRule message="Seleccione el proveedor" />
                                </SimpleItem>
                                <SimpleItem dataField="reference">
                                    <Label text="Referencia" />
                                    <RequiredRule message="Ingrese la referencia de compra" />
                                </SimpleItem>
                                <SimpleItem dataField="observation" colSpan={3} editorType="dxTextArea">
                                    <Label text="Observacion" />
                                    <StringLengthRule max={500} message="Maximo 500 caracteres" />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem colSpan={1}>
                            <Resumen data={resumenData} />          
                        </GroupItem>
                    </GroupItem>
                    <GroupItem>
                        <DataGrid id="gridDetails"
                            ref={refGrid}
                            selection={{ mode: 'single' }}
                            dataSource={details}
                            showBorders={true}
                            showRowLines={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            height={290}
                            onToolbarPreparing={onToolbarPreparing}
                            onCellPrepared={onCellPrepared}
                            onRowInserted={onRowInserted}
                            onRowRemoved={onRowInserted}
                            onRowUpdated={onRowInserted}
                        >
                            <Column dataField="productId" caption="Producto"
                                setCellValue={setCellValue.bind(null, "productId")}
                                editCellComponent={props => <ProductDDBComponent showPrice={false} {...props} />}>
                                <Lookup
                                    dataSource={products}
                                    valueExpr="id"
                                    displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}

                                />
                                <RuleRequired />
                            </Column>
                            <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                                <RuleRequired />
                            </Column>
                            <Column dataField="quantity" caption="Cantidad" dataType="number" width={80} setCellValue={setCellValue.bind(null, "quantity")}>
                                <RuleRequired />
                            </Column>
                            <Column dataField="cost" caption={labels.costo} dataType="number" width={100} cellRender={cellRender()} setCellValue={setCellValue.bind(null, "cost")}>
                                <RuleRequired />
                            </Column>
                            <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRenderBold()} >
                                <RuleRequired />
                            </Column>
                            <Column dataField="price" caption={labels.precio} dataType="number" width={100} cellRender={cellRender()} >
                                <RuleRequired />
                            </Column>

                            <Column dataField="lote" caption="# Lote" width={80}></Column>                
                            <Column dataField="expireDate" caption="Expiración" dataType='date' format='MM/yyyy' width={80}></Column>     

                            <Column type="buttons" width={50}>
                                <ButtonGrid name="delete" />
                            </Column>
                            <Pager
                                showInfo={true}
                                showPageSizeSelector={true}
                                allowedPageSizes={[10, 20, 50]}
                            />
                            <Summary>
                                <TotalItem
                                    column="total"
                                    summaryType="sum"
                                    customizeText={data => formatToMoney(data.value)} />
                            </Summary>
                            <Editing
                                mode="cell"
                                allowDeleting={true}
                                allowUpdating={true}
                                selectTextOnEditStart={true}
                                useIcons={true}
                            ></Editing>
                        </DataGrid>
                    </GroupItem>
                </Form>

                <ButtonForm saving={saving} visible={isNew} textSaving={textSaving} onClick={guardarEntrada} />

            </Popup>
        </div>
    );
}

export default NuevoPurchase;
