// react
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToolBarAction, removeToolBarAction } from '../../store/toolBar/toolBarOptsReducer';
function BlockHeader({showFavorite = false, title, icon, children,...rest}) {

    const { toolBarOpts } = useSelector(store => store);
    const dispatch = useDispatch();

    const path = rest.location?.pathname;

    const existFavorite = toolBarOpts.find(x => x.name === title);

    const onClick = () => {
        if(existFavorite) 
            dispatch(removeToolBarAction(title));
        else
            dispatch(addToolBarAction({ name: title, path}));         

    }

    const showFavorites = path && showFavorite

    const className = existFavorite ? 'd-hand dx-icon-favorites exist-favorite' : 'd-hand dx-icon-favorites';

    return (
        <div className="block-header">
            <h3 className="block-header__title">
                {title} {icon && <span className={icon} style={{fontSize : 20}}></span>}
                {showFavorites && <span onClick={onClick} className={className} style={{fontSize : 20}}></span>}
            </h3>
            <div className="block-header__divider" />       
            {children}
        </div>
    );
}

export default BlockHeader;
