import React, { useEffect, useRef, useState } from "react";
import {
    Paging,
    Pager,
    FilterRow,
    ColumnChooser,
    Column,
    Lookup,
    Export,
    Summary, GroupItem as GroupItemGrid
}
    from 'devextreme-react/data-grid';

import { DataGrid } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import Form, { SimpleItem, GroupItem, Label, EmptyItem, StringLengthRule, RequiredRule, EmailRule, AsyncRule } from 'devextreme-react/form';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from "../../components/shared/Title";
import { store } from "../../services/store";
import { dataAccess, editorOptionsNumberBox, editorOptionsSwitch, formatDate, resources, editorOptsTextBoxPhone, formatDateTime } from '../../data/app';
import { createStore, createStoreLocal } from "../../utils/proxy";
import uri from "../../utils/uri";
import useAuthorization from "../../hooks/useAuthorization";
import { cellAsPayoff, cellRender, cellRenderBold, dataFormatId } from "../../utils/common";
import http from "../../utils/http";
import notify from "devextreme/ui/notify";

const Ejecute = props => {

    const [state, setState] = useState({});
    const [saving, setSaving] = useState(false);
    const [bills, setBills] = useState([]);
    const [loading, setLoading] = useState(true);

    const { id } = props.location.state;
    let refCut = useRef();

    const getInfoCut = async () => {

        const data = await http(`cuts/pre-ejecute/${id}`).asGet();
        const sales = await http(`bills/bills-by-cut/${id}`).asGet();

        data.endValue = sales.reduce((previousValue, currentValue) => previousValue + currentValue.totalDetail, 0);
        data.calculateValue = data.initValue + data.endValue;
        setState(data);
        setBills(sales);

        setLoading(false);

    }

    const guardarCut = () => {
        
        setSaving(true);
        http(`cuts/close`).asPost(state).then(resp => {

            setSaving(false);
            notify(`Cierre de caja aplicado correctamente`);
            setState({ ...state, status: true });

        }).catch(err => {
            setSaving(false);
            notify(err, 'error', 5000);
        });

    }

    useEffect(() => {
        getInfoCut();
    }, [0]);

    const onCellPrepared = e => {

        const cellsQuantity = ['payoff']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                if (e.row.data.payoff)
                    e.cellElement.classList.add('pagada');
                else
                    e.cellElement.classList.add('pendiente');

            if (e.column.dataField == 'id')
                e.cellElement.classList.add('col-id-factura');
            if (e.column.dataField == 'reference')
                e.cellElement.classList.add('col-reference-factura');
        }

    }

    const backPage = () => {
        props.history.push('/app/cierres-caja');
    }

    const title = "Cierre de caja";

    const isClosed = state.status;

    return (
        <div className="container">
            {loading ? <div style={{ display: 'flex', justifyContent: 'center'}}>Cargando...</div> :
                <div>
                    <Title title={title} />
                    <BlockHeader title={title} />
                    <DataGrid
                        dataSource={bills}
                        selection={{ mode: 'single' }}
                        showBorders={true}
                        showRowLines={true}

                        allowColumnResizing={true}
                        columnHidingEnabled={true}
                        allowColumnReordering={true}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        onCellPrepared={onCellPrepared}
                    >
                        <Pager allowedPageSizes={[10]} showPageSizeSelector={true} showInfo={true} />
                        <Paging defaultPageSize={10} />
                        <FilterRow visible={true} />
                        <ColumnChooser enabled={true} />
                        <Export enabled={true} fileName={title} allowExportSelectedData={true} />
                        <Column dataField="id" caption="Factura #" width={90} groupIndex={0} cellRender={dataFormatId} alignment="rigth" />
                        <Column dataField="date" caption="Fecha" dataType='date' format={formatDate} width={120} />
                        <Column dataField="product" caption="Item" allowHeaderFiltering={false} />
                        <Column dataField="paymentTypeId" width={100} caption="Tipo pago">
                            <Lookup disabled={true} dataSource={createStoreLocal({ name: 'billPaymentType' })} valueExpr="id" displayExpr="name" />
                        </Column>
                        <Column dataField="totalDetail" width={110} cellRender={cellRender()} />
                        <Column dataField="createBy" caption='Creado por' width={100} />
                        <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} width={180} />
                        <Summary>
                            <GroupItemGrid
                                column="totalDetail"
                                summaryType="sum" displayFormat="Total: {0}" alignByColumn={true} />
                        </Summary>
                    </DataGrid>
                    <br />
                    <Form formData={state} ref={refCut}>
                        <GroupItem colCount={6}>
                            <SimpleItem dataField="initValue" colSpan={2} editorOptions={{ readOnly: true }}>
                                <Label text="Saldo Inicial" />
                            </SimpleItem>
                            <SimpleItem dataField="endValue" colSpan={2} editorOptions={{ readOnly: true }}>
                                <Label text="Ingresos" />
                            </SimpleItem>
                            <SimpleItem dataField="calculateValue" colSpan={2} editorOptions={{ readOnly: true }}>
                                <Label text="Total Caja" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                    <br />
                    <Button
                        text={`Regresar`}
                        type="normal"
                        icon="chevronleft"
                        stylingMode="contained"
                        className="m-1"
                        disabled={saving}
                        onClick={backPage}
                    />
                    <Button
                        text={`${saving ? 'Guardando...' : 'Cerrar caja'}`}
                        type="default"
                        icon="save"
                        stylingMode="contained"
                        className="m-1"
                        disabled={saving || isClosed}
                        onClick={guardarCut}
                    />
                </div>
            }

        </div>
    )


}


export default Ejecute;
