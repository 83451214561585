import { _path } from "./headerNavigation";

export default [{
    layout: 'conta',
    type: 'link',
    label: 'Inicio',
    url: '/',
},
{
    layout: 'app',
    type: 'link',
    label: 'Cotizaciones',
    url: `${_path.ROOTMAIN}/mobile/cotizaciones`,
    children: [
        { type: 'link', label: 'Cotizaciones', url: `${_path.ROOTMAIN}/mobile/cotizaciones` },
        { type: 'link', label: 'Preventas', url: `${_path.ROOTMAIN}/mobile/pre-ventas` },
    ],
},
{
    layout: 'app',
    type: 'link',
    label: 'Facturacion',
    url: `${_path.ROOTMAIN}/facturas`,
    children: [
        { type: 'link', label: 'Facturas', url: `${_path.ROOTMAIN}/facturas` },
        { type: 'link', label: 'Facturas rápida', url: `${_path.ROOTMAIN}/facturas-quickly` },
        { type: 'link', label: 'Gestión de caja', url: `${_path.ROOTMAIN}/cierres-caja` },  
        { type: 'link', label: 'Recibos', url: `${_path.ROOTMAIN}/recibos` },  
        { type: 'link', label: 'Nota de Crédito', url: `${_path.ROOTMAIN}/notascreditos` },  
    {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
                { type: 'link', label: 'Ventas', url: `${_path.ROOTMAIN}/facturas/reportes/ventas` },
            ],
        },
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Compras',
    url: `${_path.ROOTMAIN}/compras`,
    children: [
        { type: 'link', label: 'Lista de compras', url: `${_path.ROOTMAIN}/movimientos/compras` },
        { type: 'link', label: 'Proveedores', url: `${_path.ROOTMAIN}/proveedores` },
        {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
                { type: 'link', label: 'Compras de productos', url: `${_path.ROOTMAIN}/compras/reportes` },
            ],
        },
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Inventario',
    url: `${_path.ROOTMAIN}/productos`,
    children: [
        { type: 'link', label: 'Productos', url: `${_path.ROOTMAIN}/productos` },
        { type: 'link', label: 'Grupos', url: `${_path.ROOTMAIN}/familias` },     
        { type: 'link', label: 'Unidad Medida', url: `${_path.ROOTMAIN}/unidadmedida` },
        { type: 'link', label: 'Tasa de cambio', url: `${_path.ROOTMAIN}/tasa-de-cambio` },
        {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
                { type: 'link', label: 'Kardex', url: `${_path.ROOTMAIN}/inventario/reportes/kardex` },
                { type: 'link', label: 'Existencias', url: `${_path.ROOTMAIN}/inventario/reportes/existencias` },
                { type: 'link', label: 'Stock', url: `${_path.ROOTMAIN}/inventario/reportes/stock` },
                { type: 'link', label: 'Ult ingresos', url: `${_path.ROOTMAIN}/inventario/reportes/ingresos` },
            ],
        },
    ],
},
{
    layout: 'app',
    type: 'link',
    label: 'Clientes',
    url: `${_path.ROOTMAIN}/clientes`,
    children: [
        { type: 'link', label: 'Gestión de clientes', url: `${_path.ROOTMAIN}/clientes` },
        { type: 'link', label: 'Cuentas por cobrar', url: `${_path.ROOTMAIN}/clientes/pending` },
        {
            type: 'link',
            label: 'Reportes',
            url: '',
            children: [
            ],
        },
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Entradas',
    url: `${_path.ROOTMAIN}/movimientos/entradas`,
    children: [
        { type: 'link', label: 'Ajustes', url: `${_path.ROOTMAIN}/movimientos/entradas` },
        { type: 'link', label: 'Inventario Inicial', url: `${_path.ROOTMAIN}/movimientos/inv-inicial` },
        { type: 'link', label: 'Solicitud de traslado', url: `${_path.ROOTMAIN}/movimientos/traslados` },
       
    ],
},
{
    layout: 'app',
    type: 'link',
    label: 'Salidas',
    url: `${_path.ROOTMAIN}/movimientos/salidas`,
    children: [
        { type: 'link', label: 'Ajustes de salida', url: `${_path.ROOTMAIN}/movimientos/salidas` },
        { type: 'link', label: 'Despachos', url: `${_path.ROOTMAIN}/movimientos/despacho` },
       
    ],
},
{
    layout: 'app',
    type: 'link',
    label: 'Configuración',
    url: `${_path.ROOTMAIN}/usuarios`,
    children: [
        { type: 'link', label: 'Usuarios', url: `${_path.ROOTMAIN}/usuarios` },
        { type: 'link', label: 'Roles', url: `${_path.ROOTMAIN}/roles` },
        { type: 'link', label: 'Recursos', url: `${_path.ROOTMAIN}/permisos` },
        { type: 'link', label: 'Areas', url: `${_path.ROOTMAIN}/areas` },       
    ],
},

{
    layout: 'app',
    type: 'link',
    label: 'Cuenta',
    url: `${_path.ROOTMAIN}/usuarios`,
    children: [
        { type: 'link', label: 'Password', url: `/account/password` },
        { type: 'link', label: 'Salir', url: `/account/logout` },
    ],
},

];