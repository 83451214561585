import React, { useEffect,  useState, useRef } from 'react';
import { DataGrid, Box,  DateBox, SelectBox, Button, Popup } from 'devextreme-react';
import { Item } from "devextreme-react/box";
import {
    Paging,
    Pager,   
    Column, 
    FilterRow,
    Summary,
    TotalItem,
    GroupItem,  
    Button as ButtonGrid,
} from 'devextreme-react/data-grid';
import Title from '../../../components/shared/Title';
import BlockHeader from '../../../components/shared/BlockHeader';

import { _path } from "../../../data/headerNavigation";               
import { formatDate } from '../../../data/app';
import { cellRender, cellRenderBold, dataFormatId, formatId, toBoldMoney, toMoney } from '../../../utils/common';

import uri from '../../../utils/uri';
import http from '../../../utils/http';
import { dateByReport } from '../../../data/defaultObjects';
import { userService } from '../../../services/user.service';
import moment from 'moment';
import ReceiptByBillId from '../../receipts/ReceiptByBillId';
import Nuevo from '../../bills/Nuevo';
import { billDialogAction } from '../../../store/bill/billDialogReducer';
import { useDispatch } from 'react-redux';

const Comision = (props) => {

    const user = userService.getUser();
    const dispatch = useDispatch();
    const refGrid = useRef();

    const [ users, setUsers ] = useState([]);
    const [ username, setUsername ] = useState(user.username);
    const [ dateFrom, setDateFrom ] = useState(dateByReport.from);
    const [ dateTo, setDateTo ] = useState(dateByReport.to);
    const [ data, setData ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);    
    const [dialogReceipt, setDialogReceipt] = useState({ open: false, id: 0, reference: 0 });

    const changeUser = e => setUsername(e.value);    

    const changeDateFrom = e => setDateFrom(e.value);

    const changeDateTo = e => setDateTo(e.value);    

    useEffect(() => {
        http(uri.users.get).asGet().then(resp => setUsers(resp));
    }, [0]);

    const loadReport = () => {
        if(dateFrom && dateTo){
            setLoading(true);
            http('reports/comissions').asPost({username, from : dateFrom, to: dateTo}).then(resp => {
                setData(resp);
                setLoading(false);
            });      
        }
    };

    const onCellPrepared = e => {
        
        if (e.rowType == 'data') {            

            if (e.column.dataField == 'billId')
                e.cellElement.classList.add('col-id-factura');
        }       

    }    

    const DateCell = (cellData) => {

        const { billDate, payOfDate, diasTranscurridos, daysMora } = cellData.data;
        let datePaid = 'datePaid';
        if(daysMora > 0)
            datePaid = 'dateMora';

        
        return (
            <div>
                <div className="current-value">
                    <span className='datebill'>{moment(billDate).format('DD/MM/YYYY')}</span>-<span className={datePaid}>{moment(payOfDate).format('DD/MM/YYYY')}</span> <b>{diasTranscurridos}</b>
                </div>                
            </div>
        );
    };

    const showDialog = id => dispatch(billDialogAction({ open: true, id }));  

    const showReceipt = ( id, nombre ) => setDialogReceipt({ open: true, id, reference:id, cliente:nombre })

    const title = 'Comisiones';
    
    return (
        <div className=''>
            <Title title={title} />
            <BlockHeader title={title} showFavorite={true} {...props} />
            <Popup
                width={800}
                height={400}
                title={`Recibos`}
                onHiding={e => setDialogReceipt({ open: false, id: 0 })}
                visible={dialogReceipt.open}
                className="bg-fbfbfb"
            >
                <ReceiptByBillId id={dialogReceipt.id} reference={dialogReceipt.reference} onSave={_ => _} cliente={dialogReceipt.cliente} />
            </Popup>
            <Nuevo onSave={_ => _} { ...props } />
            <Box direction="row" width="100%" height={75}>                    
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Desde:</label>
                        <DateBox type="date" 
                            value={dateFrom}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateFrom}/>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Hasta:</label>
                        <DateBox type="date" 
                            value={dateTo}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateTo}/>
                    </div>
                </Item> 
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Usuario:</label>
                        <SelectBox items={users}
                            placeholder="Selecciona"
                            showClearButton={true} valueExpr="username" displayExpr="username" 
                            value={username}
                            onValueChanged={changeUser}                         
                        />
                    </div>
                </Item>        
                     
                <Item ratio={1}>
                    <div style={{ paddingTop : '19px' }}>
                        <Button
                            width={120}
                            text={`${loading ? 'Buscando...' : 'Buscar'}`}
                            type="default"
                            icon="search"
                            stylingMode="contained"
                            className="m-1"
                            disabled={loading}
                            onClick={loadReport}
                            />
                        </div>
                </Item>
                <Item ratio={1}>
                    <div style={{ paddingTop : '19px' }}>
                        <Button
                            width={180}
                            text={`Exportar a Excel`}
                            type="success"
                            icon="file"
                            stylingMode="contained"
                            className="m-1"
                            disabled={loading}
                            onClick={() => {    
                                refGrid.current.instance.exportToExcel();
                            }}
                            />
                        </div>
                </Item>
            </Box>
            <Box direction="row" width="100%" height={75}>

                <Item ratio={1}>                    
                <DataGrid  
                    ref={refGrid}              
                    dataSource={data}
                    selection={{ mode: 'single' }}
                    showBorders={true}
                    showRowLines={true}
                    allowColumnResizing={true}               
                    allowColumnReordering={true}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    onCellPrepared={onCellPrepared}
                    >
                    <Pager allowedPageSizes={[10, 15, 30, 50]} showPageSizeSelector={true} showInfo={true} />
                    <Paging defaultPageSize={15} />
                    <FilterRow visible={false} />
                    <Column type="buttons" width={60}>
                        <ButtonGrid name="showBill" icon="find" onClick={e => showDialog(e.row.data.billId)}/>
                        <ButtonGrid name="receive" icon="product" onClick={e => showReceipt(e.row.data.billId, e.row.data.fullName)}/>
                    </Column>
                    <Column dataField="asignedTo" caption="Vendedor" width={90} groupIndex={0} />
                    <Column caption="DETALLE DE FACTURA" alignment={'center'}>
                        <Column dataField="billId" caption="Factura #" width={70} cellRender={dataFormatId}/>
                        <Column dataField="fullName" caption="Cliente" minWidth={50} />
                        <Column dataField="diasTranscurridos" caption="Fecha"  width={190} cellRender={DateCell}/>
                        <Column dataField="daysMora" caption="Mora"  width={50}/>
                        <Column dataField="total" caption="Total"  width={100} cellRender={toBoldMoney}/>
                    </Column>
                    <Column caption="Categorias" alignment={'center'}>
                        <Column dataField="c0_5" caption="0.5%" width={80} alignment={'right'} cellRender={toMoney} />                  
                        <Column dataField="c1" caption="1%" width={80}  alignment={'right'} cellRender={toMoney}/>                  
                    
                        <Column dataField="c1_5" caption="1.5%" width={80}  alignment={'right'} cellRender={toMoney}/>                  
                        <Column dataField="c2" caption="2%" width={80}  alignment={'right'} cellRender={toMoney}/>                  
                
                        <Column dataField="c2_5" caption="2.5%" width={80}  alignment={'right'} cellRender={toMoney}/>                  
                        <Column dataField="c3" caption="3%" width={80}  alignment={'right'} cellRender={toMoney}/>                  
                    </Column>
                    <Column caption="Comisión" alignment={'center'}>
                        <Column dataField="totalComi" caption="Total" width={90}  alignment={'right'} cellRender={toBoldMoney}/>            
                    </Column>  
                 
                    <Summary>
                        <TotalItem column="billId" summaryType="count" />
                        <TotalItem column="total" summaryType="sum" customizeText={toMoney} />
                        <TotalItem column="totalComi" summaryType="sum" customizeText={toMoney} />
                        <GroupItem
                            column="billId"
                            summaryType="count"
                            displayFormat="{0} items"
                        />
                        <GroupItem column="totalComi" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                        <GroupItem column="total" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                        <GroupItem column="c0_5" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                        <GroupItem column="c1" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                        <GroupItem column="c1_5" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                        <GroupItem column="c2" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                        <GroupItem column="c2_5" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                        <GroupItem column="c3" summaryType="sum" showInGroupFooter={true} alignByColumn={true} customizeText={toMoney} />
                    </Summary>     
                
                </DataGrid>
            </Item>
        </Box>
        </div>
    );
}

export default Comision;
