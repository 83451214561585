import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, Box, DropDownBox, DateBox, SelectBox, Button } from 'devextreme-react';
import { Item } from "devextreme-react/box";
import {
    Paging,
    Pager,
    FilterRow,
    Column,
    Export,
    Scrolling,
    Selection,
    GroupPanel,
    SearchPanel,
    Grouping,
    Summary,
    TotalItem,
    GroupItem
} from 'devextreme-react/data-grid';
import Title from '../../../components/shared/Title';
import BlockHeader from '../../../components/shared/BlockHeader';

import { _path } from "../../../data/headerNavigation";
import { formatDate } from '../../../data/app';

import http from '../../../utils/http';

import { dateByReport, defaultLabels } from '../../../data/defaultObjects';
import CharByType from './DashboardComponent/CharByType';

const DashboardSales = (props) => {
  
    const [ dateFrom, setDateFrom ] = useState(dateByReport.from);
    const [ dateTo, setDateTo ] = useState(dateByReport.to);
    const [ data, setData ] = useState([]);
    const [ dashboardType, setDashboardType ] = useState('family');
    const [ loading, setLoading ] = useState(false);

    const changeDateFrom = e => setDateFrom(e.value);

    const changeDateTo = e => setDateTo(e.value);  

    const changeDashboard = e => setDashboardType(e.value);   

    const loadReport = () => {
        if(dateFrom && dateTo){

            setLoading(true);            

            http('reports/dashboard').asPost({areaId:0, from : dateFrom, to: dateTo}).then(resp => {
                setData(resp);
                setLoading(false);
            });      

        }
    };    

    const currentTypeLabel = dashboardType === 'family' ? defaultLabels.family : 'Vendedor';

    const reverseType = dashboardType === 'family' ? 'username' : 'family';
    const reverseTypeLabel = dashboardType === 'family' ? 'Vendedor' : defaultLabels.family;
    
    const title = 'Últimos Ingresos';

    return (
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} showFavorite={true} {...props}></BlockHeader>
            <Box direction="row" width="100%" height={75}>                   
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Desde:</label>
                        <DateBox type="date"                                            
                            value={dateFrom}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateFrom}/>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Hasta:</label>
                        <DateBox type="date" 
                            value={dateTo}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateTo}/>
                    </div>
                </Item>               
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Tipo Dashboard:</label>
                        <SelectBox items={[
                                { id: 'family', name: defaultLabels.family },
                                { id: 'username', name: 'Vendedor' }
                            ]} 
                            valueExpr={'id'}
                            displayExpr={'name'}
                            defaultValue={'Categoria'}
                            showClearButton={true}
                            value={dashboardType}
                            onValueChanged={changeDashboard}                         
                        />
                    </div>
                </Item>   
                <Item ratio={1}>
                    <div style={{ paddingTop : '19px' }}>
                        <Button
                            width={120}
                            text={`${loading ? 'Buscando...' : 'Buscar'}`}
                            type="default"
                            icon="search"
                            stylingMode="contained"
                            className="m-1"
                            disabled={loading}
                            onClick={loadReport}
                            />
                        </div>
                </Item>
            </Box>
            <div>
                <CharByType 
                    type={dashboardType} 
                    typeLabel={currentTypeLabel} 
                    reverseType={reverseType}
                    reverseTypeLabel={reverseTypeLabel}
                    data={data} />
            </div>
                
        </div>
    );
}



export default DashboardSales;
