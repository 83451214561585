import React from 'react';
import PreBills from './PreBills';
import { preBillType } from '../../../data/enums';

const MobilePreVentas = (props) => {

    const title = "PreVentas";
    const action = "PreVenta";
    const typeId = preBillType.preventa;
    const initStates = [2,3];
    
    return (
        <PreBills title={title} action={action} typeId={typeId} initStates={initStates} targetPage='cotizaciones' {...props}/>
    );

}

export default MobilePreVentas;
