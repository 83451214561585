import React, { useRef, useState } from 'react';
import { Popup } from 'devextreme-react';
import Form, { SimpleItem, Label, StringLengthRule, RequiredRule } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useDispatch, useSelector } from 'react-redux'
import http from '../../utils/http';
import uri from '../../utils/uri';
import { defaultClient } from '../../data/defaultObjects';
import { editorOptionsSwitch, editorOptsTextBoxPhone, editorOptionsSelect, resources } from '../../data/app';
import { clientDialogAction } from '../../store/client/clientDialogReducer';
import { createStoreLocal } from '../../utils/proxy';
import useAuthorization from '../../hooks/useAuthorization';

const NuevoClient = (props) => {

    const { fullScreen } = props;

    const dispatch = useDispatch();
    
    const { clientDialog: { open  } } = useSelector(store => store);

    const [cliente, setcliente] = useState({ ...defaultClient });  
    const [saving, setSaving] = useState({
        loading : false,
        text : 'Guardar'
    });

    const closeDialog = (load, clientId) => {

        dispatch(clientDialogAction({ id: 0, clientId: 0, open: false }));

        if(load)
            if(props.onCreate)
                props.onCreate(clientId);

    }

    const onHiding = ({ load }) => {

        closeDialog(load, 0);
        
    }


    const onShowing = (e) => {
        setcliente({ ...defaultClient });
    }

    const refForm = useRef();

    const guardarCliente = (params) => {

        let result = refForm.current.instance.validate();      

        if (result.isValid) {

            setSaving({loading : true, text:'Guardando...'});
            
            http(uri.clients.insert).asPost(cliente).then(resp => {

                notify('Datos guardados correctamente');
                setSaving({loading : false, text:'Guardar'});               
                closeDialog(true, resp.id);

            }).catch(err => {

                setSaving({loading : false, text:'Guardar'});
                notify(err, 'error');

            });

        }
    }

    const screenOpts = {  };

    if(fullScreen)
        screenOpts.fullScreen = true;
    else{
        screenOpts.width = 850;
        screenOpts.height = 430;
    }   

    return (
        <div>           
            <Popup
                title={`Cliente`}
                {...screenOpts}
                onHiding={onHiding}
                onShowing={onShowing}
                visible={open}
                showCloseButton={true}
                className="bg-fbfbfb"
            >
                <Form formData={cliente} ref={refForm} colCount={2}>
                    <SimpleItem dataField="isCompany" colSpan={2} editorType='dxSwitch' editorOptions={{...editorOptionsSwitch, onValueChanged: e => setcliente({ ...cliente, isCompany: e.value, typeId: e.value ? cliente.typeId : null })}}>
                        <Label text="Es Empresa ?" />                        
                    </SimpleItem>
                    <SimpleItem dataField="identification">
                        <Label text={cliente.isCompany ? 'RUC':'Identificación'} />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="typeId" editorType="dxSelectBox"
                        editorOptions={{
                            disabled: !cliente.isCompany,
                            dataSource: createStoreLocal({ name: 'clientType' }),
                            ...editorOptionsSelect,
                            onValueChanged: e => setcliente({ ...cliente, typeId: e.value, })
                        }} >
                        <Label text="Tipo" />
                        { cliente.isCompany == true && <RequiredRule message="Este campo es requerido" />}
                    </SimpleItem>                   
                    <SimpleItem dataField="name" colSpan={1}>
                        <Label text={ cliente.isCompany ? 'Comercio':'Nombre' } />
                        <RequiredRule message="Este campo es requerido" />
                        <StringLengthRule max={100} message="Maximo 100 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="lastName">
                        <Label text={ cliente.isCompany ? 'Contacto':'Apellidos'} />
                        { cliente.isCompany == false && <RequiredRule message="Este campo es requerido" />}
                        <StringLengthRule max={100} message="Maximo 100 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="birthdate" editorType='dxDateBox' editorOptions={{
                            disabled: cliente.isCompany,
                            openOnFieldClick:true
                        }}>
                        <Label text="Fecha de Nac" />
                    </SimpleItem>                   
                                  
                    <SimpleItem dataField="phoneNumber" editorOptions={{...editorOptsTextBoxPhone}}>
                        <Label text="Telefono" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="phoneNumberWork" editorOptions={editorOptsTextBoxPhone}>
                        <Label text="Telefono Trabajo" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="cellNumber" editorOptions={editorOptsTextBoxPhone}>
                        <Label text="Celular" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="email">
                        <Label text="Correo" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="address">
                        <Label text="Dirección" />
                        <StringLengthRule max={250} message="Maximo 250 caracteres" />
                    </SimpleItem>
                    
                </Form>
                <br />
                <Button
                    width={180}
                    text={saving.text} 
                    disabled={saving.loading}
                    type="default"
                    icon='save'
                    onClick={guardarCliente}
                />
                <br />
            </Popup>
        </div>
    );
}

export default NuevoClient;
