import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, Box, DropDownBox, DateBox, SelectBox, Button } from 'devextreme-react';
import { Item } from "devextreme-react/box";
import {
    Paging,
    Pager,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Column,
    Lookup,
    Editing,
    Button as ButtonGrid,
    Export,
    Scrolling,
    Selection,
    GroupPanel,
    SearchPanel,
    Grouping,
    Summary,
    TotalItem,
    GroupItem
} from 'devextreme-react/data-grid';
import Title from '../../../components/shared/Title';
import BlockHeader from '../../../components/shared/BlockHeader';

import { _path } from "../../../data/headerNavigation";
import { formatDate } from '../../../data/app';
import { cellRender, cellRenderBold, dataFormatId, formatToMoneyNumber, onCellPreparedSales, } from '../../../utils/common';

import uri from '../../../utils/uri';
import useProducts from '../../../hooks/useProducts';
import http from '../../../utils/http';
import { dateByReport, defaultLabels } from '../../../data/defaultObjects';
import { userService } from '../../../services/user.service';

const Compras = () => {

    const user = userService.getUser();

    let dropDownBoxRef = useRef();

    const [ areas, setAreas ] = useState([]);
    const [ areaId, setAreaId ] = useState(user.areaId);
    const { products } = useProducts({ areaId }); 
    const [ productId, setProductId ] = useState(null);
    const [ users, setUsers ] = useState([]);
    const [ username, setUsername ] = useState([]);
    const [ dateFrom, setDateFrom ] = useState(dateByReport.from);
    const [ dateTo, setDateTo ] = useState(dateByReport.to);
    const [ data, setData ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);

    const changeHandler = (e) => {
        setProductId(e.value);
    }

    const dataGrid_onSelectionChanged = (e) => {        
        setProductId(e.selectedRowKeys[0].id);
        dropDownBoxRef.current.instance.close();            

    }

    const changeArea = e => setAreaId(e.value);    

    const changeUser = e => setUsername(e.value);    

    const changeDateFrom = e => setDateFrom(e.value);

    const changeDateTo = e => setDateTo(e.value);    

    useEffect(() => {
        http(uri.areas.get).asGet().then(resp => setAreas(resp));
        http(uri.users.get).asGet().then(resp => setUsers(resp));
    }, [0]);

    const loadReport = () => {
        if(areaId > 0 && dateFrom && dateTo){
            setLoading(true);
            http('reports/shoppings').asPost({areaId, productId, from : dateFrom, to: dateTo}).then(resp => {
                setData(resp);
                setLoading(false);
            });      
        }
    };

    const dataGridRender = () => {
        return (
          <DataGrid
            dataSource={products}
            hoverStateEnabled={true}
            selectedRowKeys={productId}
            onSelectionChanged={dataGrid_onSelectionChanged}
            height="100%">            
            <Selection mode="single" />
            <Scrolling mode="infinite" />
            <Paging enabled={true} pageSize={10} />
            <FilterRow visible={true} />
            <Column dataField="internalCode" caption="Codigo" width={80} />
            <Column dataField="name" caption="Nombre" />
            <Column dataField="family" caption={defaultLabels.family} width={120}></Column>
            <Column dataField="um" caption="UM" width={120}></Column>
          </DataGrid>
        );
    }

    const toMoney = data => formatToMoneyNumber(data.value);  

    const title = 'Reporte de Compras';

    return (
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title}>
                
            </BlockHeader>
            <Box direction="row" width="100%" height={75}>    
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Seleccione el area:</label>
                        <SelectBox items={areas}
                            placeholder="Selecciona una bodega"
                            showClearButton={true} valueExpr="id" displayExpr="name" 
                            value={areaId}
                            onValueChanged={changeArea}                         
                        />
                    </div>
                </Item>   
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Desde:</label>
                        <DateBox type="date" 
                            value={dateFrom}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateFrom}/>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Hasta:</label>
                        <DateBox type="date" 
                            value={dateTo}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateTo}/>
                    </div>
                </Item> 
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Usuario:</label>
                        <SelectBox items={users}
                            placeholder="Selecciona"
                            showClearButton={true} valueExpr="username" displayExpr="username" 
                            value={username}
                            onValueChanged={changeUser}                         
                        />
                    </div>
                </Item>         
                <Item ratio={2}>
                    <div className="ml-10">
                        <label>Seleccione un item:</label>
                        <DropDownBox
                            ref={dropDownBoxRef}
                            dataSource={products}
                            key="id"
                            placeholder="Selecciona un producto"
                            showClearButton={true} 
                            valueExpr="id"      
                            displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''} 
                            value = {productId}              
                            onValueChanged={changeHandler} 
                            contentRender={dataGridRender}  
                            dropDownOptions={{ width: 500 }}                      
                        />
                    </div>
                </Item>                
                <Item ratio={1}>
                    <div style={{ paddingTop : '19px' }}>
                        <Button
                            width={120}
                            text={`${loading ? 'Buscando...' : 'Buscar'}`}
                            type="default"
                            icon="search"
                            stylingMode="contained"
                            className="m-1"
                            disabled={loading}
                            onClick={loadReport}
                            />
                        </div>
                </Item>
            </Box>
            <Box direction="row" width="100%" height={75}>

                <Item ratio={1}>                    
                    <DataGrid
                        id="gridContainer"
                        //keyExpr="id"
                        selection={{ mode: 'single' }}
                        dataSource={data}
                        showBorders={true}
                        showRowLines={true}
                        hoverStateEnabled={true}
                        onCellPrepared={onCellPreparedSales}
                    >             
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} width={200}/>
                        <Grouping autoExpandAll={true} />          
                        <Paging defaultPageSize={10} />
                        <Pager
                            showInfo={true}
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 20, 50,100,500]}
                        />
                        <Export enabled={true} fileName={title} allowExportSelectedData={true} />  


                        <Column dataField="id" caption="Factura" width={100} cellRender={dataFormatId}/>                                     
                        <Column dataField="date"  dataType="date" caption="Fecha" format={formatDate} width={100}/>                                     
                        
                        <Column dataField="productId" caption="Código" width={80}/>                                     
                        <Column dataField="product" caption="Producto" />    

                        <Column dataField="quantity" caption="Cantidad"  width={80}/>                                     
                     
                        <Column caption='Costos' alignment='center'>
                            <Column dataField="cost" caption="Costo" cellRender={cellRender()} width={100} />                                     
                            <Column dataField="costoTotal" caption="Costo Total" cellRender={cellRender()} width={100}/>                                     
                        </Column>
                        <Column caption='Precios' alignment='center'>
                            <Column dataField="price" caption="Precio" cellRender={cellRender()} width={100} />                                     
                            <Column dataField="priceTotal" caption="Precio Total" cellRender={cellRender()} width={100} />                                     
                            <Column dataField="utilidades" caption="Utilidades" cellRender={cellRender()} width={100}/>                                     
                        </Column>
                        <Summary>

                            <TotalItem column="id" summaryType="sum" customizeText={() => 'Totales :'}/>
                            
                            <TotalItem column="price" summaryType="sum" customizeText={toMoney}/>
                            <TotalItem column="totalDetail" summaryType="sum" customizeText={toMoney}/>
                            <TotalItem column="cost" summaryType="sum" customizeText={toMoney} />
                            <TotalItem column="costoTotal" summaryType="sum" customizeText={toMoney}/>
                            <TotalItem column="priceTotal" summaryType="sum" customizeText={toMoney}/>
                            <TotalItem column="utilidades" summaryType="sum" customizeText={toMoney}/>

                            <GroupItem column="totalDetail" summaryType="sum" alignByColumn={true} customizeText={toMoney}/>
                            <GroupItem column="price" summaryType="sum" alignByColumn={true} showInGroupFooter={true} customizeText={toMoney}/>
                            <GroupItem column="utilidades" summaryType="sum" alignByColumn={true} showInGroupFooter={true} customizeText={toMoney}/>
                            <GroupItem column="priceTotal" summaryType="sum" alignByColumn={true} showInGroupFooter={true} customizeText={toMoney}/>
                            <GroupItem column="cost" summaryType="sum" alignByColumn={true} showInGroupFooter={true} customizeText={toMoney}/>
                            <GroupItem column="costoTotal" summaryType="sum" alignByColumn={true} showInGroupFooter={true} customizeText={toMoney}/>

                        </Summary>                
                    </DataGrid>
                </Item>               
            </Box>
        </div>
    );
}



export default Compras;
