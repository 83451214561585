import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, Box, DropDownBox, DateBox, SelectBox, Button } from 'devextreme-react';
import { Item } from "devextreme-react/box";
import {
    Paging,
    Pager,
    FilterRow,
    Column,
    Export,
    Scrolling,
    Selection,
    GroupPanel,
    SearchPanel,
    Grouping,
    Summary,
    TotalItem,
    GroupItem
} from 'devextreme-react/data-grid';
import Title from '../../../components/shared/Title';
import BlockHeader from '../../../components/shared/BlockHeader';

import { _path } from "../../../data/headerNavigation";
import { formatDate } from '../../../data/app';
import { cellRender, formatToMoneyNumber, onCellPreparedSales, } from '../../../utils/common';

import uri from '../../../utils/uri';
import http from '../../../utils/http';

import { dateByReport, defaultLabels } from '../../../data/defaultObjects';

const Ingresos = (props) => {
  
    const [ dateFrom, setDateFrom ] = useState(dateByReport.from);
    const [ dateTo, setDateTo ] = useState(dateByReport.to);
    const [ data, setData ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);


    const changeDateFrom = e => setDateFrom(e.value);

    const changeDateTo = e => setDateTo(e.value);  

    const loadReport = () => {
        if(dateFrom && dateTo){
            setLoading(true);
            http('reports/ingresos').asPost({areaId:0, from : dateFrom, to: dateTo}).then(resp => {
                setData(resp);
                setLoading(false);
            });      
        }
    };

    const title = 'Últimos Ingresos';

    return (
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} showFavorite={true} {...props}></BlockHeader>
            <Box direction="row" width="100%" height={75}>                   
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Desde:</label>
                        <DateBox type="date"                                            
                            value={dateFrom}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateFrom}/>
                    </div>
                </Item>
                <Item ratio={1}>
                    <div className="ml-10">
                        <label>Hasta:</label>
                        <DateBox type="date" 
                            value={dateTo}
                            displayFormat={formatDate} 
                            openOnFieldClick={true} 
                            onValueChanged={changeDateTo}/>
                    </div>
                </Item>  
                <Item ratio={1}>
                    <div style={{ paddingTop : '19px' }}>
                        <Button
                            width={120}
                            text={`${loading ? 'Buscando...' : 'Buscar'}`}
                            type="default"
                            icon="search"
                            stylingMode="contained"
                            className="m-1"
                            disabled={loading}
                            onClick={loadReport}
                            />
                        </div>
                </Item>
            </Box>
            <Box direction="row" width="100%" height={75}>
                <Item ratio={1}>                    
                    <DataGrid
                        id="gridContainer"
                        keyExpr="id"
                        selection={{ mode: 'single' }}
                        dataSource={data}
                        showBorders={true}
                        showRowLines={true}
                        hoverStateEnabled={true}
                        onCellPrepared={onCellPreparedSales}
                    >             
                      
                        
                        <Paging defaultPageSize={10} />
                        <Pager
                            showInfo={true}
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 20, 50,100,500]}
                        />
                        <Export enabled={true} fileName={title} allowExportSelectedData={true} />  
                        <FilterRow visible={true} />

                        <Column dataField="id" caption="Compra#" width={70} />                                     
                        <Column dataField="date"  dataType="date" caption="Fecha" format={formatDate} width={100}/>                                  
                        <Column dataField="provider" caption="Proveedor" width={70} visible={false}/>                                     
                        
                        <Column dataField="internalCode" caption="Código" width={100}/>                                     
                        <Column dataField="product" minWidth={180} caption="Producto" />  

                        <Column dataField="family" caption={defaultLabels.family} width={100}/>    
                        <Column dataField="um" caption="UM" width={100}/>    
                        <Column dataField="presentation" caption={defaultLabels.presentacion} width={100}/>    

                        <Column dataField="quantity" caption="Cant"  width={70}/>                                     
                        <Column dataField="price" caption="Precio" cellRender={cellRender()}  width={100}/>                                     
                         
                            
                    </DataGrid>
                </Item>               
            </Box>
        </div>
    );
}



export default Ingresos;
