
import React from 'react'
export const logotipo = <img className="nav-panel-logo" width={100} src={require('../svg/logotipo.png')} />
export const logoHome = <img className="nav-panel-logo" width={250} src={require('../svg/homelogo.png')} />
export const logoHome150 = <img className="nav-panel-logo" width={220} src={require('../svg/homelogo.png')} />
export const working = <img className="nav-panel-logo" width={350} src={require('../svg/working.png')} />
export const cart = <img className="nav-panel-logo" width={100} src={require('../svg/cart.png')} />
export const file = <img className="nav-panel-logo" width={100} src={require('../svg/file.png')} />
export const book = <img className="nav-panel-logo" width={100} src={require('../svg/book.png')} />

export const cotizacion = <img className="nav-panel-logo" width={92} src={require('../svg/cotizaciones.png')} />
export const ventas = <img className="nav-panel-logo" width={92} src={require('../svg/ventas.png')} />
export const clientes = <img className="nav-panel-logo" width={92} src={require('../svg/clientes.png')} />
export const bills = <img className="nav-panel-logo" width={92} src={require('../svg/bill.png')} />
export const clients = <img className="nav-panel-logo" width={92} src={require('../svg/clients.png')} />
export const clients2 = <img className="nav-panel-logo" width={92} src={require('../svg/clients2.png')} />


export const colorNavBar = () => '#fff';