import {  TOOLBAR_ADD, TOOLBAR_REMOVE, TOOLBAR_LOAD } from "../consts";

const stateInit = [
    { name:'Aplicación', path: '/app/config'}
];

const myPathName = 'myPaths';


export const addToolBarAction = ({ name, path}) => ({
    type: TOOLBAR_ADD,
    payload : {name, path}
});

export const removeToolBarAction = (name) => ({
    type: TOOLBAR_REMOVE,
    payload : name
});

const getPaths = () => JSON.parse(localStorage.getItem(myPathName)) || stateInit;

export default function toolBarOptsReducer(state = stateInit, { type, payload }) {

    const actions = {
        [TOOLBAR_ADD] : () => {

            const myPaths = getPaths();
            const exits = myPaths.find(x => x.name === payload.name);

            if(exits) return [...myPaths];

            myPaths.push(payload);
            localStorage.setItem(myPathName, JSON.stringify(myPaths));
            return [...myPaths];

        },
        [TOOLBAR_REMOVE] : () => {
            
            const myPaths = getPaths();
            const myPathsFiltered = myPaths.filter(x => x.name !== payload);
            localStorage.setItem(myPathName, JSON.stringify(myPathsFiltered));
            return [...myPathsFiltered];

        },
        [TOOLBAR_LOAD] : () => {
            const myPaths = getPaths();
            return myPaths;
        }
    }

    return actions[type]?.call() || actions[TOOLBAR_LOAD]?.call();
}

