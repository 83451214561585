import React, { useEffect, useMemo, useState } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Popup,Button } from 'devextreme-react';
import {
    Validator,
    CustomRule,
    StringLengthRule
} from 'devextreme-react/validator';
import { calculateEvaluate } from '../../data/app';
import notify from 'devextreme/ui/notify';
import { useSelector, useDispatch } from 'react-redux';
import {  DivFormColumns } from '../../components/form/DivForm';
import { appRuleDialogAction } from '../../store/appRule/appRuleDialogReducer';
import http from '../../utils/http';
import { setAppInfo } from '../../store/app/appActions';

const AppInfo = () => { 

    const [saving, setSaving] = useState({
        loading : false,
        text : 'Actualizar'
    });

    const dispatch = useDispatch();
    const { appInfo, appRuleDialog: { open  } } = useSelector(store => store);

    const [app, setApp] = useState({...appInfo});
    const [textValuated, setTextValuated] = useState('');
    const [textValuatedLocal, setTextValuatedLocal] = useState('');   
    const [textValuatedPrice, setTextValuatedPrice] = useState('');

    useEffect(() => {
        setApp({...app, ...appInfo});
    }, [appInfo]);

    const costMock = 100;    

    const evaluateRules = rule => {

        if(rule.value?.length > 0){

            const valSpaceless = rule.value.replace(/\s/g, '');

            if(valSpaceless.startsWith('*') || valSpaceless.startsWith('+') || valSpaceless.startsWith('-') || valSpaceless.startsWith('/')){
                          
                const result = calculateEvaluate(costMock, rule.value);            
    
                if (result == null) return false;
                if (result == undefined) return false;
                if (isNaN(result)) return false;
    
                return true;        
               
            }

            rule.rule.message = 'La formula debe iniciar con un operador matemático (*, +, -, /)';

            return false;            

        }

        return true;

    }

    const setRule = propName => e => {

        const result = calculateEvaluate(costMock, e.value);
        const text = `Costo=${costMock}, Formula: ${costMock}${e.value}, Resultado del costo real: ${result}`

        setApp(app => ({ ...app, [propName]: e.value }));

        if (propName == 'ruleCalcuteNewPrice')            
            setTextValuated(text);

        if (propName == 'ruleCalcuteNewPriceLocal')
            setTextValuatedLocal(text);
        

    }

    const closeDialog = () => {

        dispatch(appRuleDialogAction({ open: false }));

    }

    const onHiding = ({ load }) => {

        closeDialog();
        
    }

    
    const onFormSubmit = (e) => {

        e.preventDefault();

        setSaving({loading : true, text:'Guardando...'});
        http('about/set-info').asPost(app).then(resp => {

            notify('Datos actualizados');
            setSaving({loading : false, text:'Guardar'});
            dispatch(setAppInfo(app));
            
        }).catch(err => {

            notify(err, 'error');
            setSaving({loading : false, text:'Guardar'});

        });
    }
    
    const costButton = useMemo(
        () => ({
            text: 'Costo',
            stylingMode: 'text',
            onClick: () => 0,
        }),
        [],
    );

    const priceButton = useMemo(
        () => ({
            text: 'Precio',
            stylingMode: 'text',
            onClick: () => 0,
        }),
        [],
    );

    return (
        <Popup
                title={`Configuración del Compras`}                
                onHiding={onHiding}              
                visible={open}
                showCloseButton={true}
                className="bg-fbfbfb"
                width={500}
                height={'95vh'}
            >
                <div className="">                    
                    <form onSubmit={onFormSubmit}>
                        <div className=""> 
                            <DivFormColumns title='Fórmula para compras de importación'
                                description="Defina la fórmula para el cálculo del precio en base al costo para las compras de importación">                      
                                <div>
                                    <TextBox 
                                        placeholder=' * 1.15 * 1.30'
                                        value={app.ruleCalcuteNewPrice}
                                        onValueChanged={setRule('ruleCalcuteNewPrice')}
                                    >
                                        <TextBoxButton
                                            name="ruleCalcuteNewPrice"
                                            location="before"
                                            options={costButton}
                                        />
                                        <Validator>
                                            <StringLengthRule max={350} message="Maximo de caracteres permitidos 350" />
                                            <CustomRule 
                                                validationCallback={evaluateRules}
                                                message="Fórmula incorrecta, verifique los valores ingresados"
                                                />
                                        </Validator>
                                    </TextBox>
                                    <span>{textValuated}</span>
                                </div>
                            </DivFormColumns>
                            <DivFormColumns title='Fórmula para compras locales'
                                description="Defina la fórmula para el cálculo del precio en base al costo para las compras locales">   
                                <div>
                                    <TextBox                                
                                        placeholder=' * 1.15 * 1.30'
                                        value={app.ruleCalcuteNewPriceLocal}
                                        onValueChanged={setRule('ruleCalcuteNewPriceLocal')}
                                        >
                                        <TextBoxButton
                                            name="ruleCalcuteNewPriceLocal"
                                            location="before"
                                            options={costButton}
                                        />
                                        <Validator>
                                            <StringLengthRule max={350} message="Maximo de caracteres permitidos 350" />
                                            <CustomRule 
                                                validationCallback={evaluateRules}
                                                message="Fórmula incorrecta, verifique los valores ingresados"
                                                />
                                        </Validator>
                                    </TextBox>
                                    <span>{textValuatedLocal}</span>
                                </div>                   
                                
                            </DivFormColumns>
                            <DivFormColumns title='Fórmula para el precio en las  compras'
                                description="Defina la fórmula para el cálculo del precio real en base al costo para las compras">   
                                <div>
                                    <TextBox                                
                                        placeholder=' * 1.15 '
                                        value={app.ruleProfitPrice}
                                        showClearButton={true}
                                        onValueChanged={e => {

                                            setApp(app => ({ ...app, ruleProfitPrice: e.value }));
                                            const result = calculateEvaluate(costMock, e.value);
                                            const text = `Costo Real=${costMock}, Formula: ${costMock}${e.value}, Resultado del precio: ${result}`
                                            setTextValuatedPrice(text);
                                        }}
                                        >
                                        <TextBoxButton
                                            name="ruleProfitPrice"
                                            location="before"
                                            options={priceButton}
                                        />
                                        <Validator>
                                            <StringLengthRule max={350} message="Maximo de caracteres permitidos 350" />
                                            <CustomRule 
                                                validationCallback={evaluateRules}
                                                message="Fórmula incorrecta, verifique los valores ingresados"
                                                />
                                        </Validator>
                                    </TextBox>
                                    <span>{textValuatedPrice}</span>
                                </div>                   
                                
                            </DivFormColumns>
                        </div>    
                        <Button 
                            icon='save'
                            text={saving.text} 
                            disabled={saving.loading}
                            type="default"                     
                            useSubmitBehavior={true}>
                        </Button>        
                    </form>         
                    
                </div>
            </Popup>
        
    );
}

export default AppInfo;
