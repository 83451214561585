import { DIALOG_BILL_DISABLED } from "../consts";

const mydialog = { open: false, id: 0, name: ''  }

export const billDisabledDialogAction = ({ open = false, id = 0, name = ''  }) => ({
    type: DIALOG_BILL_DISABLED,
    payload : { open, id, name  }
});

export default function billDisabledDialogReducer(state = mydialog, { type, payload }) {

    const actions = {
        [DIALOG_BILL_DISABLED] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}
