import React from 'react';
import PreBills from './PreBills';
import { preBillType } from '../../data/enums';

const Cotizaciones = (props) => {

    const title = "Cotizaciones";
    const action = "Cotización";
    const typeId = preBillType.cotizacion;
    const initStates = [1];

    return (
        <PreBills title={title} action={action} typeId={typeId} initStates={initStates} {...props}/>
    );
}

export default Cotizaciones;
