import React from "react";
import useApi from "./useApi";
import uri from "../utils/uri";


const useClients = ({reload=0}) => {    
    
    const { data: clients, isLoading } = useApi({ url: uri.clients.base+'/catalogo?reload='+reload }); 
   
    return {
        clients, 
        isLoading
    }
}

export default useClients;