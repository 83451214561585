import { dialogDefault, DIALOG_APPRULE } from "../consts";

const mydialog = {...dialogDefault}


export const appRuleDialogAction = ({ open = false  }) => ({
    type: DIALOG_APPRULE,
    payload : { open  }
});

export default function appRuleDialogReducer(state = mydialog, { type, payload }) {

    const actions = {
        [DIALOG_APPRULE] : () => ({...state, ...payload })
    }

    return actions[type]?.call() || state;
}

