import React from 'react';
import CustomButton from './CustomButton';

const ButtonForm = ({icon='save', saving, textSaving, onClick, visible=true, type="success"}) => {
    return <CustomButton
        text={`${saving?'Guardando...': textSaving}`}
        type={type}
        icon={icon}
        stylingMode="contained"
        className="m-1"
        onClick={onClick}
        visible={visible}
        disabled={saving}
    />    
}

export default ButtonForm;
