import React from 'react';
import PreBills from './PreBills';
import { preBillType } from '../../../data/enums';

const MobileCotizaciones = (props) => {

    const title = "Cotizaciones";
    const action = "Cotización";
    const typeId = preBillType.cotizacion;
    const initStates = [1];

    return (
        <PreBills title={title} action={action} typeId={typeId} initStates={initStates} targetPage='pre-ventas' {...props}/>
    );
}

export default MobileCotizaciones;
