import React from 'react';

import Chart, {
    CommonSeriesSettings,
    Series,
    SelectionStyle,
    Hatching,
    Legend,
    Export,
    Tooltip,
    Title
  } from 'devextreme-react/chart';

import { formatToMoneyNumber } from '../../../../utils/common';



const ChartBar = ({ data, type, typeLabel, reverseType, reverseTypeLabel , onPointClick }) => {
    
    const totalGlobal = data.reduce((prev, current) => prev + current.total, 0);

    const customizeTooltipHTML = (pointInfo) => {

        const percent = parseFloat((pointInfo.value / totalGlobal) * 100).toFixed(2);

        return {
            html:  `<div>
                        <div class="tooltip-header">${pointInfo.argumentText}</div>
                        <div class="tooltip-body">
                            <div class="series-name">
                                <span class='top-series-name'>Total C$</span>: 
                            </div>
                            <div class="value-text">
                                <span class='top-series-value'>${formatToMoneyNumber(pointInfo.valueText)}</span>
                            </div>
                            <div class="series-name">
                                <span class='bottom-series-name'>Porcentaje</span>: 
                            </div>
                            <div class="value-text">
                                <span class='bottom-series-value'>${percent}</span>% 
                            </div>
                        </div>
                    </div>`,
        }
    }

    const orderByTotal = (a, b) => {
        return b.total - a.total;
    }

    return (
        <Chart
            id="chart"
            dataSource={data.sort(orderByTotal)}
            rotated={true}           
            onPointClick={onPointClick}
            title={`Top ventas por ${typeLabel} ${formatToMoneyNumber(totalGlobal)}`}
            >
            <CommonSeriesSettings
                argumentField={type}
                type="bar"

            />
            <Series
                valueField="total"
                name="breeds"
                color="#a3d6d2"               
            >
                <SelectionStyle color="#ec2e7a">
                    <Hatching direction="none" />
                </SelectionStyle>
            </Series>
            <Tooltip
                enabled={true}
                customizeTooltip={customizeTooltipHTML}
            />
            <Legend visible={false} />
            <Export enabled={true} />
        </Chart>
    );
}

export default ChartBar;
