import React, { useEffect, useRef, useState } from "react";
import {
    Paging,
    Pager,
    FilterRow,
    ColumnChooser,
    Column,
    Lookup,
    Export,   
    Button as ButtonGrid,   
    Editing,
    Popup,     
    Form, 
    RequiredRule,
    StringLengthRule, 
}
    from 'devextreme-react/data-grid';
   
import { EmailRule, EmptyItem, Item } from 'devextreme-react/form';
import { DataGrid } from 'devextreme-react';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from "../../components/shared/Title";
import { store } from "../../services/store";
import {  formatDate, resources,  } from '../../data/app';
import { createStore, createStoreLocal } from "../../utils/proxy";
import uri from "../../utils/uri";
import useAuthorization from "../../hooks/useAuthorization";
import { cellRender } from "../../utils/common";
import http from "../../utils/http";
import { clientDialogAction } from "../../store/client/clientDialogReducer";
import { useDispatch } from "react-redux";
import Nuevo from "./Nuevo";

const ClientsMobile = props => {

    const [cities, setCities] = useState([]);

    const [ authorized ] = useAuthorization( resources.verClientes );

    let dataGrid = useRef();
    const title = "Clientes";        

    const onToolbarPreparing = e => {

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                stylingMode:"outlined",
                type:'default',
                icon: 'add',
                text: 'Agregar cliente',
                onClick: e => showDialog(0)// dispatch(openDialog({open : true, id : 0}))
            }
        });
    }

    const onRowDblClick = (e) => {
       // history.push('/clinica/cliente/'+e.data.id);
    }

    const onSave = (params) => {
        dataGrid.current.instance.refresh();
    }    

    const dispatch = useDispatch();

    const showDialog = (id) => dispatch(clientDialogAction({ open: true, id }))

    const getFilteredCities = (options) => {
        return {
          store: cities,
          filter: options.data ? ['departmentId', '=', options.data.departmentId] : null
        };
      }

    const setStateValue = (rowData, value) => {

        rowData['departmentId'] = value || 0;
        rowData.cityId = null;
        
    }

    useEffect(() => {
        http('catalogos/City').asGet().then(resp => setCities(resp))
    }, [0]);

    const onInitNewRow = (e) => {
        e.data.creditLimit = 0;
    }



    return authorized(
        <div className="container">
            <Title title={title} />
            <BlockHeader title={title} />
            <Nuevo onSave={onSave} isMobile={true}  /> 
            <DataGrid
                ref={dataGrid}
                dataSource={store({uri : uri.clients, remoteOperations: true })}
                selection={{ mode: 'single' }}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                onToolbarPreparing={onToolbarPreparing}
                onRowDblClick={onRowDblClick}
                onInitNewRow={onInitNewRow}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}               
            >
                <Pager allowedPageSizes={[10, 15, 30, 50]} showPageSizeSelector={true} showInfo={true} />
                <Paging defaultPageSize={10} />
                <FilterRow visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={true} fileName={title} allowExportSelectedData={true} />       
                <Column type="buttons" width={90}>
                    <ButtonGrid name="edit" icon="edit" onClick={e => showDialog(e.row.data.id)}/>
                    <ButtonGrid name="delete" />
                </Column>                
                <Column dataField="identification" caption="Identificacion" width={130} visible={false} />
                <Column dataField="name" caption="Nombre" minWidth={180} />
                <Column dataField="typeId" caption="Tipo" width={120}>
                    <Lookup disabled={true} dataSource={createStoreLocal({name : 'clientType'} )} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="lastName" caption="Apellidos/Contacto" minWidth={140}/>
                <Column dataField="birthdate" caption="Fecha de Nac" width={120} dataType="date" format={formatDate} />
                <Column dataField="sexId" caption="Sexo" visible={false}>
                    <Lookup dataSource={createStoreLocal({name : 'sex'} )} valueExpr="id" displayExpr="name" />
                </Column>
                <Column dataField="address" caption="Direción" visible={false} allowFiltering={false} />
                <Column dataField="phoneNumber" caption="Telefono" allowFiltering={false} />
                <Column dataField="phoneNumberWork"  caption="Telefono Trab" allowFiltering={false}  visible={false}/>
                <Column dataField="cellNumber" caption="Celular" allowFiltering={false} />
                <Column dataField="email" caption="Correo" allowFiltering={false} visible={false} />               
                <Column dataField="departmentId" caption="Departamento" width={150} setCellValue={setStateValue} visible={false}>
                    <Lookup disabled={true} dataSource={createStore({name : 'department'} )} valueExpr="id" displayExpr="name" />
                </Column> 
                <Column dataField="cityId" caption="Ciudad" width={150}  visible={false}>
                    <Lookup disabled={true} dataSource={getFilteredCities} valueExpr="id" displayExpr="name" />
                </Column>      
                <Column dataField="creditLimit" caption="Monto Credito" cellRender={cellRender()} />        
                <Column dataField="creditDays" caption="Dias Credito"  />        
                <Column dataField="isCompany" caption="Empresa"/>       
                
                
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                >
                    <Popup title={title} showTitle={true} width={800} height={580}>

                    </Popup>                   
                </Editing>
            </DataGrid>
        </div>
    )


}


export default ClientsMobile;
