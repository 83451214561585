import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule, CustomRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { LoadPanel } from 'devextreme-react/load-panel';
import { createStoreLocal } from '../../utils/proxy';
import { calculateEvaluate, editorOptionsSelect } from '../../data/app';
import { DataGrid, ScrollView, SelectBox } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid ,Summary,TotalItem, SearchPanel} from 'devextreme-react/data-grid';
import ProductDDBComponent from '../../components/dropdown/ProductDDBComponent';
import uri from '../../utils/uri';
import { cellRender, formatId, formatToMoney, onCellPrepared } from '../../utils/common';
import http from '../../utils/http';
import useProducts from '../../hooks/useProducts';
import ButtonForm from '../../components/buttons/ButtonForm';
import notify from 'devextreme/ui/notify';
import { convert2, defaultLabels, purchaseDefault } from '../../data/defaultObjects';
import { dialogPurchase } from '../../store/inPutProductPurchase/purchaseDialogReducer';
import { purchaseSteps, purchaseTypes } from '../../data/enums';
import { confirm } from 'devextreme/ui/dialog';
import NuevoProvider from '../providers/NuevoProvider';
import { providerDialogAction } from '../../store/provider/providerDialogReducer';
import { appRuleDialogAction } from '../../store/appRule/appRuleDialogReducer';
import AppInfo from '../app/AppInfo';
import DataSource from 'devextreme/data/data_source';

const NewPurchase = props => {

    const active = true;

    const { purchaseDialog: { open, id }, user, appInfo } = useSelector(store => store);

    const [ reloadScreen, setReloadScreen] = useState(0);
    const [ reload, setReload] = useState(false);

    const { products, setProducts, refresh } = useProducts({ areaId: user.areaId, active, reload: reloadScreen });
    const [ purchase, setPurchase ] = useState({ ...purchaseDefault });
    const [ saving, setSaving ] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ details, setDetails ] = useState([]);
    const [ rate, setRate ] = useState(0);

   
    let refForm = useRef();
    let refGrid = useRef();
    let refSelect = useRef(); 

    useEffect(() => {

        if (id > 0) {
            setLoading(true);
            http(uri.compras.getById(id)).asGet().then(resp => {

                http(uri.products.getByArea(resp.areaId)).asGet().then(data => {

                    setProducts(data);

                    const { purchaseDetails, ...rest } = resp;

                    purchaseDetails.map(detail => {

                        let info = products.find(x => x.id == detail.productId);

                        detail['presentation'] = info.presentation;
                        detail['um'] = info.um;
                        detail['family'] = info.family;

                        return detail;
                    })

                    setPurchase({ ...purchaseDefault, ...rest });
                    setDetails([...purchaseDetails]);

                    setLoading(false);

                });

            });

        } else {
            setLoading(true);
            setPurchase({ ...purchaseDefault, areaId: user.areaId, rate });
            setDetails([]);
            setLoading(false);
        }

        if(open) refresh();

    }, [open]);

    const dispatch = useDispatch();
    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                stylingMode:"outlined",
                text: 'Agregar producto',
                icon:'add',
                disabled: purchase.purchaseTypeId == undefined,
                type:'default',
                onClick: () =>  {

                    let result = refForm.current.instance.validate();

                    if (result.isValid)                    
                        refGrid.current.instance.addRow();

                }
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'check',
                type: 'normal',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.saveEditData();                   
                   
                }
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'revert',
                type: 'normal',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.cancelEditData();                   
                   
                }
            }
        });
    }

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();

        dispatch(dialogPurchase({ open: false, id: 0 }));
        if (load) {
            let { onSave } = props;
            onSave();
        }

        setPurchase({ ...purchaseDefault, areaId: user.areaId });
        setDetails([]);
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const validateAllRows = () => {

        let result = true;
        let i = 0;
        let items = ds.items();

        while (i < items.length) {

            let item = items[i];

            if (item.cost <= 0) {
                refGrid.current.instance.editCell(i, 'cost');
                result = false;
                break;
            }
            if (item.quantity <= 0) {
                refGrid.current.instance.editCell(i, 'quantity');
                result = false;
                break;
            }
            if (item.price <= 0) {
                refGrid.current.instance.editCell(i, 'price');
                result = false;
                break;
            }

            i++;
        }

        return result;

    }

    const guardarEntrada = (e, successCallback) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            let result = refForm.current.instance.validate();

            if (result.isValid) {

                if (validateAllRows()) {
                
                    let result = confirm("<i>Esta seguro de guardar la orden compra?</i>", "Confirmar cambios");

                    result.then((dialogResult) => {
                        if (dialogResult) {

                            setSaving(true);
                            let data = { ...purchase, purchaseDetails: [...details] };
                
                            http(uri.compras.insert).asPost(data).then(resp => {
                                setSaving(false);
                
                                if (successCallback) {
                                    successCallback(data);
                                }
                                else {
                                    if (isNew) {
                                        notify('Compra registrada correctamente');
                                        closeDialog(true);
                                    }
                                    else {
                                        notify('Compra actualizada correctamente');
                                    }
                
                                }
                
                
                            }).catch(err => {
                                setSaving(false);
                                notify(err, 'error', 8000);
                            });
                            
                        }
                    });

                }

            }

        }

    }

    const procesarCompra = (e) => {

        setProcessing(true);
        setSaving(true);
        guardarEntrada(e, (data) => {
            http(uri.compras.base + '/process').asPost(data).then(resp => {
                notify('Compra procesada en inventario correctamente');
                setProcessing(false);
                setSaving(false);
                closeDialog(true);
            });
        });

    }

    const getResult = (cost, ruleName) => {

        let rule = ''
        rule =  appInfo[ruleName];    
        const resultCost = calculateEvaluate(cost, rule);

        return resultCost ?? 0;
       
    }

    const itemRender = (data) => {

        const isZero = data.stock <= 0;

        const cssZero = isZero ? 'product-stock zero-stock' : 'product-stock';

        return <div className="d-flex space-between">
            <div>
                <div className="product-name"><b>{data.internalCode}</b>-{data.name}</div>
                <div className="product-desciption"><small className='text-muted'>Categoria: </small>{data.family} <small className='text-muted'>Marca: </small>{data.presentation}</div>
            </div>  
            <div className="text-right">
                <div className="product-price"><span className='product--render-cost'>{formatToMoney(data.cost)}</span> | <span className='product--render-price'>{formatToMoney(data.price)}</span></div>
                <div className={cssZero}><small className='text-muted'>Disponible:</small> {data.stock}/{data.disponible}</div>
            </div>    
        </div>
    }


    const setCellValue = (prop, newData, value, currentRowData) => {
        
        newData[prop] = value || 0;
        if (prop == 'productId' && value) {
            let info = products.find(x => x.id == value);

            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['cost'] = 0;
            newData['realCost'] = info.cost;
            newData['price'] = 0;
            newData['oldPrice'] = info.price;
            newData['quantity'] = 1;           
            newData['total'] = info.cost;

            if(purchase.currencyId == 1){
                if(rate > 0)
                    newData['dolarCost'] = convert2(info.cost / rate);
                else
                    newData['dolarCost'] = 0                
            }  
        }

        if (prop == 'quantity' && (+value) >= 0) {
            newData['total'] = currentRowData['realCost'] * value;
            
            if(purchase.currencyId == 1){
                if(rate > 0)
                    newData['dolarCost'] = convert2(value / rate);
                else
                    newData['dolarCost'] = 0;                
            }                
            
        }

        if (prop == 'cost' && (+value) >= 0) {

            const info = products.find(x => x.id == currentRowData.productId);
            
            newData['dolarCost'] = (rate > 0 ? convert2(value / rate) : 0);                   
           
            let realCost = 0;
            if(purchase.purchaseTypeId == purchaseTypes.nacional)   
                realCost = getResult(value, 'ruleCalcuteNewPriceLocal'); 
            
            if(purchase.purchaseTypeId == purchaseTypes.importacion)
                realCost = getResult(value, 'ruleCalcuteNewPrice');

            newData['realCost'] = realCost;
            newData['total'] = currentRowData['quantity'] * realCost;
            newData['price'] = getResult(info.price, 'ruleProfitPrice');

        }

        if (prop == 'dolarCost' && (+value) >= 0) {      
            
            const info = products.find(x => x.id == currentRowData.productId);
           
            const newCost= value * rate;
            newData['cost'] = newCost;           

            let realCost = 0;

            if(purchase.purchaseTypeId == purchaseTypes.nacional)                
                realCost = getResult(newCost, 'ruleCalcuteNewPriceLocal');  
        
            if(purchase.purchaseTypeId == purchaseTypes.importacion)                        
                realCost = getResult(newCost, 'ruleCalcuteNewPrice');                  
                
            
            newData['realCost'] = realCost;
            newData['total'] = currentRowData['quantity'] * realCost;
            newData['price'] = getResult(info.price, 'ruleProfitPrice');

        }

    }

    const isNew = id == 0;

    const textSaving = 'Guardar Entrada';

    const canProcess = !isNew && purchase.stepId == purchaseSteps.pendiente;

    const canSave = isNew || purchase.stepId == purchaseSteps.pendiente;

    const buttonOptions = {
        text: 'Agregar proveedor',
        icon: 'user',
        onClick: function(e) { 
            dispatch(providerDialogAction({ open: true }));
        }
    };

    const appButtonOptions = {
        text: 'Reglas de cálculo',
        icon: 'menu',
        onClick: function(e) { 
            dispatch(appRuleDialogAction({ open: true }));
        }
    };

    const reloadProvider = providerId => {
        setPurchase(_purchase => ({ ..._purchase, providerId: providerId }));
    }

    useEffect(() => {
        
        if(purchase.date)
            http(uri.rates.base + '/GetByDate').asPost(purchase.date).then(resp => {

                const newRate = resp ? resp.value : 0;

                if(newRate != purchase.rate && newRate > 0){

                    setPurchase(_purchase => ({ ..._purchase, rate: newRate }));
                    setRate(newRate);

                }
                
            });
       
    }, [purchase.date]);

    const currencies = [{ id: 1, name: 'Córdobas' }, { id: 2, name: 'Dólares' }];

    const onRowInserting = (e) => {

        const isCanceled = new Promise((resolve, reject) => {

            const existsProduct = details.find(x => x.productId == e.data.productId);

            if (existsProduct) {
                notify(`El producto ya se encuentra en la lista`, 'error', 5000);
                reject(`El producto ya se encuentra en la lista`);
            }else{
                resolve(false);
            }
        });
        e.cancel = isCanceled;

    }

    const dataSourceProduct = useCallback(() => new DataSource({
        load: (loadOptions) => {
    
            let params = {};
            params.skip = loadOptions.skip || 0;
            params.take = loadOptions.take || 10;
    
            if(loadOptions.searchValue)
                params.name = loadOptions.searchValue;

            params.exists = true;
            params.active=false; 
            params.has=false;
            params.onlyProducts= false;
    
            return http(uri.products.getByArea(user.areaId)+'/grid')
            .asGet(params).then(x => x.items);
            
        },
        byKey: id => http(uri.products.getById(id)).asGet(),
        paginate : true,
        pageSize: 10
    }),[]);

    useEffect(() => {

        if(!refGrid.current)
            return;

        refGrid.current.instance.beginUpdate();
        
        let i = 0;
        const totalCount = refGrid.current.instance.totalCount()
        while(i < totalCount) {

            if(purchase.currencyId == 1) //Cordobas
            {
                let valueCordoba = refGrid.current.instance.cellValue(i, 'cost');
                let valueRound = convert2(valueCordoba / rate);
                refGrid.current.instance.cellValue(i, 'dolarCost', valueRound);
            }

            if(purchase.currencyId == 2) //Dolares
            {
                let valueDolar = refGrid.current.instance.cellValue(i, 'dolarCost');
                let valueRound = convert2(valueDolar * rate);
                refGrid.current.instance.cellValue(i, 'cost', valueRound); 
            }

           
            i++;

        }

        refGrid.current.instance.endUpdate();
        refGrid.current.instance.saveEditData();
    }, [rate]);

    const validateRate = e => {
        if(purchase.currencyId == 1)
            return true;
        if(purchase.currencyId == 2)
            return e.value > 0;
    
    }

    const buttonOptions2 = {
        text: 'Actualizar',
        icon: 'refresh',
        onClick: function(e) { 
            setReload(true);
            setReloadScreen(reloadScreen + 1);
        }
    };

    const ds = useMemo(() =>new DataSource({
        key: 'productId',
        store: details,
    }));

    const addProductById = ( id, _info=null ) => {

        if (!id) return;

        const items = ds.items();

        const existsProduct = items.find(x => x.productId == id);

        if (existsProduct) {
            notify(`El producto ya se encuentra en la lista`, 'error', 5000);
            return
        }

        let info = _info == null ? products.find(x => x.id == id) : _info;
      
        let newData = {}; 

        newData['presentation'] = info.presentation;
        newData['um'] = info.um;
        newData['family'] = info.family;       
        newData['cost'] = 0;
        newData['realCost'] = info.cost;        
        newData['price'] = 0;
        newData['oldPrice'] = info.price;
        newData['quantity'] = 1;           
        newData['total'] = info.cost;
        newData['productId'] = info.id;  

        if(purchase.currencyId == 1){
            if(rate > 0)
                newData['dolarCost'] = convert2(info.cost / rate);
            else
                newData['dolarCost'] = 0                
        }  

        ds.store().insert(newData).then(() => {
            ds.reload().then(() => {
                
                refGrid.current.instance.editCell(details.length-1, 'quantity');
                //refGrid.current.instance.editRow(details.length);

            });
        });

    }

    const setFocus = () => {        
        setTimeout(() => {
            refSelect.current.instance.focus();        
        }, 300);
    }

    const onEditCanceled = (e) => {
        setFocus()
    }

    const keyDown = (e) => {
        if(e.event.key == 'Enter')
            setFocus()
    }

    return (
        <div>
            <NuevoProvider onCreate={reloadProvider} />
            <AppInfo />
            <Popup
                width={'95vw'}
                height={'95vh'}    
                title={isNew ? `Nueva compra de inventario` : `Compra #${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                id='bill-panel'
                dra
            >
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#bill-panel' }}
                    visible={loading}
                    showIndicator={loading}
                    shading={true}
                    showPane={true}
                />
                <ToolbarItem widget="dxButton" 
                    location="after" 
                    options={buttonOptions2}>
                </ToolbarItem>
                <ToolbarItem widget="dxButton" 
                    location="after" 
                    options={buttonOptions}>
                </ToolbarItem>
                <ToolbarItem widget="dxButton" 
                    location="after" 
                    options={appButtonOptions}>
                </ToolbarItem>
                <ScrollView showScrollbar='never'>                
                    <Form formData={purchase} ref={refForm}>
                        <GroupItem colCount={3}>
                            <SimpleItem dataField="areaId" editorType="dxSelectBox"
                                editorOptions={{
                                    disabled: true,
                                    dataSource: createStoreLocal({ name: 'area' }),
                                    ...editorOptionsSelect
                                }} >
                                <Label text="Area" />
                                <RequiredRule message="Seleccione el area" />
                            </SimpleItem>
                            <SimpleItem dataField="date" editorType="dxDateBox"
                                editorOptions={{
                                    displayFormat: 'dd/MM/yyyy',
                                    openOnFieldClick: true,
                                    onValueChanged: e => setPurchase({ ...purchase, date: e.value })
                                }} >
                                <Label text="Fecha" />
                                <RequiredRule message="Seleccione la fecha" />
                            </SimpleItem>
                            <SimpleItem dataField="purchaseTypeId" colSpan={2} editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: createStoreLocal({ name: 'purchaseType' }),
                                    ...editorOptionsSelect,
                                    onValueChanged: e => setPurchase({ ...purchase, purchaseTypeId: e.value })
                                }} >
                                <Label text="Tipo de compra:" />
                                <RequiredRule message="Seleccione el tipo de compra" />
                            </SimpleItem>
                            <SimpleItem dataField="providerId" colSpan={1} editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: createStoreLocal({ name: 'Provider', reload }),
                                    ...editorOptionsSelect
                                }} >
                                <Label text="Proveedor" />
                                <RequiredRule message="Seleccione el proveedor" />
                            </SimpleItem>
                        
                            <SimpleItem dataField="reference">
                                <Label text="Referencia" />
                                <RequiredRule message="La referencia es requerida" />
                            </SimpleItem>
                            <SimpleItem dataField="rate" editorOptions={{
                                onValueChanged: e => setRate(e.value)
                            }}>
                                <Label text="Tipo cambio" />
                                <CustomRule validationCallback={validateRate} message="Ingrese el tipo de cambio" />
                            </SimpleItem>
                            {/* <EmptyItem></EmptyItem> */}
                            <SimpleItem dataField="currencyId" colSpan={1} editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: currencies,
                                    ...editorOptionsSelect,
                                    onValueChanged: e => setPurchase({ ...purchase, currencyId: e.value })
                                }} >
                                <Label text="Moneda" />
                                <RequiredRule message="Seleccione la moenda" />
                            </SimpleItem>
                            <SimpleItem dataField="observation" colSpan={2} editorType="dxTextBox">
                                <Label text="Observacion" />
                                <StringLengthRule max={500} message="Maximo 500 caracteres" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem colCount={4}>
                            <SimpleItem colSpan={2}>
                                <SelectBox
                                    ref={refSelect}
                                    disabled={purchase.purchaseTypeId == undefined}
                                    dataSource={dataSourceProduct()} 
                                    valueExpr="id" 
                                    displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}
                                    searchEnabled={true} 
                                    searchMode="contains" searchExpr={['name','internalCode']} 
                                    searchTimeout={600} 
                                    placeholder="Selecione una producto" 
                                    showClearButton={true}         
                                    itemRender={itemRender}                           
                                    onValueChanged={e => {

                                        addProductById(e.value);
                                        e.component.reset();
                                        e.component.focus();
                                      

                                    }} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <DataGrid id="gridDetails"
                                ref={refGrid}
                                selection={{ mode: 'single' }}
                                dataSource={ds}
                                showBorders={true}
                                showRowLines={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                height={350}
                                onToolbarPreparing={onToolbarPreparing}
                                onCellPrepared={onCellPrepared}
                                onRowInserting={onRowInserting}
                                onEditCanceled={onEditCanceled}                              
                                onKeyDown={keyDown}
                            >
                                <SearchPanel visible={true} />
                                <Column dataField="productId" caption="Producto"
                                    setCellValue={setCellValue.bind(null, "productId")}
                                    editCellComponent={ProductDDBComponent} minWidth={100}>
                                    <Lookup
                                        dataSource={products}
                                        valueExpr="id"
                                        displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}

                                    />
                                    <RuleRequired />
                                </Column>                           
                                <Column dataField="um" caption="Um" width={100} allowEditing={false}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="quantity" caption="Cant" dataType="number" width={50} setCellValue={setCellValue.bind(null, "quantity")}>
                                    <RuleRequired />
                                </Column>                            
                                <Column dataField="dolarCost" caption="Costo $" dataType="number" allowEditing={purchase.currencyId == 2} width={100} cellRender={cellRender(2)} setCellValue={setCellValue.bind(null, "dolarCost")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="cost" caption="Costo" dataType="number" allowEditing={purchase.currencyId == 1} width={100} cellRender={cellRender(1)} setCellValue={setCellValue.bind(null, "cost")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="realCost" caption="Costo Real" dataType="number" width={100} cellRender={cellRender(1)} setCellValue={setCellValue.bind(null, "cost")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="price" caption="Precio" dataType="number" width={100} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="oldPrice" caption="Precio Ant" dataType="number" width={100} cellRender={cellRender()} allowEditing={false}>
                                    <RuleRequired />
                                </Column>

                                <Column type="buttons" width={50}>
                                    <ButtonGrid name="delete" />
                                </Column>
                                <Editing
                                    mode="cell"
                                    allowDeleting={true}
                                    allowUpdating={true}
                                    selectTextOnEditStart={true}
                                    useIcons={true}
                                ></Editing>
                                <Summary>
                                    <TotalItem
                                        column="total"
                                        summaryType="sum" 
                                        customizeText={data => `${formatToMoney(data.value)}`} />
                                </Summary>
                            </DataGrid>
                        </GroupItem>
                    </Form>

                <ButtonForm saving={saving} textSaving={textSaving} onClick={guardarEntrada} visible={canSave} />
                <ButtonForm saving={processing} textSaving="Procesar compra" onClick={procesarCompra} visible={canProcess} icon="check" type='default' />
                </ScrollView>

            </Popup>
        </div>
    );
}

export default NewPurchase;