
const urlReport = () => 
{
    const urlBase = '';

    const root = process.env.PUBLIC_URL;
    const path = `${root}/api/`;

    return {      
        inPut: id => `${path}reports/${id}/movement/in`,
        outPut: id => `${path}reports/${id}/movement/out`,
        purchase: id => `${path}reports/${id}/Compra`,
        notaCredito: id => `${path}reports/${id}/NotaCredito`,
        billTicket : id => `${path}reports/print/Factura-${id}`,       
        preBillTicket : id => `${path}reports/prebill/${id}/print`,       
        recibo : id => `${urlBase}/bills/recibos?id=${id}`,       
        commissions : (desde, hasta) =>  `${urlBase}/bills/commission?desde=${desde}&hasta=${hasta}`,   
        products : (desde, hasta, username) =>  `${urlBase}/bills/products?desde=${desde}&hasta=${hasta}&username=${username}`,   

        print : (url) =>  window.open(`${url}`,'_blank'),
        
    }

}
///commission?desde=2021-03-15T00:00:00.00&hasta=2021-03-22T00:00:00.00


export default urlReport;