import React, { useRef, useState } from 'react';
import { Popup } from 'devextreme-react';
import Form, { SimpleItem, Label, StringLengthRule, RequiredRule } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useDispatch, useSelector } from 'react-redux'
import http from '../../utils/http';
import uri from '../../utils/uri';
import { defaultProvider } from '../../data/defaultObjects';
import { editorOptionsSwitch } from '../../data/app';
import { providerDialogAction } from '../../store/provider/providerDialogReducer';

const NuevoProvider = (props) => {

    const { fullScreen } = props;

    const dispatch = useDispatch();
    
    const { providerDialog: { open  } } = useSelector(store => store);

    const [provider, setProvider] = useState({ ...defaultProvider });  
    const [saving, setSaving] = useState({
        loading : false,
        text : 'Guardar'
    });

    const closeDialog = (load, providerId) => {

        dispatch(providerDialogAction({ id: 0, providerId: 0, open: false }));

        if(load)
            if(props.onCreate)
                props.onCreate(providerId);

    }

    const onHiding = ({ load }) => {

        closeDialog(load, 0);
        
    }


    const onShowing = (e) => {
        setProvider({ ...defaultProvider });
    }

    const refForm = useRef();

    const guardarProveedor = (params) => {

        let result = refForm.current.instance.validate();      

        if (result.isValid) {

            setSaving({loading : true, text:'Guardando...'});
            
            http(uri.providers.insert).asPost(provider).then(resp => {

                notify('Datos guardados correctamente');
                setSaving({loading : false, text:'Guardar'});               
                closeDialog(true, resp.id);

            }).catch(err => {

                setSaving({loading : false, text:'Guardar'});
                notify(err, 'error');

            });

        }
    }

    const screenOpts = {  };

    if(fullScreen)
        screenOpts.fullScreen = true;
    else{
        screenOpts.width = 700;
        screenOpts.height = 430;
    }   

    return (
        <div>           
            <Popup
                title={`Proveedor`}
                {...screenOpts}
                onHiding={onHiding}
                onShowing={onShowing}
                visible={open}
                showCloseButton={true}
                className="bg-fbfbfb"
            >
                <Form formData={provider} ref={refForm} colCount={2}>                    
                    <SimpleItem dataField="name" colSpan={2}>
                        <Label text={'Nombre'} />
                        <RequiredRule message="Este campo es requerido" />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="ruc">
                        <Label text={'RUC'} />
                        <RequiredRule message="Este campo es requerido" />
                        <StringLengthRule max={20} message="Maximo 20 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="property">
                        <Label text={'Propietario'} />
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="phoneNumber">
                        <Label text={'Teléfono'} />
                        <RequiredRule message="Este campo es requerido" />
                        <StringLengthRule max={20} message="Maximo 20 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="email">
                        <Label text={'Correo'} />                        
                        <StringLengthRule max={50} message="Maximo 50 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="address" colSpan={2}>
                        <Label text="Dirección" />
                        <StringLengthRule max={150} message="Maximo 150 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="observation" colSpan={2}>
                        <Label text="Nota" />
                        <StringLengthRule max={150} message="Maximo 150 caracteres" />
                    </SimpleItem>
                    <SimpleItem dataField="active" editorType="dxSwitch" editorOptions={{...editorOptionsSwitch}}>
                        <Label text="Active" />
                    </SimpleItem>                  
                </Form>
                <br />
                <Button
                    width={180}
                    text={saving.text} 
                    disabled={saving.loading}
                    type="default"
                    icon='save'
                    onClick={guardarProveedor}
                />
                <br />
            </Popup>
        </div>
    );
}

export default NuevoProvider;
