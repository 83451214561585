import React, { useEffect, useMemo, useState } from 'react';
import { Button, NumberBox, SelectBox, Switch, } from 'devextreme-react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';

import {
    Validator,
    RequiredRule,
    CustomRule,
    StringLengthRule
} from 'devextreme-react/validator';
import useAuthorization from '../../hooks/useAuthorization';
import { calculateEvaluate, dataAccess, editorOptionsNumberBox, editorOptionsSelect, editorOptionsSwitch, resources } from '../../data/app';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { useSelector,useDispatch } from 'react-redux';
import { setAppInfo } from '../../store/app/appActions';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from '../../components/shared/Title';
import { DivForm, DivFormColumns } from '../../components/form/DivForm';
import { editorOptionsDropDown } from '../../components/dropdown/ClienteDropDown';
import { createStoreLocal } from '../../utils/proxy';

const AppConfig = () => {

    const [ authorized ] = useAuthorization( resources.app );
    const [saving, setSaving] = useState({
        loading : false,
        text : 'Guardar'
    });

    const dispatch = useDispatch();
    const { appInfo } = useSelector(store => store);

    const [app, setApp] = useState({...appInfo});
    const [textValuated, setTextValuated] = useState('');
    const [textValuatedLocal, setTextValuatedLocal] = useState('');
    const [textValuatedPrice, setTextValuatedPrice] = useState('');
    

    const onFormSubmit = (e) => {

        e.preventDefault();

        setSaving({loading : true, text:'Guardando...'});
        http('about/set-info').asPost(app).then(resp => {

            notify('Datos actualizados');
            setSaving({loading : false, text:'Guardar'});
            dispatch(setAppInfo(app));
            
        }).catch(err => {

            notify(err, 'error');
            setSaving({loading : false, text:'Guardar'});

        });
    }

    useEffect(() => {
        setApp({...app, ...appInfo});
    }, [appInfo]);

    const title = 'Configuracion';

    const costMock = 100;

    

    const evaluateRules = rule => {

        if(rule.value?.length > 0){

            const valSpaceless = rule.value.replace(/\s/g, '');

            if(valSpaceless.startsWith('*') || valSpaceless.startsWith('+') || valSpaceless.startsWith('-') || valSpaceless.startsWith('/')){
                          
                const result = calculateEvaluate(costMock, rule.value);            
    
                if (result == null) return false;
                if (result == undefined) return false;
                if (isNaN(result)) return false;
    
                return true;        
               
            }

            rule.rule.message = 'La formula debe iniciar con un operador matemático (*, +, -, /)';

            return false;            

        }

        return true;

    }

    const setRule = propName => e => {

        const result = calculateEvaluate(costMock, e.value);
        const text = `Costo=${costMock}, Formula: ${costMock}${e.value}, Resultado del costo real: ${result}`

        setApp(app => ({ ...app, [propName]: e.value }));

        if (propName == 'ruleCalcuteNewPrice')            
            setTextValuated(text);

        if (propName == 'ruleCalcuteNewPriceLocal')
            setTextValuatedLocal(text);
        

    }
    
    const costButton = useMemo(
        () => ({
            text: 'Costo',
            stylingMode: 'text',
            onClick: () => 0,
        }),
        [],
      );

      const priceButton = useMemo(
        () => ({
            text: 'Precio',
            stylingMode: 'text',
            onClick: () => 0,
        }),
        [],
      );

    return authorized(
        <div className="container small">
             <Title title={title} />
             <BlockHeader title='Configuracion del sistema' />     
             <form onSubmit={onFormSubmit}>
                <div className="dx-fieldset">
                <div className="dx-fieldset-header">Configuracion de la empresa</div>
                    <DivFormColumns title='Nombre de la empresa'
                        description="Ingrese nombre de la empresa">                        
                        <TextBox                         
                            value={app.name}                           
                            onValueChanged={e => setApp(app => ({ ...app, name: e.value }))}                          
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </TextBox>
                    </DivFormColumns>
                    <DivFormColumns title='Ruc'
                        description="Ingrese ruc de la empresa">                        
                        <TextBox                         
                            value={app.slogan}                           
                            onValueChanged={e => setApp(app => ({ ...app, slogan: e.value }))}                          
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </TextBox>
                    </DivFormColumns>
                    <DivFormColumns title='Direción de la empresa'
                        description="Ingrese la direción de la empresa">                        
                        <TextBox                          
                            value={app.address}                           
                            onValueChanged={e => setApp(app => ({ ...app, address: e.value }))}                          
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </TextBox>
                    </DivFormColumns>
                    <DivFormColumns title='Teléfono de la empresa'
                        description="Ingrese el teléfono de la empresa">                        
                        <TextBox                          
                            value={app.phoneNumber}                           
                            onValueChanged={e => setApp(app => ({ ...app, phoneNumber: e.value }))}                          
                        >
                        </TextBox>
                    </DivFormColumns>
                    <div className="dx-fieldset-header">Configuracion de bodega principal</div>
                    <DivFormColumns title='Area'
                        description="Seleccione el area que desea como bodega pricipal">                        
                        <SelectBox                            
                            value={app.areaMainId}                           
                            onValueChanged={e => setApp(app => ({ ...app, areaMainId: e.value }))}
                            dataSource= {createStoreLocal({name : 'area'})}
                            {...editorOptionsSelect}
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </SelectBox>
                    </DivFormColumns>
                    <DivFormColumns title='Validar precio' 
                        description="Validar que el precio siempre sea mayor al costo en las salidas de inventario (no aplica para servicios)">                              
                        <Switch style={{ float : "left" }}                        
                            value={app.validatePriceGreaterCost}                           
                            onValueChanged={e => setApp(app => ({ ...app, validatePriceGreaterCost: e.value }))}     
                            {...editorOptionsSwitch}                      
                        >                            
                        </Switch>
                    </DivFormColumns>
                    <div className="dx-fieldset-header">Configuración de facturas</div>
                    <DivFormColumns title='% de IVA' required
                        description="El iva a aplicar en las facturas, compras y movimiento de inventario">                        
                        <NumberBox                            
                            value={app.iva}                           
                            onValueChanged={e => setApp(app => ({ ...app, iva: e.value }))}
                            {...editorOptionsNumberBox}
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </NumberBox>
                    </DivFormColumns>
                    <DivFormColumns title='Cliente' required
                        description="Cliente que se registra en la factura por defecto para aquellas facturas que son ventas rapidas">                      
                        <SelectBox                            
                            value={app.clientDefault}
                            onValueChanged={e => setApp(app => ({ ...app, clientDefault: e.value }))}
                            {...editorOptionsDropDown}
                        >
                            <Validator>
                                <RequiredRule message="Este campo es requerido" />
                            </Validator>
                        </SelectBox>
                    </DivFormColumns>
                    <DivFormColumns title='Factura tamaño A4' required
                        description="Cliente que se registra en la factura por defecto para aquellas facturas que son ventas rapidas">                      
                        <Switch style={{ float: "left" }}
                            value={app.billA4Size}
                            onValueChanged={e => setApp(app => ({ ...app, billA4Size: e.value }))}
                            {...editorOptionsSwitch}
                        >
                        </Switch>
                    </DivFormColumns>
                    <DivFormColumns title='Validar credito de clientes' required
                        description="Validar si se le puede dar credito a un cliente que no tenga configurado el limite de crédito">                      
                        <Switch style={{ float: "left" }}
                            value={app.validateCreditClient}
                            onValueChanged={e => setApp(app => ({ ...app, validateCreditClient: e.value }))}
                            {...editorOptionsSwitch}
                        >
                        </Switch>
                    </DivFormColumns>
                    <div className="dx-fieldset-header">Configuración de Compras</div>
                    <DivFormColumns title='Fórmula para compras de importación'
                        description="Defina la fórmula para el cálculo del costo real en base al costo para las compras de importación">                      
                        <div>
                            <TextBox 
                                placeholder=' * 1.15 * 1.30'
                                value={app.ruleCalcuteNewPrice}
                                onValueChanged={setRule('ruleCalcuteNewPrice')}
                            >
                                 <TextBoxButton
                                    name="ruleCalcuteNewPrice"
                                    location="before"
                                    options={costButton}
                                />
                                <Validator>
                                    <StringLengthRule max={350} message="Maximo de caracteres permitidos 350" />
                                    <CustomRule 
                                        validationCallback={evaluateRules}
                                        message="Fórmula incorrecta, verifique los valores ingresados"
                                        />
                                </Validator>
                            </TextBox>
                            <span>{textValuated}</span>
                        </div>
                    </DivFormColumns>
                    <DivFormColumns title='Fórmula para compras locales'
                        description="Defina la fórmula para el cálculo del costo real en base al costo para las compras locales">   
                        <div>
                            <TextBox                                
                                placeholder=' * 1.15 * 1.30'
                                value={app.ruleCalcuteNewPriceLocal}
                                onValueChanged={setRule('ruleCalcuteNewPriceLocal')}
                                >
                                <TextBoxButton
                                    name="ruleCalcuteNewPriceLocal"
                                    location="before"
                                    options={costButton}
                                />
                                <Validator>
                                    <StringLengthRule max={350} message="Maximo de caracteres permitidos 350" />
                                    <CustomRule 
                                        validationCallback={evaluateRules}
                                        message="Fórmula incorrecta, verifique los valores ingresados"
                                        />
                                </Validator>
                            </TextBox>
                            <span>{textValuatedLocal}</span>
                        </div>                   
                        
                    </DivFormColumns>
                    <DivFormColumns title='Fórmula para el precio en las  compras '
                        description="Defina la fórmula para el cálculo del precio real en base al costo para las compras">   
                        <div>
                            <TextBox                                
                                placeholder=' * 1.15 '
                                value={app.ruleProfitPrice}
                                onValueChanged={e => {

                                    setApp(app => ({ ...app, ruleProfitPrice: e.value }));
                                    const result = calculateEvaluate(costMock, e.value);
                                    const text = `Costo Real=${costMock}, Formula: ${costMock}${e.value}, Resultado del precio: ${result}`
                                    setTextValuatedPrice(text);

                                }}
                                >
                                <TextBoxButton
                                    name="ruleProfitPrice"
                                    location="before"
                                    options={priceButton}
                                />
                                <Validator>
                                    <StringLengthRule max={350} message="Maximo de caracteres permitidos 350" />
                                    <CustomRule 
                                        validationCallback={evaluateRules}
                                        message="Fórmula incorrecta, verifique los valores ingresados"
                                        />
                                </Validator>
                            </TextBox>
                            <span>{textValuatedPrice}</span>
                        </div>                   
                        
                    </DivFormColumns>
                    
                </div>
                <Button 
                    icon='save'
                    text={saving.text} 
                    disabled={saving.loading}
                    type="default"                     
                    useSubmitBehavior={true}>
                </Button>
            </form>         
               
        </div>
    );
}

export default AppConfig;
