import React, { useCallback, useState } from 'react';
import ChartLine from './ChartLine';
import ChartBar from './ChartBar';
import { Box, TagBox } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import ChartPie from './PieChart';
import './index.css';
const CharByType = ({ data, type, typeLabel, reverseType, reverseTypeLabel }) => {

    const [ currentCategory, setCurrentCategory ] = useState(null);
    const [ filter, setFilter ] = useState([]);

    const onPointClick = (e) => {

        const { target: point } = e;

        if (point.isSelected()) {
            point.clearSelection();
            setCurrentCategory(null);
        } else {
            point.select();
            setCurrentCategory(point.argument);
        }

    }

    const getUniques = (property, _data) => {
        return [...new Set(_data.map(item => item[property]))];
    }


    const getDataGeneral = useCallback((_data) => {        

        const data = _data.filter(item => {

            if(filter.length == 0) return true;

            return filter.includes(item[type]);
        
        });

        if(data.length === 0) return [];

        const getTotal = (_type) => {
            return data.filter(item => item[type] === _type)
                .reduce((prev, current) => prev + current.total, 0);
        }

        const dataByType = getUniques(type, data)

        const dataGeneral = dataByType.map(_type => {
            return {
                [type] : _type,
                total : getTotal(_type)
            }
        });

        return dataGeneral;

    },[filter, data]);

    const getDataDetail = useCallback((data) => {

        if(data.length === 0) return [];

        if(!currentCategory) return [];

        const dataByDates = getUniques('date', data);
        const dataByTypes = getUniques(reverseType, data);

        const dataDetail = dataByDates.map(date => {

            const dataByReverseType = dataByTypes.map(dataByType => {
                return {
                    [dataByType] : data.filter(item => item.date === date && item[reverseType] === dataByType && item[type] === currentCategory)
                        .reduce((prev, current) => prev + current.total, 0)
                }
            });

            let result = { date };

            for (const value of dataByReverseType.values()) {

                result = { ...result, ...value };
                
            }

            return result;
        })

        return dataDetail;



    } ,[currentCategory]);

    const getDataDetailDoughnut = useCallback((data) => {

        if(data.length === 0) return [];

        if(!currentCategory) return [];
       
        const dataByTypes = getUniques(reverseType, data);

        const dataDetail = dataByTypes.map(dataByType => {
            return {
                [reverseType]: dataByType,
                total : data.filter(item => item[reverseType] === dataByType && item[type] === currentCategory)
                    .reduce((prev, current) => prev + current.total, 0)
            }
        });          

        return dataDetail;

    } ,[currentCategory]);
    
    const dataGeneral = getDataGeneral(data);

    const dataDetail = getDataDetail(data);

    const dataDetailDoughnut = getDataDetailDoughnut(data);

    const collection = getUniques(type, data);

    const collectionReserve = getUniques(reverseType, data);

    return (
        <div>
            <div>
                <TagBox                   
                    dataSource={collection} 
                    multiline={false} 
                    searchEnabled={true} 
                    showClearButton={true}
                    showDropDownButton={true}
                    onValueChanged={e => setFilter(e.value)}
                >
                </TagBox>
            </div>
            <div>
                <ChartBar type={type} typeLabel={typeLabel} data={dataGeneral} onPointClick={onPointClick}/>
            </div>
            <div>
                { dataDetail.length > 0 &&  <ChartLine type={reverseType} typeLabel={reverseTypeLabel} data={dataDetail} reverseType={type} reverseTypeLabel={typeLabel} collection={collectionReserve} currentCategory={currentCategory}/> }
            </div>
            <div>
                { dataDetail.length > 0 &&  <ChartPie type={reverseType} typeLabel={reverseTypeLabel} data={dataDetailDoughnut} reverseType={type} reverseTypeLabel={typeLabel} collection={collectionReserve} currentCategory={currentCategory}/> }
            </div>
        </div>
    );
}

export default CharByType;
