import React from "react";
import useApi from "./useApi";
import uri from "../utils/uri";


const useUsers = () => {
    
    const { data: users, isLoading } = useApi({ url: uri.users.base+'/catalogo' }); 
   
    return {
        users, 
        isLoading
    }
}

export default useUsers;