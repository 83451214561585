
import React from 'react';
import { Link } from 'react-router-dom';
import { logoHome,logoHome150, cart, file, book,cotizacion, ventas, bills, clients, clients2 } from '../../data/logo';
import Title from '../shared/Title';
import Dashboard from './Dashboard';

export default function HomePage(props) {
    
    return (
        <div className="container text-center mt-10">
            <header className="site__header d-lg-none">

                <Title title="Inicio" />            
                {logoHome150}
                <div className='center center2col'>
                    <div className='d-grid'>
                        <Link className='d-grid' to={'app/mobile/cotizaciones'}> 
                            {cotizacion}
                            Cotizaciones
                        </Link>
                    </div>
                    <div className='d-grid'>
                        <Link className='d-grid' to={'app/mobile/pre-ventas'}> 
                            {ventas}
                            Preventas
                        </Link>
                    </div>
                    <div className="d-grid">
                        <Link className='d-grid' to={'app/facturas'}> 
                            {bills}
                            Facturas
                        </Link>
                    </div>
                    <div className='d-grid'>
                        <Link className='d-grid' to={'app/clientes/mobile/pending'}> 
                            {clients}
                            Cta por cobrar
                        </Link>
                    </div>   
                    <div className='d-grid'>
                        <Link className='d-grid' to={'app/mobile/clientes'}> 
                            {clients2}
                            Clientes
                        </Link>
                    </div>   
                    <div className='d-grid'>
                        <Link className='d-grid' to={'app/inventario/reportes/ingresos'}> 
                            {cart}
                            Últ Ingresos
                        </Link>
                    </div>   
                </div>               
                    
            </header>
            <header className="site__header d-lg-block d-none">

                <Title title="Inicio" />
                <Dashboard {...props}/>

            </header>            
        </div>
    )
}