import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Popup, ToolbarItem, } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { billDialogAction } from '../../../store/bill/billDialogReducer';
import { createCustomStore, createStoreLocal } from '../../../utils/proxy';
import { editorOptionsSelect, formatDate, resources } from '../../../data/app';
import { DataGrid, Button, ScrollView } from 'devextreme-react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, Summary, TotalItem, SearchPanel } from 'devextreme-react/data-grid';
import { cellRender, formatId } from '../../../utils/common';
import http from '../../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault, defaultLabels } from '../../../data/defaultObjects';
import { paymentType, preBillType } from '../../../data/enums';
import useProducts from '../../../hooks/useProducts';
import uri from '../../../utils/uri';
import Resumen from '../../../components/form/Resumen';
import ClientInfo from '../../clients/ClientInfo';
import { confirm } from 'devextreme/ui/dialog';
import  NuevoClient from '../../clients/NuevoClient';
import { clientDialogAction } from '../../../store/client/clientDialogReducer';
import ProductDDBComponentMobile from '../../../components/dropdown/ProductDDBComponentMobile';
import useAuthorization from '../../../hooks/useAuthorization';
import useClients from '../../../hooks/useClients';
import { clientDetailAction } from '../../../store/clientDetail/clientDetailReducer';

const NuevoNew = props => {

    const [ _, canChangeCreditDays ] = useAuthorization( resources.cambiarDiasCredito );
    const { action, typeId } = props;

    const { billDialog: { open, id, clientId }, user, } = useSelector(store => store);
    const [resumenData, setResumenData] = useState([]);
    const billDefaultCustom = { ...billDefault, clientId: clientId, preBillTypeId: typeId, paymentTypeId: 2, preBillDetails: []};
    
    const [ reload, setReload ] = useState(0);
    const [ loading, setLoading ] = useState(false);

    const { products, setProducts } = useProducts({ areaId: user.areaId, exists: true, reload });
    const [bill, setBill] = useState({ ...billDefaultCustom });
    const [saving, setSaving] = useState(false);
    const [currentCreditDays, setCurrentCreditDays] = useState(0);
    const [showRest, setShowRest] = useState(false);

    let refForm = useRef();
    let refGrid = useRef();

    useEffect(() => { 

        if (id > 0) {

            setLoading(true);
            http(uri.products.getByArea(user.areaId)).asGet().then(data => {
                setProducts(data);

                http(uri.prebills.getById(id)).asGet().then(resp => {

                    resp.preBillDetails.forEach(detail => {

                        let info = products.find(x => x.id == detail.productId);

                        detail['presentation'] = info.presentation;
                        detail['um'] = info.um;
                        detail['family'] = info.family;
                        detail['product'] = null;

                    });

                    setBill({ ...bill, ...resp });
                    setResumenData([...resp.preBillDetails]);
                    setLoading(false);
                })

            });

        } else {

            setLoading(true);
            setBill({ ...billDefaultCustom, preBillDetails: [] });
            setResumenData([]);
            setLoading(false);           

        }

        if(open)
            window.addEventListener("beforeunload", alertUser);
        
        return () => {
            if(open)
                window.removeEventListener("beforeunload", alertUser);                   
            
        };

    }, [open]); 

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
        alert('saliendo');
    };

    const dispatch = useDispatch();

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                type: 'default',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.addRow()
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                type: 'default',
                stylingMode: "outlined",
                onClick: () => setReload(Math.random())
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'remove',
                type: 'danger',
                stylingMode: "outlined",
                onClick: () => {                

                    var selectedRow = refGrid.current.instance.getSelectedRowKeys();                   
                    if (selectedRow.length > 0) {
                        let key = selectedRow[0]
                        let index = refGrid.current.instance.getRowIndexByKey(key)
                        refGrid.current.instance.deleteRow(index);
                    }
                }
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'check',
                type: 'normal',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.saveEditData();                   
                   
                }
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'revert',
                type: 'normal',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.cancelEditData();                   
                   
                }
            }
        }
        );
    }

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();

        dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
        dispatch(clientDetailAction( {clientId:0} ));
        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const convertFactura = () => {

        let result = confirm("<i>Esta seguro de convertir a factura la preventa: <b>"+formatId(id)+"</b>?</i>", "Confirmar");
        result.then((dialogResult) => {

            if (dialogResult){

                setSaving(true);
                
                http(`${uri.prebills.base}/${id}/convertToBill`).asGet().then(resp => {
                    
                    notify(`Se ha creado la factura correctamente correctamente`);
                    setSaving(false);
                    refGrid.current.instance.refresh();   
                    
                }).catch(err => {                    
                    notify(err, 'error', 5000);
                    setSaving(false);
                });
                
            }
        });

    }

    const guardarFactura = (e) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            let result = refForm.current.instance.validate();

            if (result.isValid) {

                setSaving(true);
                let data = { ...bill };

                http(uri.prebills.insert).asPost(data).then(resp => {
                    setSaving(false);
                    notify(`${action} registrada correctamente`);
                    setReload(Math.random());
                    closeDialog(true);                    
                }).catch(err => {
                    setSaving(false);
                    notify(err, 'error', 6000);
                });

            }
        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        if (value == undefined)
            newData[prop] =  0;
        else if(value.constructor.name === 'Object')
            newData[prop] = value.id;
        else
            newData[prop] = value || 0;
        
        if (prop == 'productId' && value) {

            let info = products.find(x => x.id == value);

            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['stock'] = info.stock;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            newData['discount'] = 0;
            !currentRowData['quantity'] && (newData['quantity'] = 1);
            !currentRowData['total'] && (newData['total'] = info.price);
        }

        if (prop == 'quantity' && (+value) >= 0) {
            if(value > currentRowData['stock'] && isPreventa){
                newData['quantity'] = currentRowData['stock'];
                newData['total'] = currentRowData['customPrice'] * currentRowData['stock'];
                notify(currentRowData['stock'] + " máximo stock disponible", "error", 5000);
            }else{
                newData['total'] = currentRowData['customPrice'] * value;
            }
        }


        if (prop == 'customPrice' && (+value) >= 0) {
            newData['total'] = currentRowData['quantity'] * value;
        }

        if (prop == 'discount' && (+value) >= 0) {
            newData['total'] = (currentRowData['quantity'] *  currentRowData['customPrice']) - value;
        }

    }

    const onCellPrepared = useCallback(e => {

        const cellsQuantity = ['quantity', 'quantityRequest']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                e.cellElement.classList.add('quantity-text');
            if (e.column.dataField == 'customPrice')
                e.cellElement.classList.add('customPrice-text');
            if (e.column.dataField == 'productoId')
                e.cellElement.classList.add('fs-text-13');

        }

    },[]);

    const onRowInserted = e => {
        setResumenData([...bill.preBillDetails])
    }

    const isNew = id == 0;
    
    const buttonOptions = {
        text: 'Crear Cliente',
        icon: 'user',
        onClick: function(e) { 
            dispatch(clientDialogAction({ open: true }));
        }
    };

    const reloadClient = clientId => {
        setReload(Math.random());
        setBill(_bill => ({ ..._bill, clientId: clientId }));
    }

    const onRowInserting = useCallback((e) => {

        const isCanceled = new Promise((resolve, reject) => {

            const existsProduct = refGrid.current.instance.getDataSource().items().find(x => x.productId == e.data.productId);

            if (existsProduct) {
                notify(`El producto ya se encuentra en la lista`, 'error', 5000);
                reject(`El producto ya se encuentra en la lista`);
            }else{
                resolve(false);
            }
        });
        e.cancel = isCanceled;

    },[]);

    const paymentTypeIdChanged = useCallback((e) => {

        setBill(_bill => ({ 
            ..._bill, paymentTypeId: e.value, 
            paymentMethodId: e.value == paymentType.credito ? null : '', 
            bankId: e.value == paymentType.credito ? null : '', 
            reference:'', 
            creditDays: e.value == paymentType.contado ? 0 : currentCreditDays,
        }))
    },[]);

    const clientIdChanged = useCallback(e => {
        const client = e.component.option('items')
            .find(x => x.id == e.value);

        let creditDays = 0;

        if(client)
            creditDays = client.creditDays;               
        
        dispatch(clientDetailAction( {clientId:e.value}));
        setCurrentCreditDays(creditDays);
        setBill(_bill => ({ ..._bill, creditDays: creditDays, clientId: e.value}));
    },[]);

    const isPreventa = typeId == preBillType.preventa;

    const buttonOptions2 = {
        icon: 'close',
        onClick: e => onHiding({ load: false }),
    };

    const buttonOptions3 = {
        icon: 'menu',
        onClick: e => setShowRest(!showRest),
    };

    return (
        <div>
            <NuevoClient onCreate={reloadClient} fullScreen={true}/>
            <Popup
                fullScreen={true}
                title={isNew ? `Nueva ${action}` : `Ver ${action} ${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"
                showCloseButton={false}
                id='bill-panel'
            >               
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#bill-panel' }}
                    visible={loading}
                    showIndicator={loading}
                    shading={true}
                    showPane={true}
                />
               <ToolbarItem
                    widget="dxButton" 
                    location="after" options={buttonOptions3}>
                </ToolbarItem>
                <ToolbarItem 
                    widget="dxButton" 
                    location="after" 
                    options={buttonOptions2}>
                </ToolbarItem>
                <ScrollView showScrollbar='never'>

                    <Form formData={bill} ref={refForm} labelLocation='top'>
                        <GroupItem colCount={2}>
                            <GroupItem colSpan={2}>
                                <GroupItem colCount={2}>
                                    <SimpleItem dataField="clientId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource:  createCustomStore({ url: uri.clients.base+'/catalogo?reload='+reload, active: true })(),
                                            valueExpr:"id",
                                            displayExpr: item => item ? `${item.id} - ${item.name}` : '',
                                            searchEnabled: true,                                            
                                            showClearButton:true,
                                            searchExpr: ["name"],
                                            onValueChanged: clientIdChanged
                                        }} >
                                        <Label text="Cliente" />
                                        <RequiredRule message="Seleccione el cliente" />
                                    </SimpleItem>
                                    <SimpleItem dataField="date" colSpan={2} editorType="dxDateBox"
                                        editorOptions={{
                                            displayFormat: formatDate,
                                            openOnFieldClick: true,
                                        }} >
                                        <Label text="Fecha" />
                                        <RequiredRule message="Seleccione la fecha" />
                                    </SimpleItem>
                                    <SimpleItem dataField="paymentTypeId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: createStoreLocal({ name: 'billPaymentType' }),
                                            ...editorOptionsSelect,
                                            searchEnabled: false,   
                                            onValueChanged: paymentTypeIdChanged 
                                        }} >
                                        <Label text="Tipo pago" />
                                        <RequiredRule message="Seleccione el tipo" />
                                    </SimpleItem>
                                    <SimpleItem dataField="creditDays" colSpan={2} editorType="dxNumberBox"
                                        editorOptions={{
                                            disabled: !canChangeCreditDays,
                                            onValueChanged: e => setBill(_bill => ({ ..._bill, creditDays: e.value, })),
                                        }} >
                                        <Label text="Dias crédito" />
                                        <RequiredRule message="Seleccione el tipo" />                                        
                                    </SimpleItem> 
                                                              
                                    <SimpleItem colSpan={2} >
                                        <ClientInfo isMobile={true} />
                                    </SimpleItem> 
                                                                   
                                    { (showRest && isPreventa) && <SimpleItem dataField="paymentMethodId" colSpan={2} editorType="dxSelectBox"
                                            editorOptions={{
                                                disabled: bill.paymentTypeId == paymentType.credito,
                                                dataSource: createStoreLocal({ name: 'BillPaymentMethod' }),
                                                ...editorOptionsSelect,
                                                searchEnabled: false,   
                                                onValueChanged: e => setBill(_bill => ({ ...bill, billPaymentTypeId: e.value, }))
                                            }} >
                                            <Label text="Forma pago" />
                                        </SimpleItem>
                                    }   
                                    {(showRest && isPreventa) && <SimpleItem dataField="bankId" colSpan={2} editorType="dxSelectBox"
                                            editorOptions={{
                                                disabled: bill.paymentTypeId == paymentType.credito,
                                                dataSource: createStoreLocal({ name: 'bank' }),
                                                ...editorOptionsSelect,
                                                searchEnabled: false,   
                                                onValueChanged: e => setBill(_bill => ({ ...bill, bankId: e.value, }))
                                            }} >
                                            <Label text="Banco" />
                                        </SimpleItem>
                                    }
                                    {(showRest && isPreventa) && <SimpleItem dataField="reference" colSpan={2} editorType="dxTextBox"
                                            editorOptions={{ showClearButton: true, disabled: bill.paymentTypeId == paymentType.credito, }} >
                                            <Label text="Referencia" />
                                        </SimpleItem>
                                    }
                                    {(showRest && isPreventa) && <SimpleItem dataField="codigo" colSpan={2} editorType="dxTextBox"
                                            editorOptions={{ placeholder: 'Talonario', showClearButton: true }} >
                                            <Label text="Referencia" />
                                        </SimpleItem>
                                    }
                                    <SimpleItem colSpan={2} >
                                        <Button {...buttonOptions}></Button>
                                    </SimpleItem> 

                                </GroupItem>
                            </GroupItem>   
                        </GroupItem>
                        <GroupItem>
                            <DataGrid id="gridDetails"
                                ref={refGrid}
                                selection={{ mode: 'single' }}
                                dataSource={bill.preBillDetails}
                                showBorders={true}
                                showRowLines={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                height={400}
                                onToolbarPreparing={onToolbarPreparing}
                                onRowInserted={onRowInserted}
                                onRowRemoved={onRowInserted}
                                onRowUpdated={onRowInserted}
                                onCellPrepared={onCellPrepared}
                                onRowInserting={onRowInserting}
                            >
                                 <SearchPanel visible={true} width={120} />
                                <Column dataField="productId" caption="Producto" minWidth={190}
                                    setCellValue={setCellValue.bind(null, "productId")}
                                    editCellComponent={props => <ProductDDBComponentMobile showPrice={true} {...props} validateQuantity={isPreventa}/>}
                                >
                                
                                    <Lookup
                                        dataSource={products}
                                        valueExpr="id"
                                        displayExpr={item => item ? `${item.internalCode}-${item.name}` : ''}

                                    />
                                    <RuleRequired message={"Seleccione un producto"} />
                                </Column>                           
                                
                                <Column dataField="quantity" caption="Cant" dataType="number" width={55}
                                    setCellValue={setCellValue.bind(null, "quantity")}
                                >
                                    <RuleRequired message={"Ingrese la cantidad"} />
                                </Column>
                               
                                <Column visible={false} dataField="price" caption="Precio" dataType="number" width={85} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired message={"Ingrese el precio"} />
                                </Column>
                                <Column dataField="customPrice" caption="Precio" dataType="number" width={100} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "customPrice")}>
                                    <RuleRequired message={"Ingrese el precio"}/>
                                </Column>
                                <Column dataField="discount" caption="Desc" dataType="number" width={80} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "discount")}>
                                    <RuleRequired message={"Ingrese el descuento"}/>
                                </Column>
                                <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="stock" caption="Disponible" dataType="number" width={90} allowEditing={false}>
                                </Column>
                                <Column type="buttons" width={50}>
                                    <ButtonGrid name="delete" />
                                </Column>
                                <Column type={'selection'} width={50} />
                                <Editing
                                    mode="cell"
                                    allowDeleting={true}
                                    allowUpdating={true}
                                    selectTextOnEditStart={true}
                                    useIcons={true}
                                ></Editing>
                                <Summary>
                                    <TotalItem  column="productId" summaryType="count" />
                                </Summary>
                            </DataGrid>
                        </GroupItem>
                        <GroupItem >
                            <SimpleItem dataField="observation" editorType="dxTextBox">
                                <Label text="Observacion" />
                                <StringLengthRule max={150} message="Maximo 150 caracteres" />
                            </SimpleItem>
                            <SimpleItem colSpan={2} dataField="codeAuth" editorType="dxTextBox">
                                <Label text="Código Autorización" />
                                <StringLengthRule max={150} message="Maximo 150 caracteres" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem colSpan={2}>
                            <Resumen data={resumenData} />
                        </GroupItem>
                    </Form>
                    <div className='d-grid'>                    
                        {bill.active == false &&  <label className="text-danger pa-canceled-mobile">Registro Anulado</label>}
                        <Button
                            className="mt-10"
                            text={saving ? 'Guardando...' : `Convertir a factura`}
                            type="default"
                            icon='save'
                            visible={!isNew && isPreventa}
                            disabled={saving}
                            onClick={convertFactura}
                            width={'100%'}
                            />

                        <Button
                            className="mt-10"
                            text={saving ? 'Guardando...' : `Guardar ${action}`}
                            type="success"
                            icon='save'
                            disabled={saving}
                            onClick={guardarFactura}
                            width={'100%'}
                            />
                    </div>

                </ScrollView>   
            </Popup>
        </div>
    );
}

export default NuevoNew;
