import { useState,useEffect } from "react";
import http from "../utils/http";
import uri from "../utils/uri";


const useApi = ({ url }) => {
    
    const [ isLoading, setIsLoading] = useState(true);
    const [ data, setData] = useState([]);   

    const callApi = async () => {

        const data = await  http(url).asGet();
        setData(data);
        setIsLoading(false);

    }

    
    useEffect(() => {
        callApi();
    }, [url]);

    return {
        data, 
        isLoading
    }
}

export default useApi;