import React, { useRef } from 'react';
import BlockHeader from '../../components/shared/BlockHeader';
import Title from '../../components/shared/Title';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    Export,
    FilterRow,
    HeaderFilter,
    Lookup,
    Pager,
    Paging,
    Button as ButtonGrid,
  } from 'devextreme-react/data-grid';
import {  createStoreLocal } from '../../utils/proxy';
import uri from '../../utils/uri';
import { store } from '../../services/store';
import { purchaseSteps } from '../../data/enums';
import { dataAccess, formatDate, formatDateTime } from '../../data/app';
import CustomButton from '../../components/buttons/CustomButton';
import { useDispatch } from 'react-redux'
import useAuthorization from '../../hooks/useAuthorization';
import { dataFormatId } from '../../utils/common';
import { addMenu } from '../../components/grids/Menu';
import { onToolbar } from '../../components/grids/ToolBar';
import urlReport from '../../services/reportServices';
import { resources } from '../../data/app';
import Nuevo from './Nuevo';
import { ncDialogAction } from '../../store/notacredit/ncDialogReducer';

const NotasCreditos = (    
    { 
        title= "Notas de Crédito", 
        btnAddText= "Nueva Nota de Crédito",
        typeId= null,
        icon="",       
        resourcesId = resources.crearNotasCreditos,
        printName = 'notaCredito',
        dialog = ncDialogAction,
        ...rest
    }) => {
    
    const [ authorized ] = useAuthorization( resourcesId );

    let dataGrid = useRef();
    const dispatch = useDispatch();

    const reload = () => dataGrid.current.instance.refresh();    

    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            if (!e.data.active)
                e.rowElement.classList.add('no-activo');

        }
    }

    const onCellPrepared = e => {

        const cellsQuantity = ['stepId']
            
        if (e.rowType == 'data' && e.column.allowEditing) {
            if(cellsQuantity.includes(e.column.dataField)){
                e.cellElement.classList.add('td-status');

                if(e.data.stepId == purchaseSteps.anulada)
                    e.cellElement.classList.add('td-status-cancelado');

                if(e.data.stepId == purchaseSteps.pendiente)
                    e.cellElement.classList.add('td-status-pendiente');

                if(e.data.stepId == purchaseSteps.procesada)
                    e.cellElement.classList.add('td-status-procesado');
            }
          
        }
    
    }

    const showDialog = id => dispatch(dialog({ open: true, id }));  
    
    const report = urlReport();

    const addMenuItems = (e) => {
        addMenu(e, [{
            text: `Ver ${title}`,
            icon: 'find',
            onItemClick: () => showDialog(e.row.data.id)
        },{
            text: `Imprimir ${title}`,
            icon: 'print',
            onItemClick: () => {

                if(printName)
                    report.print(`${report[printName](e.row.data.id)}`) ;
            }
        }])
    }

    const onToolbarPreparing = onToolbar({ export : true } , dataGrid);

    const _uri = uri.movements;

    const printReport = ( id ) => {
       
        const report = urlReport();
        report.print(`${report.notaCredito(id)}`);

    }

    return authorized(
        <div className="container">
            <Title title={title}/>
            <BlockHeader title={title} icon={icon} showFavorite={true} {...rest}>
                <CustomButton                 
                    text={btnAddText}
                    icon='plus'
                    onClick={() => showDialog(0)}
                />
            </BlockHeader>
            <Nuevo onSave={reload} typeId={typeId}/> 
            
            <DataGrid id="gridContainer"
                ref={dataGrid}
                selection={{ mode: 'single' }}
                dataSource={store({ uri : _uri, remoteOperations : true, extraParameter: null })}
                showBorders={true}
                showRowLines={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                onRowPrepared={onRowPrepared}
                onCellPrepared={onCellPrepared}
                onContextMenuPreparing={addMenuItems}
                onToolbarPreparing={onToolbarPreparing}
                remoteOperations={{
                    paging: true,
                    filtering: true
                }}     
            >
                <Paging defaultPageSize={20} />
                <Pager
                    showInfo={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <Export enabled={true} fileName={title} allowExportSelectedData={true} />
                <Column dataField="id" caption='Numero' width={100} cellRender={dataFormatId}/>
                <Column dataField="billId" caption='Factura' width={100} cellRender={dataFormatId}/>
                <Column dataField="movementTypeId" caption="Tipo " width={110}>
                    <Lookup disabled={true} dataSource={createStoreLocal({name: 'movementType'})} valueExpr="id" displayExpr="name" />
                </Column> 
                <Column dataField="name" caption="Cliente" minWidth={150} allowHeaderFiltering={false} />
                <Column dataField="total" caption='Total' dataType='number' format={{ type: 'fixedPoint', precision: 2 }} width={100} />              
                <Column dataField="observation" caption='Observacion' visible={false} allowHeaderFiltering={false} allowFiltering={false}/>
                <Column dataField="active" width={90} caption="Activa"/>
                <Column dataField="createAt" caption='Creado el' dataType='date' format={formatDateTime} width={180}/>
                <Column dataField="createBy" caption='Creado Por'  width={120}/>
                <Column type="buttons" width={90}>
                    <ButtonGrid name="edit" icon="find" onClick={e => showDialog(e.row.data.id)}/>
                    <ButtonGrid name="print" icon="print" onClick={e => printReport(e.row.data.id)}/>
                    <ButtonGrid name="delete" />
                </Column>
                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowUpdating={true}
                    useIcons={true}
                >
                </Editing>
            </DataGrid>
        </div>
    );
}

export default NotasCreditos;
