import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, AsyncRule, EmptyItem, RequiredRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import notify from 'devextreme/ui/notify';
import uri from '../../../utils/uri';
import http from '../../../utils/http';
import { dialogAreaProduct } from '../../../store/areaProduct/areaProductDialogReducer';
import { Button } from 'devextreme-react';
import { editorOptionsNumberBox, editorOptionsSwitch } from '../../../data/app';
import { convert2 } from '../../../data/defaultObjects';


const Nuevo = props => {

    const { areaId } = props;

    let refAreaProduct = useRef();
    let refProductConvert = useRef();

    const [areaProduct, setAreaProduct] = useState({});
    const [productConvert, setProductConvert] = useState({ areaId, convertProductId: 0, convertProductQuantity: 0, name: ''});
    const [areaProductConvert, setAreaProductConvert] = useState({});

    const [saving, setSaving] = useState(false);
    const [converting, setConverting] = useState(false);
    const { open, productId, name, internalCode } = useSelector(store => store.areaProductDialog);

    const dispatch = useDispatch();

    const closeDialog = ( load ) => {

        dispatch(dialogAreaProduct({open : false, id: 0}));

        if (load) {

            let { onSave } = props;
            onSave();
      
        }
    }

    const onHiding = ({ load }) => {

        refAreaProduct.current.instance.resetValues();
        setAreaProduct({});
        closeDialog(load);
        
    }

    const convertProductAsync = async() => {

        let resultValidate = refProductConvert.current.instance.validate();

        let result = resultValidate;

        if(resultValidate.complete)
            result = await resultValidate.complete; 
        
        if (result.isValid) 
        {
            setConverting(true);
            http(uri.areaProductConvert(areaId).insert).asPost({
                areaId: areaId,
                productId: productId,
                quantity: productConvert.convertProductQuantity,
                costReal: productConvert.costReal,
                costAvg: productConvert.costAvg,
                price: productConvert.price
            })
            .then(resp => {
                setConverting(false);
                notify(`Producto convertido correctamente`);
                closeDialog(true);
            })
            .catch(err => {
                setConverting(false);
                notify(err,'error');
            })
        }

    }

    const guardarProduct = (e) => {

        let result = refAreaProduct.current.instance.validate();
        if (result.isValid) 
        {
            setSaving(true);
            http(uri.areaProducts(areaId).insert).asPost({...areaProduct})
            .then(resp => {
                setSaving(false);
                notify(`Producto modificado correctamente`);
                closeDialog(true);
            })
            .catch(err => {
                setSaving(false);
                notify(err,'error');
            })
        }
    }
    const onValueChanged = (e) => {
        if(e.value){
            setAreaProduct({...areaProduct, inherit: e.value, stockMin: 0, stockMax: 0, discount : 0});

        }
        else{
            setAreaProduct({...areaProduct, inherit: e.value, stockMin: 1, stockMax: 1, discount : 0});
        }   

    }

    const validationCallback = (e) => {

        let result = e.value > 0 && e.value != '' &&  e.value != 0 &&  e.value != null;

        let isMupli = e.value % areaProduct.product.convertSourceQuantiy == 0;

        return new Promise((resolve,reject) => {
            if(!isMupli)
                reject('La cantidad a convertir debe ser multiplo de ' + areaProduct.product.convertSourceQuantiy);                
            resolve(result);
        });
    }


    useEffect(() => {

        if(productId > 0){
            http(uri.areaProducts(areaId).getById(productId)).asGet()
            .then(resp => {

                let costReal = 0;
                let costAvg = 0;
                let price = 0;

                setAreaProduct({productId, name, internalCode, ...resp.areaProduct});

                if(resp.areaProductToConvert){

                    costReal = resp.areaProductToConvert.costReal;
                    costAvg = resp.areaProductToConvert.costAvg;
                    price = resp.areaProductToConvert.price;

                }

                setProductConvert({...productConvert,convertProductQuantity:resp.areaProduct.product.convertSourceQuantiy, name: resp.productoToConvert?.name,costReal, costAvg, price});
            })
        }
               
    }, [open]);

    const onValueChangedConvert = (e) => {

        let costReal = 0;
        let costAvg = 0;
        let price = 0;

        if(e.value > 0)
        {
            if(areaProduct.product.convertSourceQuantiy  > areaProduct.product.convertProductQuantity)
            {
                price = convert2(areaProduct.price * areaProduct.product.convertSourceQuantiy);
                costReal = convert2(areaProduct.costReal * areaProduct.product.convertSourceQuantiy);
                costAvg = convert2(areaProduct.costAvg * areaProduct.product.convertSourceQuantiy);
            }else
            {
                costReal = convert2(areaProduct.costReal / areaProduct.product.convertProductQuantity);
                costAvg = convert2(areaProduct.costAvg / areaProduct.product.convertProductQuantity);
                price = convert2(areaProduct.price / areaProduct.product.convertProductQuantity);
            }
        }            

        setProductConvert({...productConvert, convertProductQuantity: e.value, costReal, costAvg, price});
    }

    const getQuantity = (e) => {

        if(areaProduct && areaProduct.product)
            if(open)
                return productConvert.convertProductQuantity / areaProduct.product.convertSourceQuantiy * areaProduct.product.convertProductQuantity
            
        return 0;
    }

    const quantityToConvertText = `Cantidad de ${areaProduct?.product?.unitOfMeasure?.name}`;

    const productoToConvertText = <div>Convertir a <b>{getQuantity()}</b> {productConvert?.name}</div>;

    return (
        <div>
             <Popup
                width={600}
                height={640}
                title={`Inventario`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"             
            >   
                <Form formData={{
                    productId : areaProduct.productId,
                    name : areaProduct.name,
                    stock : areaProduct.stock,
                    internalCode : areaProduct.internalCode
                }} readOnly={true}>
                    <GroupItem colCount={3}>
                        <SimpleItem dataField="productId" colSpan={1} editorType="dxNumberBox"> 
                            <Label text="Codigo" />
                        </SimpleItem>
                        <SimpleItem dataField="internalCode" colSpan={1}> 
                            <Label text="Código" />
                        </SimpleItem>
                        <SimpleItem dataField="stock" colSpan={1}> 
                            <Label text="Existencias" />
                        </SimpleItem>     
                        <SimpleItem dataField="name" colSpan={3}> 
                            <Label text="Nombre" />
                        </SimpleItem>
                                         
                    </GroupItem>
                </Form>
                <Form formData={areaProduct} ref={refAreaProduct}>
                    <GroupItem colCount={2}>                          
                        <GroupItem caption="Configuración de Costos y precios"  colSpan={2} colCount={2}>
                            <SimpleItem dataField="inherit" colSpan={2} editorType="dxSwitch" 
                                editorOptions={{
                                    ...editorOptionsSwitch,
                                    onValueChanged : onValueChanged
                                }}>           
                                <Label text="Heredar" />                 
                            </SimpleItem>    
                            <SimpleItem dataField="stockMin" colSpan={1} editorType="dxNumberBox" 
                                editorOptions={{...editorOptionsNumberBox, disabled : areaProduct.inherit}}>
                                <Label text="Mínimo" />
                            </SimpleItem>   
                            <SimpleItem dataField="costReal" colSpan={1} editorType="dxNumberBox" 
                                editorOptions={{...editorOptionsNumberBox }} >
                                <Label text="Cost" />
                                <RequiredRule message="Ingrese el costo real" />
                            </SimpleItem>
                            <SimpleItem dataField="costAvg" colSpan={1} editorType="dxNumberBox" 
                                editorOptions={{...editorOptionsNumberBox }} >
                                <Label text="Cost Promedio" />
                                <RequiredRule message="Ingrese el costo promedio" />
                            </SimpleItem>
                            <SimpleItem dataField="price" colSpan={1} editorType="dxNumberBox" 
                                editorOptions={{...editorOptionsNumberBox }} >
                                <Label text="Precio Vta" />
                                <RequiredRule message="Ingrese el precio de venta" />
                            </SimpleItem>                           
                        </GroupItem>
                      
                        
                    </GroupItem>
                </Form>   
                <Button
                    width={120}
                    text={`${saving?'Guardando...':'Guardar'}`}
                    type="default"
                    icon="save"
                    stylingMode="contained"
                    className="m-1"
                    disabled={saving}
                    onClick={guardarProduct}
                />
                <Form formData={productConvert} ref={refProductConvert}>
                    <GroupItem colCount={2} caption='Conversión'>                         
                        
                        <SimpleItem colSpan={2} dataField="convertProductQuantity" editorType="dxNumberBox" editorOptions={{
                           onValueChanged : onValueChangedConvert,
                           max: areaProduct.stock,
                           min: areaProduct?.product?.convertSourceQuantiy || 0,
                           step: areaProduct?.product?.convertSourceQuantiy || 1,
                           disabled: productConvert?.name == undefined,
                            ...editorOptionsNumberBox,
                        }}>
                            <Label text={quantityToConvertText} />
                            <AsyncRule message="Ingrese la cantidad a convertir" validationCallback={validationCallback}></AsyncRule>
                        </SimpleItem>
                        <SimpleItem dataField="costReal" colSpan={1} editorType="dxNumberBox" 
                            editorOptions={{disabled: productConvert?.name == undefined,...editorOptionsNumberBox }} >
                            <Label text="Cost" />
                        </SimpleItem>
                        <SimpleItem dataField="costAvg" colSpan={1} editorType="dxNumberBox" 
                            editorOptions={{disabled: productConvert?.name == undefined,...editorOptionsNumberBox }} >
                            <Label text="Cost Promedio" />
                        </SimpleItem>
                        <SimpleItem dataField="price" colSpan={1} editorType="dxNumberBox" 
                            editorOptions={{disabled: productConvert?.name == undefined,...editorOptionsNumberBox }} >
                            <Label text="Precio Vta" />
                        </SimpleItem>
                        <EmptyItem colSpan={1} />
                        <SimpleItem colSpan={2}>
                            <div>
                                {productoToConvertText}
                            </div>
                        </SimpleItem>
                    </GroupItem>
                </Form>    
                <Button
                    width={150}
                    text={`${converting?'Convertiendo...':'Convertir'}`}
                    type="default"
                    icon="save"
                    stylingMode="contained"
                    className="m-1"
                    visible={true}
                    disabled={converting}
                    onClick={convertProductAsync}
                />
            </Popup>
        </div>
    );
}

export default Nuevo;
